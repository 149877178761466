import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Paper,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';
import { FirebaseAppHandler } from '../firebase/FirebaseAppHandler';
import { UserData } from '../../src-server/userService';

// Check if two dates are the same day
const isSameDay = (date1: any, date2: Date): boolean => {
  if (!date1) return false;

  try {
    // Convert to Date object if it's not already
    const d1 =
      date1 instanceof Date
        ? date1
        : new Date(date1.seconds ? date1.seconds * 1000 : date1);
    const d2 = date2;

    return (
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getFullYear() === d2.getFullYear()
    );
  } catch (error) {
    console.error('Error comparing dates:', error);
    return false;
  }
};

// Copy pasted from server
export const TOKEN_LIMITS: Record<string, number> = {
  free: 100000,
  premium: 10000000,
  admin: 10000000000,
};
interface UserProfileProps {
  onSignOut?: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ onSignOut }) => {
  const [user, setUser] = useState<any>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [maxTokens, setMaxTokens] = useState<number>(0);
  const auth = FirebaseAppHandler.getInstance();

  useEffect(() => {
    fetchUserData();
  }, []);

  const refreshUserData = async () => {
    await auth.refreshCurrentUserData();
    setUserData(auth.getCurrentUserData());
  };

  const fetchUserData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get user from Firebase
      const data = auth.getCurrentUser();
      setUser(data);

      // Get UserData that includes token usage
      await auth.refreshCurrentUserData();

      // Get updated user data
      const updatedUserData = auth.getCurrentUserData();
      setUserData(updatedUserData);

      // Get max tokens based on account tier
      if (updatedUserData?.accountTier) {
        setMaxTokens(TOKEN_LIMITS[updatedUserData.accountTier]);
      }
    } catch (error: any) {
      console.error('Error fetching user data:', error);
      setError(error.message || 'Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOutUser();
      if (onSignOut) {
        onSignOut();
      }
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out');
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        {user?.photoURL ? (
          <Box
            component="img"
            src={user.photoURL}
            alt="Profile"
            sx={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              mb: 2,
              display: 'block',
              margin: '0 auto',
            }}
          />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <PersonIcon sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
          </Box>
        )}
        <Typography variant="h5" gutterBottom>
          {userData?.name || user?.displayName || 'User'}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          data-cy="account-type"
        >
          {userData?.accountTier
            ? `${userData.accountTier.charAt(0).toUpperCase() + userData.accountTier.slice(1)} Account`
            : ''}
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <List>
        <ListItem>
          <ListItemIcon>
            <EmailIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary="Email"
            secondary={userData?.email || user?.email || 'Not available'}
          />
        </ListItem>

        {userData && (
          <>
            <ListItem>
              <ListItemIcon>
                <TokenIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Daily AI Token Usage"
                secondary={
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <Tooltip
                          title={`${isSameDay(userData.aiUsedLastDay, new Date()) ? userData.aiTokensUsedLastDay || 0 : 0} of ${maxTokens} tokens used today`}
                        >
                          <LinearProgress
                            variant="determinate"
                            value={Math.min(
                              ((userData.aiTokensUsedLastDay || 0) /
                                maxTokens) *
                                100,
                              100,
                            )}
                            sx={{ height: 10, borderRadius: 5 }}
                          />
                        </Tooltip>
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">
                          {Math.round(
                            ((userData.aiTokensUsedLastDay || 0) / maxTokens) *
                              100,
                          )}
                          %
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                      {userData.aiTokensUsedLastDay?.toLocaleString() || 0} of{' '}
                      {maxTokens.toLocaleString()} tokens
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </>
        )}
      </List>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" color="primary" onClick={refreshUserData}>
          Refresh
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={handleSignOut}
          data-cy="sign-out-button"
        >
          Sign Out
        </Button>
      </Box>
    </Paper>
  );
};

export default UserProfile;
