import React from 'react';
import PPNode from '../../classes/NodeClass';
import {
  Layoutable,
  TRgba,
  WidgetProps,
  DashboardWidgetProps,
} from '../../utils/interfaces';
import { NODE_TYPE_COLOR } from '../../utils/constants';
import {
  addDashboardContentOutput,
  SOCKET_NAME_DASHBOARD_CONTENT,
} from '../../utils/layoutableHelpers';
import { DynamicWidgetContainerNode } from './dynamicLayout';

/**
 * Base class for nodes that implement the Layoutable interface
 * Provides common functionality for layout nodes
 */
export abstract class LayoutableNodeBase extends PPNode implements Layoutable {
  public isLayoutable(): boolean {
    return true;
  }

  public getTags(): string[] {
    return ['Widget', 'Layout', 'Container'];
  }

  public getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.LAYOUT);
  }

  public getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  public getDashboardName(): string {
    return this.nodeName;
  }

  public getRoundedCorners(): boolean {
    return false;
  }

  public getDashboardWrapper(props: DashboardWidgetProps): React.ReactNode {
    return <DynamicWidgetContainerNode property={this} {...props} />;
  }

  public getRelatedNode(): PPNode {
    return this;
  }

  public async onNodeAdded(source): Promise<void> {
    await super.onNodeAdded(source);
    addDashboardContentOutput(this);
  }

  protected async onExecute(input: any, output: any): Promise<void> {
    const ReactUI = {
      renderFunction: (props) => {
        return this.getDashboardWrapper({
          ...props,
        });
      },
    };
    output[SOCKET_NAME_DASHBOARD_CONTENT] = ReactUI;

    await super.onExecute(input, output);
  }

  // Abstract methods that child classes must implement
  public abstract getName(): string;
  public abstract getDescription(): string;
  public abstract getWidgetProps(): WidgetProps;
  public abstract getWidgetContent(props: any): React.ReactElement;
}
