import {
  NodeExecutionError,
  NodeExecutionWarning,
  PNPError,
  PNPStatus,
} from '../../classes/ErrorClass';
import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import { NODE_TYPE_COLOR, SOCKET_TYPE } from '../../utils/constants';
import { TRgba } from '../../utils/interfaces';
import { AnyType } from '../datatypes/anyType';
import { DynamicEnumType } from '../datatypes/dynamicEnumType';
import { JSONType } from '../datatypes/jsonType';
import { StringType } from '../datatypes/stringType';

const inputLocalStorageKeyName = 'Local Storage Key';
const inputObjectKeyName = 'Object Key';
const inputValueName = 'Value';
const inputSelectedName = 'Selected';
const inputObjectFallback = 'Fallback Value';
const inputSocketName = 'Input Socket';

const LOCAL_STORAGE_PREFIX = 'PNP.';

abstract class LocalStorage extends PPNode {
  public getTags(): string[] {
    return ['State', 'LocalStorage', 'Storage'].concat(super.getTags());
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return (
      socket.name === inputValueName || socket.name === inputObjectFallback
    );
  }
}

export class LocalStorageWrite extends LocalStorage {
  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }
  public getName() {
    return 'Local Storage Write';
  }
  public getDescription() {
    return '(Over) Writes a member of an object in local storage';
  }
  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        inputLocalStorageKeyName,
        new StringType(),
        'Key',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputObjectKeyName,
        new StringType(),
        'Object Key',
      ),
      new Socket(SOCKET_TYPE.IN, inputValueName, new AnyType(), 'Value'),
    ];
  }

  protected async onExecute(input, output) {
    try {
      // Validate inputs
      const key = input[inputLocalStorageKeyName];
      const objectKey = input[inputObjectKeyName];

      // Get existing object or create new one
      let localStorageJSON = {};
      try {
        const storedValue = localStorage.getItem(LOCAL_STORAGE_PREFIX + key);
        const parsed = JSON.parse(storedValue);
        if (
          typeof parsed === 'object' &&
          parsed !== null &&
          !Array.isArray(parsed)
        ) {
          localStorageJSON = parsed;
        }
      } catch (e) {
        // Using new empty object
        this.setStatus(
          new NodeExecutionWarning(
            'Failed to parse stored value, using empty object',
          ),
        );
      }

      // Update and store
      localStorageJSON[objectKey] = input[inputValueName];
      localStorage.removeItem(LOCAL_STORAGE_PREFIX + key);
      localStorage.setItem(
        LOCAL_STORAGE_PREFIX + key,
        JSON.stringify(localStorageJSON),
      );
    } catch (err) {
      this.setStatus(
        new NodeExecutionError(
          'Failed to write to localStorage: ' + err.message,
        ),
      );
    }
  }
}

export class LocalStorageDelete extends LocalStorage {
  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }
  public getName() {
    return 'Local Storage Delete';
  }

  public getDescription() {
    return 'Deletes a member of an object in local storage';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        inputLocalStorageKeyName,
        new StringType(),
        'Key',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputObjectKeyName,
        new StringType(),
        'Object Key',
      ),
    ];
  }

  protected async onExecute(input, output) {
    const key = input[inputLocalStorageKeyName];
    const localStorageItem = localStorage.getItem(LOCAL_STORAGE_PREFIX + key);
    if (localStorageItem === null) {
      this.setStatus(
        new NodeExecutionWarning('Key not found in local storage'),
      );
    } else {
      const found = JSON.parse(localStorageItem);
      delete found[input[inputObjectKeyName]];
      localStorage.setItem(LOCAL_STORAGE_PREFIX + key, JSON.stringify(found));
    }
  }
}

function objectKeysToEnum(object: any) {
  return Object.keys(object).map((key) => ({
    text: key,
    value: key,
  }));
}

export class LocalStorageBrowse extends LocalStorage {
  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }

  public getName() {
    return 'Browse Object in Local Storage';
  }

  public getDescription() {
    return 'Provides a dropdown of keys of an object in local storage and allows selecting one, with an optional fallback value';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        inputLocalStorageKeyName,
        new StringType(),
        'Example',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputSelectedName,
        new DynamicEnumType(
          () => {
            const objectName =
              LOCAL_STORAGE_PREFIX +
              this.getInputData(inputLocalStorageKeyName);
            if (objectName in localStorage) {
              try {
                return objectKeysToEnum(JSON.parse(localStorage[objectName]));
              } catch {
                // continue...
              }
            }
            return [];
          },
          () => {
            this.executeOptimizedChain();
          },
        ),
      ),
      new Socket(SOCKET_TYPE.IN, inputObjectFallback, new AnyType()),
      new Socket(SOCKET_TYPE.OUT, inputSelectedName, new StringType()),
      new Socket(SOCKET_TYPE.OUT, inputValueName, new AnyType()),
    ];
  }

  protected async onExecute(input, output) {
    const objectName = LOCAL_STORAGE_PREFIX + input[inputLocalStorageKeyName];
    output[inputSelectedName] = input[inputSelectedName];
    if (objectName in localStorage) {
      const found = JSON.parse(localStorage[objectName]);
      if (input[inputSelectedName] in found) {
        output[inputValueName] = found[input[inputSelectedName]];
      } else {
        output[inputValueName] = input[inputObjectFallback];
      }
    } else {
      output[inputValueName] = input[inputObjectFallback];
    }
  }
}

// I hate this node, maybe there is some other way we can do this?
export class CopySocketValue extends PPNode {
  public getName() {
    return 'Trigger Copy Socket Value';
  }
  public getDescription() {
    return 'Copies the value of an socket on a node to an input socket of another node using a trigger socket (ONLY USE WHEN ABSOLUTELY NECESSARY AND YOU ARE DOING SOMETHING VERY STATEFUL)';
  }
  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, inputValueName, new AnyType(), 'Value'),
      new Socket(SOCKET_TYPE.IN, inputSocketName, new StringType(), 'Input'),
      new Socket(SOCKET_TYPE.OUT, inputValueName, new AnyType(), 'Value'),
    ];
  }

  protected async onExecute(input, output) {
    const value = input[inputValueName];
    const socketName = input[inputSocketName];
    const links = this.getOutputSocketByName(inputValueName).links;
    for (const link of links) {
      const node = link.getTarget().getNode();
      node.setInputData(socketName, structuredClone(value));
      await node.executeOptimizedChain();
    }
    output[inputValueName] = value;
  }
}
