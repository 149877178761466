import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import InterfaceController from '../InterfaceController';
import { createGist, formatDate, writeTextToClipboard } from '../utils/utils';
import PPGraph from '../classes/GraphClass';
import PPStorage from '../PPStorage';

interface ShareDialogProps {
  showSharePlayground: boolean;
  setShowSharePlayground: (show: boolean) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  graphName: string;
}

const submitSharePlaygroundDialog = (props, isPublic) => {
  props.setShowSharePlayground(false);

  const description = (
    document.getElementById(
      'share-playground-description-input',
    ) as HTMLInputElement
  ).value;
  const fileName =
    (
      document.getElementById(
        'share-playground-fileName-input',
      ) as HTMLInputElement
    ).value + '.ppgraph';
  const fileContent = JSON.stringify(PPGraph.currentGraph.serialize(), null, 2);

  createGist(description, fileName, fileContent, isPublic)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        if (data.sessionExpired) {
          props.setIsLoggedIn(false);
        }
        throw new Error(data.error);
      }

      InterfaceController.showSnackBar(
        <span>
          The{' '}
          <Link target="_blank" href={data.htmlUrl}>
            gist
          </Link>{' '}
          was successfully created.
        </span>,
        {
          autoHideDuration: 20000,
          action: (key) => (
            <>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  writeTextToClipboard(data.shareableLink);
                  InterfaceController.hideSnackBar(key);
                }}
              >
                Copy link
              </Button>
              <Button
                size="small"
                onClick={() => InterfaceController.hideSnackBar(key)}
              >
                Dismiss
              </Button>
            </>
          ),
        },
      );
    })
    .catch((error) => {
      console.warn(error);
      InterfaceController.showSnackBar(error.message, {
        variant: 'warning',
      });
    });
};

export const ShareDialog = React.memo(
  (props: ShareDialogProps) => {
    const [isPublic, setIsPublic] = useState(false);

    // Memoize handlers
    const handleClose = useCallback(() => {
      props.setShowSharePlayground(false);
    }, [props.setShowSharePlayground]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublic(event.target.value === 'true');
      },
      [],
    );

    // Memoize the default file name
    const defaultFileName = useMemo(
      () => `${props.graphName} - ${formatDate()}`,
      [props.graphName],
    );

    // Memoize content based on login status
    const dialogContent = useMemo(() => {
      if (props.isLoggedIn) {
        return (
          <Box>
            <DialogContentText id="alert-dialog-description">
              We store the playground as a gist and create a shareable link for
              you.
            </DialogContentText>
            <TextField
              id="share-playground-fileName-input"
              margin="dense"
              label="Name of playground file"
              fullWidth
              variant="filled"
              defaultValue={defaultFileName}
              placeholder="Name of playground file"
            />
            <TextField
              id="share-playground-description-input"
              autoFocus
              margin="dense"
              label="Description"
              fullWidth
              variant="filled"
              placeholder="Description of playground"
            />
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={isPublic}
              onChange={handleChange}
              title="Secret gists are hidden by search engines but visible to anyone you give the URL to. Public gists are visible to everyone."
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Secret"
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Public"
              />
            </RadioGroup>
          </Box>
        );
      }

      return (
        <Box>
          <Grid2
            container
            justifyContent="center"
            spacing={2}
            columns={{ xs: 6, sm: 6, md: 12 }}
          >
            <Grid2 xs={12}>
              <Paper sx={{ mx: 1, px: 3, py: 6, textAlign: 'center' }}>
                Get a shareable link
                <Box sx={{ m: 3 }}>
                  <Button
                    variant="contained"
                    href="/auth-with-github"
                    data-cy="loginWithGithubButton"
                  >
                    Login with Github
                  </Button>
                </Box>
                We store the playground as a gist.
              </Paper>
            </Grid2>
          </Grid2>
        </Box>
      );
    }, [props.isLoggedIn, isPublic, handleChange, defaultFileName]);

    return (
      <Dialog
        open={props.showSharePlayground}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        data-cy="shareDialog"
        sx={{
          zIndex: 1410,
        }}
      >
        <DialogTitle id="alert-dialog-title">Share playground</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitSharePlaygroundDialog(props, isPublic);
          }}
        >
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {props.isLoggedIn && (
              <Button type="submit">Share playground</Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function for React.memo
    return (
      prevProps.showSharePlayground === nextProps.showSharePlayground &&
      prevProps.isLoggedIn === nextProps.isLoggedIn &&
      prevProps.graphName === nextProps.graphName
    );
  },
);

ShareDialog.displayName = 'ShareDialog';

interface DeleteConfirmationDialogProps {
  showDeleteGraph: boolean;
  setShowDeleteGraph: (show: boolean) => void;
  graphToBeModified: {
    id: string;
    name: string;
  } | null;
}

const dialogStyles = {
  '& .MuiDialog-paper': {
    bgcolor: 'background.default',
  },
} as const;

export const DeleteConfirmationDialog = React.memo(
  ({
    showDeleteGraph,
    setShowDeleteGraph,
    graphToBeModified,
  }: DeleteConfirmationDialogProps) => {
    const handleClose = useCallback(() => {
      setShowDeleteGraph(false);
    }, [setShowDeleteGraph]);

    const handleDelete = useCallback(() => {
      if (!graphToBeModified) return;

      setShowDeleteGraph(false);
      const storage = PPStorage.getInstance();
      const currentGraphID = PPGraph.currentGraph.id;

      storage.deleteGraph(graphToBeModified.id);

      if (graphToBeModified.id === currentGraphID) {
        storage.loadGraphFromDB();
      }
    }, [setShowDeleteGraph, graphToBeModified]);

    return (
      <Dialog
        open={showDeleteGraph}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        data-cy="deleteDialog"
        sx={dialogStyles}
      >
        <DialogTitle id="alert-dialog-title">Delete playground?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete
            <br />
            <b>{graphToBeModified?.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.showDeleteGraph === nextProps.showDeleteGraph &&
      prevProps.graphToBeModified?.id === nextProps.graphToBeModified?.id &&
      prevProps.graphToBeModified?.name === nextProps.graphToBeModified?.name
    );
  },
);

DeleteConfirmationDialog.displayName = 'DeleteConfirmationDialog';

export const EditDialog = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.showEdit) {
      // Slight delay to ensure the TextField is available
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [props.showEdit]);

  const submitEditDialog = (): void => {
    const name = (
      document.getElementById('playground-name-input') as HTMLInputElement
    ).value;
    props.setShowEdit(false);
    PPStorage.getInstance().renameGraph(props.graphId, name);
  };

  return (
    <Dialog
      open={props.showEdit}
      onClose={() => props.setShowEdit(false)}
      fullWidth
      disableRestoreFocus
      maxWidth="sm"
      data-cy="editDialog"
      sx={{
        '& .MuiDialog-paper': {
          bgcolor: 'background.default',
        },
      }}
    >
      <DialogTitle>Edit playground details</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitEditDialog();
        }}
      >
        <DialogContent>
          <TextField
            id="playground-name-input"
            autoFocus
            margin="dense"
            label="Name of playground"
            fullWidth
            variant="standard"
            defaultValue={`${props.graphName}`}
            placeholder={`${props.graphName}`}
            InputProps={{
              inputRef: inputRef,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setShowEdit(false)}>Cancel</Button>
          <Button
            onClick={() => {
              submitEditDialog();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
