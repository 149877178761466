import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import LeftRightDrawer from './LeftRightDrawer';
import { DrawerSide, IOverlay } from '../utils/interfaces';

type GraphOverlayDrawerProps = {
  randomMainColor: string;
  overlayState: IOverlay;
  updateOverlayState: (newState: Partial<IOverlay>) => void;
  disableHoverOpen: boolean;
};

type DrawerState = {
  width: number;
  visible: boolean;
};

const GraphOverlayDrawer: React.FunctionComponent<GraphOverlayDrawerProps> = (
  props,
) => {
  const [drawerStates, setDrawerStates] = useState<[DrawerState, DrawerState]>([
    {
      width: props.overlayState.leftSide.width,
      visible: props.overlayState.leftSide.visible,
    },
    {
      width: props.overlayState.rightSide.width,
      visible: props.overlayState.rightSide.visible,
    },
  ]);

  useEffect(() => {
    setDrawerStates([
      {
        width: props.overlayState.leftSide.width,
        visible: props.overlayState.leftSide.visible,
      },
      {
        width: props.overlayState.rightSide.width,
        visible: props.overlayState.rightSide.visible,
      },
    ]);
  }, [props.overlayState]);

  const updateDrawerWidth = useCallback(
    (isLeft: boolean) => (width: number) => {
      const side = isLeft ? DrawerSide.LEFT : DrawerSide.RIGHT;
      props.updateOverlayState({
        [side]: {
          visible: true,
          width,
        },
      });
    },
    [props.updateOverlayState],
  );

  return (
    <Box>
      {[true, false].map((isLeft, index) => (
        <LeftRightDrawer
          key={isLeft ? 'left' : 'right'}
          isLeft={isLeft}
          drawerWidth={drawerStates[index].width}
          setDrawerWidth={updateDrawerWidth(isLeft)}
          toggle={drawerStates[index].visible}
          randomMainColor={props.randomMainColor}
        />
      ))}
    </Box>
  );
};

export default GraphOverlayDrawer;
