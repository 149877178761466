import React from 'react';

export const NarrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_690)">
      <path
        d="M6.01 11H2V13H6.01V16L10 12L6.01 8V11Z"
        fill="var(--svg-fill-color)"
      />
      <path
        d="M17.99 11H22V13H17.99V16L14 12L17.99 8V11Z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_690">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const WidenIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.01 11V8L20 12L16.01 16V13H7.99V16L4 12L7.99 8V11H16.01Z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const PNPIconNoShadow = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2782_696)">
      <path
        d="M26.1599 29.256C26.1599 31.88 25.0613 33.192 22.8639 33.192C22.5226 33.192 22.2026 33.1494 21.9039 33.064C21.6053 32.9787 21.3279 32.872 21.0719 32.744V25.704C21.3066 25.576 21.5839 25.4694 21.9039 25.384C22.2239 25.2774 22.5866 25.224 22.9919 25.224C24.0159 25.224 24.7946 25.5547 25.3279 26.216C25.8826 26.8774 26.1599 27.8907 26.1599 29.256Z"
        fill="var(--svg-fill-color)"
      />
      <path
        d="M46.1587 29.256C46.1587 31.88 45.06 33.192 42.8627 33.192C42.5214 33.192 42.2014 33.1494 41.9027 33.064C41.604 32.9787 41.3267 32.872 41.0707 32.744V25.704C41.3054 25.576 41.5827 25.4694 41.9027 25.384C42.2227 25.2774 42.5854 25.224 42.9907 25.224C44.0147 25.224 44.7934 25.5547 45.3267 26.216C45.8814 26.8774 46.1587 27.8907 46.1587 29.256Z"
        fill="var(--svg-fill-color)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM26.3519 21.48C25.2639 21.1387 24.0799 20.968 22.7999 20.968C21.5839 20.968 20.4853 21.096 19.5039 21.352C18.5226 21.608 17.7013 21.9494 17.0399 22.376C16.6133 22.6747 16.2719 23.0054 16.0159 23.368C15.7813 23.7307 15.6639 24.2 15.6639 24.776V40.136C15.6639 40.6694 15.7279 41.096 15.8559 41.416C15.9839 41.7574 16.1759 42.024 16.4319 42.216C16.6879 42.408 16.9973 42.536 17.3599 42.6C17.7439 42.664 18.1706 42.696 18.6399 42.696C19.1306 42.696 19.6106 42.6534 20.0799 42.568C20.5493 42.504 20.8906 42.44 21.1039 42.376V37.064C21.8933 37.3414 22.7466 37.48 23.6639 37.48C24.8799 37.48 25.9786 37.3094 26.9599 36.968C27.9626 36.6267 28.8159 36.1147 29.5199 35.432C30.2239 34.728 30.7679 33.864 31.1519 32.84C31.5359 31.816 31.7279 30.6214 31.7279 29.256C31.7279 27.8267 31.5039 26.6 31.0559 25.576C30.6079 24.5307 29.9893 23.6667 29.1999 22.984C28.4106 22.3014 27.4613 21.8 26.3519 21.48ZM46.3507 21.48C45.2627 21.1387 44.0787 20.968 42.7987 20.968C41.5827 20.968 40.484 21.096 39.5027 21.352C38.5214 21.608 37.7 21.9494 37.0387 22.376C36.612 22.6747 36.2707 23.0054 36.0147 23.368C35.78 23.7307 35.6627 24.2 35.6627 24.776V40.136C35.6627 40.6694 35.7267 41.096 35.8547 41.416C35.9827 41.7574 36.1747 42.024 36.4307 42.216C36.6867 42.408 36.996 42.536 37.3587 42.6C37.7427 42.664 38.1694 42.696 38.6387 42.696C39.1294 42.696 39.6094 42.6534 40.0787 42.568C40.548 42.504 40.8894 42.44 41.1027 42.376V37.064C41.892 37.3414 42.7454 37.48 43.6627 37.48C44.8787 37.48 45.9774 37.3094 46.9587 36.968C47.9614 36.6267 48.8147 36.1147 49.5187 35.432C50.2227 34.728 50.7667 33.864 51.1507 32.84C51.5347 31.816 51.7267 30.6214 51.7267 29.256C51.7267 27.8267 51.5027 26.6 51.0547 25.576C50.6067 24.5307 49.988 23.6667 49.1987 22.984C48.4094 22.3014 47.46 21.8 46.3507 21.48Z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2782_696">
        <rect width="64" height="64" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const DiscordIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const RedditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);

export const BlueskyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_696)">
      <path
        d="M5.2023 3.42242C7.95385 5.48811 10.9133 9.67666 12 11.9242C13.0867 9.67666 16.0462 5.48811 18.7977 3.42242C20.7831 1.93188 24 0.778618 24 4.44842C24 5.18135 23.5798 10.6053 23.3333 11.486C22.4766 14.5473 19.3549 15.3282 16.578 14.8556C21.4319 15.6817 22.6666 18.418 20 21.1544C14.9355 26.3512 12.7209 19.8505 12.1534 18.1847C12.0494 17.8794 12.0007 17.7365 12 17.858C11.9993 17.7365 11.9506 17.8794 11.8466 18.1847C11.2791 19.8505 9.06452 26.3512 4.00001 21.1544C1.33335 18.418 2.56809 15.6817 7.42196 14.8556C4.64506 15.3282 1.52337 14.5473 0.666676 11.486C0.420211 10.6053 0 5.18135 0 4.44842C0 0.778618 3.21693 1.93188 5.2023 3.42242Z"
        fill="var(--svg-fill-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_696">
        <rect width="24" height="24" fill="var(--svg-fill-color)" />
      </clipPath>
    </defs>
  </svg>
);
