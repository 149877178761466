import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useEditor, useNode } from '@craftjs/core';
import PPGraph from '../../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import { getLayoutableElement, useIsSmallScreen } from '../../utils/utils';
import { containerPlaceholder, getBorderStyles } from './Container';
import {
  getDimension,
  handleNodeSelection,
  renderSelectNodeButton,
} from '../../utils/layoutableHelpers';
import { DynamicWidgetProps } from './DynamicWidget';
import { useHoverEvents } from './hooks';

const DashboardContainerName = 'DashboardContainer';

export const DashboardContainer = (props: Partial<DynamicWidgetProps>) => {
  const {
    actions: { setProp },
    id,
  } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }));
  const { isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));
  const isSmallScreen = useIsSmallScreen();
  const [width, setWidth] = useState<any>('100%');
  const [height, setHeight] = useState<any>('auto');
  const [flexDirection, setFlexDirection] = useState<any>('column');
  const [backgroundColor, setBackgroundColor] = useState<any>({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });

  const layoutableElement = getLayoutableElement(props.id);
  const { localHover, handleMouseEnter, handleMouseLeave } =
    useHoverEvents(layoutableElement);
  if (!layoutableElement) {
    return null;
  }

  const sourceId = layoutableElement.getDashboardId();

  useEffect(() => {
    const listenID = InterfaceController.addListener(
      ListenEvent.DashboardContainerChanged,
      (data: any) => {
        if (data.id === sourceId) {
          const newWidth = getDimension(
            'width',
            data.input.widthMode,
            data.input.width,
            isSmallScreen,
          );
          const newHeight = getDimension(
            'height',
            data.input.heightMode,
            data.input.height,
            isSmallScreen,
          );
          setWidth(newWidth);
          setHeight(newHeight);
          setFlexDirection(data.input.flexDirection);
          setBackgroundColor(data.input.backgroundColor);
        }
      },
    );

    return () => {
      InterfaceController.removeListener(listenID);
    };
  }, [sourceId]);

  const isRoot = id === 'ROOT';
  const hasChildren = React.Children.count(props.children) > 0;

  const {
    index,
    randomMainColor,
    disabled,
    children,
    minWidth,
    minHeight,
    showLabel,
  } = props;
  const {
    connectors: { connect, drag },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));
  const { isEditMode } = useEditor((state) => ({
    isEditMode: state.options.enabled,
  }));

  const handleSelectNode = handleNodeSelection(layoutableElement);
  const overlayState = InterfaceController.getOverlayState();
  const dashboardFullscreen = overlayState.dashboard.fullscreen;
  const showButton = localHover && !dashboardFullscreen && !isEditMode;

  return (
    <Box
      ref={(ref: any) => connect(drag(ref))}
      data-cy={`widget of ${layoutableElement.getDashboardId()}`}
      sx={{
        position: 'relative',
        cursor: isEditMode ? 'move' : 'auto',
        width: width,
        height: height,
        background: backgroundColor.toString(),
        ...getBorderStyles(isEditMode, isRoot, flexDirection, isActive),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showButton && renderSelectNodeButton(handleSelectNode)}
      {layoutableElement.getDashboardWrapper({
        index,
        randomMainColor,
        disabled: isEditMode ? true : disabled,
        heightMode: props.heightMode,
        height: props.height,
        widthMode: props.widthMode,
        width: props.width,
        isEditMode,
        components: children,
      })}
      {isEditMode &&
        containerPlaceholder(flexDirection, hasChildren, isRoot, isActive)}
    </Box>
  );
};

const DashboardContainerSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <Stack spacing={0.5} sx={{ bgcolor: 'background.paper' }}>
      <Box sx={{ p: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Settings for this container are controlled through the node properties
        </Typography>
      </Box>
    </Stack>
  );
};

DashboardContainer.craft = {
  displayName: DashboardContainerName,
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => true,
  },
  related: {
    settings: DashboardContainerSettings,
  },
};
