import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useInterval from 'use-interval';
import { Box, IconButton, Menu, MenuItem, ToggleButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningIcon from '@mui/icons-material/Warning';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import InterfaceController from './../InterfaceController';
import {
  COLOR_WARNING,
  DISABLED_OPACITY,
  STATUS_SEVERITY,
} from './../utils/constants';
import { writeDataToClipboard } from './../utils/utils';
import { TRgba } from './../utils/interfaces';
import * as styles from './../utils/style.module.css';
import PPNode from './../classes/NodeClass';
import Socket from './../classes/SocketClass';
import { AbstractType, DataTypeProps } from './../nodes/datatypes/abstractType';
import {
  allDataTypes,
  dropDownSelectableTypes,
} from './../nodes/datatypes/dataTypesMap';
import { property } from 'cypress/types/lodash';

type SocketContainerProps = {
  property: Socket;
  index: number;
  dataType: AbstractType;
  data: any;
  randomMainColor: string;
  selectedNode: PPNode;
};

const onChangeDropdown = (event, props, setDataTypeValue, setHasError) => {
  const { myValue } = event.currentTarget.dataset;
  const entry = new allDataTypes[myValue]();
  props.property.dataType = entry;
  setDataTypeValue(entry);
  props.property.getNode().metaInfoChanged();
  props.property.redraw();
  setHasError(props.property.status.getSeverity() >= STATUS_SEVERITY.WARNING);
};
export const SocketContainer = memo(
  (props: SocketContainerProps) => {
    const [hasError, setHasError] = useState(
      props.property.status.getSeverity() >= STATUS_SEVERITY.WARNING,
    );

    useEffect(() => {
      setDataTypeValue(props.dataType);
    }, [props.dataType]);

    const [dataTypeValue, setDataTypeValue] = useState(props.dataType);

    const baseProps: DataTypeProps = {
      property: props.property,
      index: props.index,
      randomMainColor: props.randomMainColor,
      dataType: props.property.dataType,
    };

    const widget = props.property.isInput()
      ? dataTypeValue.getInputWidget(baseProps)
      : dataTypeValue.getOutputWidget(baseProps);

    useInterval(() => {
      const newHasError =
        props.property.status.getSeverity() >= STATUS_SEVERITY.WARNING;
      if (hasError !== newHasError) {
        setHasError(
          props.property.status.getSeverity() >= STATUS_SEVERITY.WARNING,
        );
      }
    }, 100);

    const disabled = !props.property.isInput() || props.property.hasLink();

    return (
      <Box
        id={`inspector-socket-${props.dataType.getName()}`} // TODO: should be socket id instead of data type name as that is not unique
        sx={{
          bgcolor: hasError ? COLOR_WARNING : 'background.default',
          opacity: disabled && !hasError ? DISABLED_OPACITY : 1,
        }}
      >
        <SocketHeader
          key={`SocketHeader-${props.dataType.getName()}`}
          property={props.property}
          hasError={hasError}
          index={props.index}
          onChangeDropdown={(event) =>
            onChangeDropdown(event, props, setDataTypeValue, setHasError)
          }
          randomMainColor={props.randomMainColor}
        />
        <Box
          sx={{
            px: 1,
            pb: 1,
          }}
          className={styles.propertyContainerContent}
        >
          <SocketBody
            property={props.property}
            randomMainColor={props.randomMainColor}
            selectedNode={props.selectedNode}
            widget={widget}
          />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.property.name === nextProps.property.name &&
      prevProps.index === nextProps.index &&
      prevProps.dataType.getName() === nextProps.dataType.getName() &&
      prevProps.selectedNode.id === nextProps.selectedNode.id
    );
  },
);

interface MenuItemsProps {
  dropDownSelectableTypes: any;
  allDataTypes: any;
  isInput: boolean;
  currentTypeName: string;
  onSelect: (event: React.MouseEvent<HTMLLIElement>) => void;
  handleClose: () => void;
  randomMainColor: string;
}

const MenuItems = React.memo(
  ({
    dropDownSelectableTypes,
    allDataTypes,
    isInput,
    currentTypeName,
    onSelect,
    handleClose,
    randomMainColor,
  }: MenuItemsProps) => {
    return Object.keys(dropDownSelectableTypes)
      .filter((name) => {
        const dataTypeItem = new dropDownSelectableTypes[name]();
        if (isInput) {
          return dataTypeItem.allowedAsInput();
        } else {
          return dataTypeItem.allowedAsOutput();
        }
      })
      .sort()
      .map((name) => {
        const entry = new allDataTypes[name]().getName();
        return (
          <MenuItem
            key={name}
            value={name}
            data-my-value={name}
            selected={currentTypeName === name}
            onClick={(event) => {
              onSelect(event);
              handleClose();
            }}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${TRgba.fromString(randomMainColor).negate()}`,
              },
            }}
          >
            {entry}
          </MenuItem>
        );
      });
  },
);

MenuItems.displayName = 'MenuItems';

type SocketHeaderProps = {
  property: Socket;
  index: number;
  hasError: boolean;
  onChangeDropdown: (event) => void;
  randomMainColor: string;
};

const handleCopyToClipboard = (props) => {
  InterfaceController.showSnackBar('Data copied to clipboard');
  writeDataToClipboard(props.property?.data);
};

const SocketHeader = React.memo(
  (props: SocketHeaderProps) => {
    const [visible, setVisible] = useState(props.property.visible);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleVisibilityToggle = useCallback(() => {
      props.property.setVisible(!visible);
      setVisible((prev) => !prev);
    }, [props.property, visible]);

    const handleAddToDashboard = useCallback(() => {
      InterfaceController.onAddToDashboard(props.property);
    }, [props.property]);

    const typeAvailableInDropdown = useMemo(
      () =>
        dropDownSelectableTypes[props.property.dataType.constructor.name] !==
        undefined,
      [props.property.dataType.constructor.name],
    );

    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
        }}
        title={`${props.hasError ? props.property.status.message : ''}`}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            opacity: visible ? 1 : 0.5,
          }}
        >
          <Box
            sx={{ flexGrow: 1, display: 'inline-flex', alignItems: 'center' }}
          >
            {props.property.hasLink() ? (
              <Box
                sx={{ px: 1, opacity: DISABLED_OPACITY, textAlign: 'center' }}
              >
                <LockIcon sx={{ fontSize: '12px' }} />
              </Box>
            ) : (
              <ToggleButton
                data-cy="socket-visible-button"
                value="check"
                size="small"
                selected={!visible}
                onChange={handleVisibilityToggle}
                sx={{
                  fontSize: '12px',
                  border: 0,
                }}
              >
                {visible ? (
                  <VisibilityIcon fontSize="inherit" />
                ) : (
                  <VisibilityOffIcon fontSize="inherit" />
                )}
              </ToggleButton>
            )}
            <IconButton
              title="Add to dashboard"
              size="small"
              onClick={handleAddToDashboard}
              sx={{
                borderRadius: 0,
              }}
            >
              <DashboardCustomizeIcon sx={{ fontSize: '16px' }} />
            </IconButton>
            <IconButton
              size="small"
              title="Copy to clipboard"
              onClick={() => handleCopyToClipboard(props)}
              sx={{
                pl: 0.5,
                borderRadius: 0,
              }}
            >
              <ContentCopyIcon sx={{ fontSize: '12px' }} />
            </IconButton>
            <Box sx={{ p: 0.5, color: 'text.primary', fontSize: '14px' }}>
              {props.property.name}
            </Box>
            {props.hasError && (
              <WarningIcon
                sx={{
                  fontSize: '16px',
                  pl: 0.5,
                }}
              />
            )}
          </Box>
          <IconButton
            data-cy={props.property.name + '-type-selector-button'}
            title={props.property.dataType.constructor.name}
            aria-label="more"
            id="select-type"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              borderRadius: 0,
              pr: 1.5,
            }}
          >
            <Box
              sx={{
                color: 'text.secondary',
                fontSize: '10px',
              }}
            >
              {props.property.dataType.getName()}
            </Box>
            {typeAvailableInDropdown && (
              <MoreVertIcon sx={{ fontSize: '12px' }} />
            )}
          </IconButton>
          {typeAvailableInDropdown && (
            <Menu
              sx={{
                fontSize: '12px',
                zIndex: 1500,
              }}
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItems
                dropDownSelectableTypes={dropDownSelectableTypes}
                allDataTypes={allDataTypes}
                isInput={props.property.isInput()}
                currentTypeName={props.property.dataType.constructor.name}
                onSelect={props.onChangeDropdown}
                handleClose={handleClose}
                randomMainColor={props.randomMainColor}
              />
            </Menu>
          )}
        </Box>
      </Box>
    );
  },
  (prevProps, newProps) => {
    const NP = prevProps.property;
    const OP = prevProps.property;
    return (
      NP.visible === OP.visible &&
      prevProps.property.hasLink() === newProps.property.hasLink() &&
      prevProps.property.name === newProps.property.name &&
      prevProps.hasError === newProps.hasError
    );
  },
);

type SocketBodyProps = {
  property: Socket;
  selectedNode: PPNode;
  widget: React.ReactNode;
  randomMainColor: string;
};

export const SocketBody: React.FunctionComponent<SocketBodyProps> = (props) => {
  return props.widget;
};
