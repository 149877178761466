import { TRgba } from '../../utils/interfaces';
import { AbstractType, dataTypeWidgetDefaultProps } from './abstractType';

/* eslint-disable prettier/prettier */
export class AnyType extends AbstractType {
  getName(): string {
    return 'Any';
  }

  getDefaultWidgetProps() {
    return {
      ...dataTypeWidgetDefaultProps,
      height: '240px',
      heightMode: 'fixed' as const,
    };
  }

  getDefaultValue(): any {
    return 0;
  }

  getColor(): TRgba {
    return new TRgba(204, 204, 204);
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['Label', 'CodeEditor', 'LogViewer', 'ConsolePrint'];
  }

  prefersToChangeAwayFromThisType(): boolean {
    return true;
  }

  recommendedInputNodeWidgets(): string[] {
    return [
      'CodeEditor',
      'CONSTANT_String',
      'CONSTANT_Number',
      'WidgetButton',
      'WidgetSlider',
      'WidgetSwitch',
      'Label',
      'WidgetColorPicker',
    ];
  }
}
