import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Drawer } from '@mui/material';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import NodeInspectorContainer from '../containers/NodeInspectorContainer';
import GraphInspectorContainer from '../containers/GraphInspectorContainer';
import { LeftsideContainer } from '../containers/LeftsideContainer';
import { useIsSmallScreen } from '../utils/utils';
import { DRAWER_CONSTANTS } from '../utils/constants';
import { DrawerContent, TRgba } from '../utils/interfaces';
import PPGraph from '../classes/GraphClass';
import * as styles from '../utils/style.module.css';

// Types
interface LeftRightDrawerProps {
  isLeft: boolean;
  drawerWidth: number;
  setDrawerWidth: (width: number) => void;
  toggle: boolean;
  randomMainColor: string;
}

// Main Component
const LeftRightDrawer: React.FC<LeftRightDrawerProps> = ({
  isLeft,
  drawerWidth,
  setDrawerWidth,
  toggle,
  randomMainColor,
}) => {
  // State
  const [nodeFilter, setNodeFilter] = useState<string | null>(null);
  const [graphFilter, setGraphFilter] = useState('nodes');
  const [graphFilterText, setGraphFilterText] = useState('');
  const [activeContent, setActiveContent] = useState<DrawerContent>('graphs');
  const [isOpen, setIsOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  // Refs
  const drawerRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number>();

  // Hooks
  const smallScreen = useIsSmallScreen();

  const resizeHandler = useRef({
    isResizing: false,
    startWidth: 0,
    startX: 0,
  });

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      // Cancel previous animation frame if it exists
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Request new animation frame
      animationFrameRef.current = requestAnimationFrame(() => {
        const delta = isLeft
          ? e.clientX - resizeHandler.current.startX
          : resizeHandler.current.startX - e.clientX;

        const newWidth = Math.min(
          Math.max(
            resizeHandler.current.startWidth + delta,
            DRAWER_CONSTANTS.MIN_DRAWER_WIDTH,
          ),
          DRAWER_CONSTANTS.MAX_DRAWER_WIDTH,
        );

        const truncatedWidth = Math.floor(newWidth);
        setDrawerWidth(truncatedWidth);
      });
    },
    [isLeft, setDrawerWidth],
  );

  const handlePointerUp = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      resizeHandler.current.isResizing = false;
      setIsResizing(false);

      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, setDrawerWidth, drawerWidth],
  );

  const handleMouseDown = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();
      e.stopPropagation();

      resizeHandler.current = {
        isResizing: true,
        startWidth: drawerWidth,
        startX: e.clientX,
      };

      setIsResizing(true);

      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, handlePointerUp, drawerWidth],
  );

  // Use cleanup effect for safety
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('pointercancel', handlePointerUp);
    };
  }, [handlePointerMove, handlePointerUp]);

  useEffect(() => {
    if (toggle) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [toggle]);

  const [selectedNodes, setSelectedNodes] = useState(
    PPGraph?.currentGraph?.selection?.selectedNodes || [],
  );

  useEffect(() => {
    const ids = [];
    ids.push(
      InterfaceController.addListener(
        ListenEvent.DrawerContentChanged,
        (content: DrawerContent) => {
          setActiveContent(content);
        },
      ),
    );
    ids.push(
      InterfaceController.addListener(ListenEvent.SelectionChanged, (nodes) =>
        setSelectedNodes(nodes),
      ),
    );

    return () => {
      ids.forEach((id) => InterfaceController.removeListener(id));
    };
  }, []);

  // Render methods
  const renderDrawerContent = () => {
    if (isLeft) {
      return (
        <LeftsideContainer
          activeContent={activeContent}
          randomMainColor={randomMainColor}
        />
      );
    }

    return selectedNodes.length ? (
      <NodeInspectorContainer
        selectedNodes={selectedNodes}
        randomMainColor={randomMainColor}
        filter={nodeFilter}
        setFilter={setNodeFilter}
      />
    ) : (
      <GraphInspectorContainer
        selectedNodes={selectedNodes}
        randomMainColor={randomMainColor}
        filter={graphFilter}
        setFilter={setGraphFilter}
        filterText={graphFilterText}
        setFilterText={setGraphFilterText}
      />
    );
  };

  const backgroundColor = isLeft
    ? TRgba.fromString(randomMainColor).darken(0.8)
    : TRgba.fromString(randomMainColor).setAlpha(0.98);

  return (
    <>
      <Drawer
        anchor={isLeft ? 'left' : 'right'}
        variant="persistent"
        hideBackdrop={true}
        open={isOpen}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            zIndex: isLeft ? 10 : 4,
            width: smallScreen ? '100%' : drawerWidth,
            border: 0,
            background: backgroundColor.toString(),
            height: '100vh',
            paddingLeft: !isLeft && smallScreen ? '40px' : undefined,
          },
          ref: drawerRef,
        }}
      >
        <div
          onPointerDown={handleMouseDown}
          className={isLeft ? styles.draggerLeft : styles.dragger}
          style={{ cursor: isResizing ? 'col-resize' : undefined }}
        />
        {isOpen && renderDrawerContent()}
      </Drawer>
    </>
  );
};

export default React.memo(LeftRightDrawer, (prevProps, nextProps) => {
  return (
    prevProps.drawerWidth === nextProps.drawerWidth &&
    prevProps.toggle === nextProps.toggle
  );
});
