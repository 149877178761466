import React from 'react';
import { Slider, ThemeProvider, Typography, Box } from '@mui/material';
import { WidgetHybridBase, WidgetPaper, labelName, outName } from './abstract';
import Socket from '../../classes/SocketClass';
import { StringType } from '../datatypes/stringType';
import { BooleanType } from '../datatypes/booleanType';
import { NumberType } from '../datatypes/numberType';
import { BackPropagation } from '../../interfaces';
import { SOCKET_TYPE, customTheme } from '../../utils/constants';
import {
  ActionHandler,
  BakedAction,
  SerializableAction,
  SerializableActionHandler,
} from '../../classes/Action';
import { limitRange } from '../../utils/utils';

// Socket names
const initialValueName = 'Initial Value';
const minValueName = 'Min';
const maxValueName = 'Max';
const roundName = 'Round';

export class WidgetSlider extends WidgetHybridBase {
  public getName(): string {
    return 'Slider';
  }

  public getDescription(): string {
    return 'Adds a number slider';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, initialValueName, new NumberType(), 0, false),
      new Socket(SOCKET_TYPE.IN, minValueName, new NumberType(), 0, false),
      new Socket(SOCKET_TYPE.IN, maxValueName, new NumberType(), 100, false),
      new Socket(SOCKET_TYPE.IN, roundName, new BooleanType(), false, false),
      new Socket(SOCKET_TYPE.IN, labelName, new StringType(), 'Slider', false),
      new Socket(SOCKET_TYPE.OUT, outName, new NumberType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(initialValueName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    const value = inputObject[initialValueName];
    const minValue = inputObject[minValueName];
    const maxValue = inputObject[maxValueName];

    // Ensure value is within min/max range
    const limitedValue = limitRange(value, minValue, maxValue);
    this.setOutputData(outName, limitedValue);
  }

  handleOnChange = async (event, newValue) => {
    const id = this.id;
    const prev = this.getInputData(initialValueName);
    const shouldRound = this.getInputData(roundName);

    // Round the value if needed
    const formattedValue = shouldRound ? Math.round(newValue) : newValue;

    const applyFunction = async (value) => {
      const safeNode = SerializableActionHandler.getSafeNode(id);
      safeNode.setInputData(initialValueName, value);
      safeNode.setOutputData(outName, value);
      await safeNode.executeOptimizedChain();
    };

    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          'Set Slider Value',
        ),
        formattedValue,
        prev,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;
    const min = props[minValueName];
    const max = props[maxValueName];
    const value = props[initialValueName];
    const shouldRound = props[roundName];

    // Format the value displayed based on rounding setting
    const displayValue = shouldRound
      ? Math.round(value)
      : Number(value.toFixed(2));

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              overflow: 'hidden',
            }}
          >
            <Typography
              id={`slider-label-${node.id}`}
              gutterBottom
              sx={{
                fontSize: props.inDashboard
                  ? '16px'
                  : `${node.nodeHeight / 8}px`,
                fontWeight: 500,
                textAlign: 'center',
              }}
            >
              {props[labelName]}
              {`${props[labelName] !== '' ? ': ' : ''} `}
              {displayValue}
            </Typography>
            <Slider
              disabled={props.disabled}
              aria-labelledby={`slider-label-${node.id}`}
              value={value}
              min={min}
              max={max}
              step={shouldRound ? 1 : 0.01}
              onChange={node.handleOnChange}
              valueLabelDisplay="off"
              sx={{
                width: '100%',
                padding: 0,
                pointerEvents: 'auto',
                height: props.inDashboard ? '32px' : node.nodeHeight / 3,
                '& .MuiSlider-track': {
                  border: 'none',
                },
                borderRadius: 2,
                '& .MuiSlider-thumb': {
                  height: props.inDashboard ? '32px' : node.nodeHeight / 3,
                  width: 16,
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'inherit',
                  },

                  '&::before': {
                    display: 'none',
                  },
                },
              }}
            />
          </Box>
        </WidgetPaper>
      </ThemeProvider>
    );
  }

  public async populateDefaults(socket: Socket): Promise<void> {
    const target = socket;
    if (
      target.dataType.constructor === new NumberType().constructor &&
      0 === this.getInputData(initialValueName)
    ) {
      const { round, minValue, maxValue } = target.dataType as NumberType;
      this.setInputData(minValueName, minValue);
      this.setInputData(maxValueName, maxValue);
      this.setInputData(roundName, round);
      this.setInputData(initialValueName, target.defaultData);
      this.setInputData(labelName, target.name);
    }
    await super.populateDefaults(socket);
  }
}
