import PPStorage from '../PPStorage';
import { hri } from 'human-readable-ids';
import PPGraph from '../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { getAllNodesInDetail } from '../nodes/allNodes';
import { FirebaseAppHandler } from '../firebase/FirebaseAppHandler';

export const DEFAULT_MODEL = 'claude-3-7-sonnet-latest';

export enum AIConversationSender {
  USER,
  AI,
}

const INSTRUCTION_SEPARATOR = '$$$$$\n\n';

export interface AIConversationMessage {
  sender: AIConversationSender;
  content: string;
  date: Date;
}

// Define types for Claude API content items
export interface ClaudeTextContent {
  type: 'text';
  text: string;
}

export interface ClaudeImageContent {
  type: 'image';
  source: {
    type: 'base64';
    media_type: string;
    data: string;
  };
}

// Union type for all content types
export type ClaudeContentItem = ClaudeTextContent | ClaudeImageContent;

export interface ClaudeConversationMessage {
  role: 'user' | 'assistant';
  content: string | ClaudeContentItem[]; // Either string or an array of properly typed content items
}

export class AIBackend {
  conversations: Record<string, AIConversationMessage[]> = {
    'Conversation 1': [],
  };

  private static instance: AIBackend = undefined;
  static getInstance() {
    if (this.instance == undefined) {
      this.instance = new AIBackend();
    }
    return this.instance;
  }

  public getConversation(id: string): AIConversationMessage[] {
    if (id in this.conversations) {
      return this.conversations[id];
    } else {
      return [];
    }
  }

  private conversationToClaudeConversation(
    conversation: AIConversationMessage[],
  ): ClaudeConversationMessage[] {
    return conversation.map((entry) => ({
      role: entry.sender === AIConversationSender.USER ? 'user' : 'assistant',
      content: entry.content,
    }));
  }

  public static removeInstructionText(text: string) {
    if (text.includes(INSTRUCTION_SEPARATOR)) {
      return text.split(INSTRUCTION_SEPARATOR)[1];
    } else return text;
  }

  // Format Claude API messages consistently
  private formatClaudeMessages(
    conversationMessages: ClaudeConversationMessage[],
    model: string,
    max_tokens: number,
  ): string {
    return JSON.stringify({
      messages: conversationMessages,
      model: model,
      max_tokens,
      temperature: 0.7,
    });
  }

  public async sendMessage(
    conversationID: string,
    message: string, // Just the text prompt
    model: string,
    context: { selectedNodes: boolean; entireGraph: boolean },
    retainConvo = true,
    max_tokens: number = 4096,
    images?: string[], // Optional array of base64 image strings
  ) {
    if (!FirebaseAppHandler.getInstance().getIsLoggedIn()) {
      InterfaceController.showSnackBar(
        'You need to be logged in to use AI features',
      );
      return;
    }

    let myConvo = this.getConversation(conversationID);
    // when sending a new message, filter out error messages that might have come from before
    for (let i = 0; i < myConvo.length; i++) {
      const msg = myConvo[i];
      if (
        msg.sender == AIConversationSender.AI &&
        msg.content.startsWith('Something went wrong')
      ) {
        myConvo.splice(i - 1, 2);
        i--;
      }
    }

    const convo: ClaudeConversationMessage[] =
      this.conversationToClaudeConversation(myConvo);

    let textMessage = message;

    if (retainConvo) {
      let preMessageContent = '';
      if (convo.length == 0) {
        preMessageContent += await this.getConversationStartInstructions();
      }
      if (context.selectedNodes) {
        preMessageContent += this.getSelectedNodesContext();
      }
      if (context.entireGraph) {
        preMessageContent += this.getEntireGraphContext();
      }
      if (preMessageContent.length) {
        preMessageContent += INSTRUCTION_SEPARATOR;
      }
      textMessage = preMessageContent + textMessage;
    }

    const sentDate = new Date();

    // Create the message with the appropriate format based on whether we have images
    let messageContent: string | ClaudeContentItem[] = textMessage;

    // If we have images, format the content for the Claude API
    if (images && images.length > 0) {
      messageContent = [
        // Add all images as separate content items
        ...images.map((img) => {
          const imageContent: ClaudeImageContent = {
            type: 'image',
            source: {
              type: 'base64',
              media_type: this.getMediaTypeFromImage(img),
              data: img.replace(/^data:image\/[a-z]+;base64,/, ''),
            },
          };
          return imageContent;
        }),
        // Add the text prompt as the final content item
        {
          type: 'text',
          text: textMessage,
        } as ClaudeTextContent,
      ];
    }

    const newMessage: ClaudeConversationMessage = {
      role: 'user',
      content: messageContent,
    };

    const myNewMessage = {
      content: textMessage,
      sender: AIConversationSender.USER,
      date: sentDate,
    };

    if (retainConvo) {
      myConvo.push(myNewMessage);
      this.conversations[conversationID] = myConvo;
      InterfaceController.notifyListeners(
        ListenEvent.newAIMessageArrived,
        myConvo,
      );
    }

    // Add the new message to the conversation
    convo.push(newMessage);

    // Format the messages for the Claude API
    const finalBody = this.formatClaudeMessages(convo, model, max_tokens);

    let backendResponse = undefined;

    try {
      const res = await fetch('/auth/ai-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(await FirebaseAppHandler.getInstance().getAuthHeader()),
        },
        body: finalBody,
      });
      backendResponse = await res.json();

      // Normalize the response to a consistent structure
      let normalizedResponse;
      const success = res?.status < 300;

      // Ensure response has a consistent structure
      if (!backendResponse) {
        normalizedResponse = {
          success: false,
          status: 500,
          message: 'Empty response from server',
        };
      }
      // If response already has success flag and data, use it directly
      else if (backendResponse.success === true && backendResponse.data) {
        // Response is already in the correct format
        normalizedResponse = backendResponse;
      }
      // Detect response structure and normalize
      else {
        // Response has data but missing success flag
        normalizedResponse = {
          success: success,
          data: backendResponse.data ? backendResponse.data : backendResponse,
          status: 200,
        };
      }

      // Process for conversation tracking if needed
      if (retainConvo) {
        let assistantMessage = '';
        try {
          // Try to extract the text from the assistant's response
          if (normalizedResponse.success && normalizedResponse.data?.content) {
            assistantMessage = normalizedResponse.data.content[0].text;
          } else {
            if (!normalizedResponse.success) {
              assistantMessage =
                'Failure: ' + JSON.stringify(normalizedResponse.data);
            } else {
              assistantMessage =
                'I have no mouth and I must scream: ' +
                JSON.stringify(normalizedResponse.data);
            }
          }
        } catch (error) {
          assistantMessage = 'Error processing response: ' + error.message;
        }

        const AIMessage: AIConversationMessage = {
          content: assistantMessage,
          sender: AIConversationSender.AI,
          date: new Date(),
        };

        myConvo.push(AIMessage);
        this.conversations[conversationID] = myConvo;
        InterfaceController.notifyListeners(
          ListenEvent.newAIMessageArrived,
          normalizedResponse,
        );
      }

      return normalizedResponse;
    } catch (error) {
      return {
        success: false,
        status: error.status || 500,
        message: error.message || 'Unknown error occurred',
      };
    }
  }

  // Helper method to extract media type from base64 image
  private getMediaTypeFromImage(dataUrl: string): string {
    const match = dataUrl.match(/^data:([^;]+);/);
    return match ? match[1] : 'image/png'; // default to png if no match
  }

  async getConversationStartInstructions(): Promise<string> {
    const metaRundown =
      'This is gonna sound pretty confusing, but here is the setup, I am using claude API (you) to help users of my software navigate it, to do this I am starting a new conversation using the Claude API, with a long intro to get Claude (you) to understand the context, I will paste this context here, tell me what you think about this approach, if you think I should do it some other way, or modify the intro text, here it is: ';

    let quickRundown =
      "You are helping a user with their Plug and Playground project. Plug and Playground is a node-based app builder using javascript that allows using pre-made nodes as well as raw javascript in 'Custom Function' nodes, these are arrow functions, for example '(a) => {return a;}', the user can at any time include either currently selected nodes or the entire graph in their messages - or both, you can ask the user to provide these at any time. The user is generally not able to make sense of serialized node data, that data is for you. \n Here is a list of all available nodes: " +
      JSON.stringify(await getAllNodesInDetail());

    quickRundown +=
      '\n\n Node graphs can be copied either in full or in part. Here is an example of a correctly formatted node configuration that you should follow exactly:' +
      JSON.stringify(SERIALIZED_EXAMPLE);

    quickRundown +=
      '\n\n The user can also drag and drop text/csv/images in the graph';
    quickRundown +=
      '\n\n after this the conversation with the user will be ongoing,  now starts the users input (and potentially provided context):\n';

    return quickRundown;
  }

  getSelectedNodesContext(): string {
    return (
      '\n\nSelected nodes and links between them in serialized form:\n' +
      JSON.stringify(PPGraph.currentGraph.serializeSelection())
    );
  }
  getEntireGraphContext(): string {
    return (
      '\n\nEntire graph in serialized form:\n' +
      JSON.stringify(PPGraph.currentGraph.serialize())
    );
  }
}

const SERIALIZED_EXAMPLE = `{
  "version": 0.1,
  "nodes": [
    {
      "id": "grumpy-swan-54",
      "name": "Custom function",
      "type": "CustomFunction",
      "x": -3182.1151341608975,
      "y": -6154.385206923524,
      "width": 160,
      "height": 88,
      "socketArray": [
        {
          "socketType": "in",
          "name": "Code",
          "dataType": "{\"class\":\"CodeType\",\"type\":{}}",
          "data": "(a) => {\n\treturn a;\n}",
          "visible": false
        },
        {
          "socketType": "in",
          "name": "Main Thread",
          "dataType": "{\"class\":\"BooleanType\",\"type\":{}}",
          "data": false,
          "visible": false
        },
        {
          "socketType": "in",
          "name": "a",
          "dataType": "{\"class\":\"AnyType\",\"type\":{}}",
          "data": 0,
          "visible": true
        },
        {
          "socketType": "out",
          "name": "OutData",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        },
        {
          "socketType": "out",
          "name": "Code",
          "dataType": "{\"class\":\"CodeType\",\"type\":{}}",
          "visible": false
        }
      ],
      "updateBehaviour": {
        "load": false,
        "update": true,
        "interval": false,
        "intervalFrequency": 1000
      },
      "version": 3
    },
    {
      "id": "serious-chicken-26",
      "name": "Add (+)",
      "type": "Add",
      "x": -2966.3390556207005,
      "y": -6177.4292541462655,
      "width": 160,
      "height": 112,
      "socketArray": [
        {
          "socketType": "in",
          "name": "Addend",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        },
        {
          "socketType": "in",
          "name": "Addend 2",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "data": 0,
          "visible": true
        },
        {
          "socketType": "out",
          "name": "Added",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        }
      ],
      "updateBehaviour": {
        "load": false,
        "update": true,
        "interval": false,
        "intervalFrequency": 1000
      }
    }
  ],
  "links": [
    {
      "id": "1234efad-5693-4b1c-a64c-82c99a6d13e3",
      "sourceNodeId": "grumpy-swan-54",
      "sourceSocketName": "OutData",
      "targetNodeId": "serious-chicken-26",
      "targetSocketName": "Addend"
    }
  ]
}`;
