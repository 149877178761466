import React, { useState, useEffect } from 'react';
import { FirebaseAppHandler } from '../firebase/FirebaseAppHandler';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Alert,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import UserProfile from './UserProfile';

enum AuthMode {
  SIGN_IN,
  SIGN_UP,
  RESET_PASSWORD,
  PROFILE,
}

const Authentication: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [mode, setMode] = useState<AuthMode>(AuthMode.SIGN_IN);
  const [loading, setLoading] = useState(false);

  const auth = FirebaseAppHandler.getInstance();
  const currentUser = auth.getCurrentUser();

  useEffect(() => {
    // When user signs in, switch to profile view
    if (currentUser && mode !== AuthMode.PROFILE) {
      setMode(AuthMode.PROFILE);
    }
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      switch (mode) {
        case AuthMode.SIGN_IN:
          await auth.signInWithEmail(email, password);
          setSuccess('Signed in successfully!');
          break;
        case AuthMode.SIGN_UP:
          await auth.signUpWithEmail(email, password);
          setSuccess('Account created successfully!');
          break;
        case AuthMode.RESET_PASSWORD:
          await auth.resetPassword(email);
          setSuccess('Password reset email sent!');
          break;
      }
    } catch (error: any) {
      setError(error.message || 'An error occurred');
    }

    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      await auth.signInWithGoogle();
      setSuccess('Signed in with Google successfully!');
    } catch (error: any) {
      setError(error.message || 'An error occurred');
    }

    setLoading(false);
  };

  const handleSignOut = async () => {
    setError(null);
    setSuccess(null);
    try {
      await auth.signOutUser();
      // Switch back to sign-in mode after signing out
      setMode(AuthMode.SIGN_IN);
      setSuccess('Signed out successfully!');
    } catch (error: any) {
      setError(error.message || 'An error occurred');
    }
  };

  if (currentUser && mode === AuthMode.PROFILE) {
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Your Account
          </Typography>

          <UserProfile onSignOut={handleSignOut} />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {success}
            </Alert>
          )}
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          {mode === AuthMode.SIGN_IN
            ? 'Sign In'
            : mode === AuthMode.SIGN_UP
              ? 'Sign Up'
              : 'Reset Password'}
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {mode !== AuthMode.RESET_PASSWORD && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete={
                mode === AuthMode.SIGN_UP ? 'new-password' : 'current-password'
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : mode === AuthMode.SIGN_IN ? (
              'Sign In'
            ) : mode === AuthMode.SIGN_UP ? (
              'Sign Up'
            ) : (
              'Send Reset Email'
            )}
          </Button>
        </Box>

        {mode === AuthMode.SIGN_IN && (
          <>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              disabled={loading}
              sx={{ mt: 1, mb: 2 }}
            >
              Sign in with Google
            </Button>

            <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider>

            <Stack spacing={1}>
              <Button
                color="secondary"
                onClick={() => setMode(AuthMode.SIGN_UP)}
                sx={{ textTransform: 'none' }}
              >
                Need an account? Sign Up
              </Button>
              <Button
                color="secondary"
                onClick={() => setMode(AuthMode.RESET_PASSWORD)}
                sx={{ textTransform: 'none' }}
              >
                Forgot password?
              </Button>
            </Stack>
          </>
        )}

        {mode === AuthMode.SIGN_UP && (
          <Button
            fullWidth
            color="secondary"
            onClick={() => setMode(AuthMode.SIGN_IN)}
            sx={{ mt: 1, textTransform: 'none' }}
          >
            Already have an account? Sign In
          </Button>
        )}

        {mode === AuthMode.RESET_PASSWORD && (
          <Button
            fullWidth
            color="secondary"
            onClick={() => setMode(AuthMode.SIGN_IN)}
            sx={{ mt: 1, textTransform: 'none' }}
          >
            Back to Sign In
          </Button>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default Authentication;
