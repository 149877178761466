import React from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import Socket from '../../classes/SocketClass';
import {
  defaultOptions,
  labelName,
  optionsName,
  outName,
  selectedOptionName,
  WidgetHybridBase,
  WidgetPaper,
} from './abstract';
import { SOCKET_TYPE, customTheme } from '../../utils/constants';
import { ArrayType } from '../datatypes/arrayType';
import { StringType } from '../datatypes/stringType';
import {
  ActionHandler,
  BakedAction,
  SerializableAction,
  SerializableActionHandler,
} from '../../classes/Action';
import { BackPropagation } from '../../interfaces';

enum DropdownType {
  SINGLE = 'Dropdown (single select)',
  MULTI = 'Dropdown (multi select)',
}

const dropDownDefaultName = 'Dropdown';

// Base abstract class for shared functionality
abstract class WidgetDropdownBase extends WidgetHybridBase {
  protected abstract isSingle(): boolean;
  protected abstract validateAndFormatSelected(
    selected: unknown,
    options: any[],
  ): unknown;
  protected abstract formatSelected(selected: unknown): unknown;
  protected abstract getDefaultSelectedSocket(): Socket;

  public getName(): string {
    return this.getDropdownType();
  }

  public getDescription(): string {
    return `Adds a ${this.getDropdownType().toLowerCase()} dropdown to select values`;
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedOptionName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected getDropdownType(): DropdownType {
    return this.isSingle() ? DropdownType.SINGLE : DropdownType.MULTI;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    const options = inputObject[optionsName];
    const formattedValue = this.validateAndFormatSelected(
      inputObject[selectedOptionName],
      options,
    );
    this.setInputData(selectedOptionName, formattedValue);
    this.setOutputData(outName, formattedValue);
  }

  handleOnChange = async (event) => {
    const {
      target: { value },
    } = event;
    const options = this.getInputData(optionsName);
    const id = this.id;
    const formattedValue = this.validateAndFormatSelected(value, options);
    const prev = this.getInputData(selectedOptionName);
    const applyFunction = async (newValue) => {
      const safeNode = SerializableActionHandler.getSafeNode(
        id,
      ) as WidgetDropdownBase;
      safeNode.setInputData(selectedOptionName, newValue);
      safeNode.setOutputData(outName, newValue);
      await safeNode.executeOptimizedChain();
    };
    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          `${this.getDropdownType()} Apply Value`,
        ),
        formattedValue,
        prev,
      ),
    );
  };

  protected parseSelected(selected: unknown): unknown {
    if (typeof selected === 'string') {
      try {
        return JSON.parse(selected);
      } catch (e) {
        return selected;
      }
    }
    return selected;
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        optionsName,
        new ArrayType(),
        defaultOptions,
        false,
      ),
      this.getDefaultSelectedSocket(),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        dropDownDefaultName,
        false,
      ),
    ];
  }

  public getWidgetContent(props: any): React.ReactElement {
    const node = props.node;

    const renderMenuItems = () => {
      if (!Array.isArray(props[optionsName])) return null;

      return props[optionsName].map((name) => (
        <MenuItem key={name} value={name}>
          {!node.isSingle() && (
            <Checkbox
              checked={
                (
                  node.formatSelected(props[selectedOptionName]) as string[]
                ).indexOf(name) > -1
              }
            />
          )}
          <ListItemText primary={name} />
        </MenuItem>
      ));
    };

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <FormControl variant="filled" sx={{ pointerEvents: 'auto' }}>
            <InputLabel>{props[labelName]}</InputLabel>
            <Select
              variant="filled"
              disabled={props.disabled}
              multiple={!node.isSingle()}
              value={node.formatSelected(props[selectedOptionName])}
              onChange={(event) => {
                node.handleOnChange(event);
              }}
              renderValue={(selected) =>
                Array.isArray(selected) ? selected.join(', ') : selected
              }
              MenuProps={getMenuProps()}
            >
              {renderMenuItems()}
            </Select>
          </FormControl>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}

// Single Select Dropdown
export class WidgetDropdown extends WidgetDropdownBase {
  protected getDefaultIO(): Socket[] {
    return [new Socket(SOCKET_TYPE.OUT, outName, new StringType())].concat(
      super.getDefaultIO(),
    );
  }

  protected isSingle(): boolean {
    return true;
  }

  protected validateAndFormatSelected(
    selected: unknown,
    options: any[],
  ): string {
    const formatted = this.formatSelected(selected);
    return options.includes(formatted) ? formatted : '';
  }

  protected formatSelected(selected: unknown): string {
    const parsed = this.parseSelected(selected);
    return Array.isArray(parsed) ? parsed.join(',') : String(parsed);
  }

  protected getDefaultSelectedSocket(): Socket {
    return new Socket(
      SOCKET_TYPE.IN,
      selectedOptionName,
      new StringType(),
      undefined,
      false,
    );
  }

  public getVersion(): number {
    return 2;
  }

  public async migrate(previousVersion: number): Promise<void> {
    if (previousVersion < 2) {
      this.removeSocket(this.getInputSocketByName('Select multiple'));
    }
  }
}

// Multi Select Dropdown
export class WidgetMultiDropdown extends WidgetDropdownBase {
  protected getDefaultIO(): Socket[] {
    return [new Socket(SOCKET_TYPE.OUT, outName, new ArrayType())].concat(
      super.getDefaultIO(),
    );
  }

  protected isSingle(): boolean {
    return false;
  }

  protected validateAndFormatSelected(
    selected: unknown,
    options: any[],
  ): string[] {
    const formatted = this.formatSelected(selected);
    // Return options in their original order, only keeping those that were selected
    return options.filter((option) => formatted.includes(option));
  }

  protected formatSelected(selected: unknown): string[] {
    const parsed = this.parseSelected(selected);
    return Array.isArray(parsed) ? parsed : String(parsed).split(',');
  }

  protected getDefaultSelectedSocket(): Socket {
    return new Socket(
      SOCKET_TYPE.IN,
      selectedOptionName,
      new ArrayType(),
      undefined,
      false,
    );
  }
}

// Utility function for menu props
const getMenuProps = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  return {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
      },
    },
  };
};
