import Socket from '../../classes/SocketClass';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import { SOCKET_TYPE, NODE_TYPE_COLOR } from '../../utils/constants';
import { TRgba } from '../../utils/interfaces';
import { JSONType } from '../datatypes/jsonType';
import { StringType } from '../datatypes/stringType';
import { HTTPNode, outputContentName, urlInputName } from './http';
import { ArrayType } from '../datatypes/arrayType';
import { DEFAULT_MODEL } from '../../backend/AIBackend';
import { AIBackend } from '../../backend/AIBackend';

const claudePromptName = 'Prompt';
const claudeOptionsName = 'Options';
const claudeModelName = 'Model';
const claudeBase64ImagesName = 'Images';

export class ClaudeNode extends HTTPNode {
  public getName(): string {
    return 'Claude AI';
  }

  public getDescription(): string {
    return 'Direct integration with Claude API. Requires being signed in to use. Supports both text and image inputs.';
  }

  public getAdditionalDescription(): string {
    return '';
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(false, false, false, 1000, this);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        claudePromptName,
        new StringType(),
        'Give me a quick rundown of the battle of Hastings',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        claudeModelName,
        new StringType(),
        DEFAULT_MODEL,
      ),
      new Socket(SOCKET_TYPE.IN, claudeOptionsName, new JSONType(), {
        max_tokens: 4096,
        temperature: 0.7,
      }),
      new Socket(SOCKET_TYPE.IN, claudeBase64ImagesName, new ArrayType()),
      new Socket(SOCKET_TYPE.OUT, outputContentName, new JSONType(), {}),
    ];
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    try {
      const finalOptions = JSON.parse(
        JSON.stringify(inputObject[claudeOptionsName]),
      );
      const base64Images = inputObject[claudeBase64ImagesName] || [];
      const promptText = inputObject[claudePromptName];
      const model = inputObject[claudeModelName];

      // Generate a unique conversation ID for this node execution
      const conversationID = `claude-node-${this.id}`;
      const aiBackend = AIBackend.getInstance();

      // Call AIBackend's sendMessage method - let the backend handle complex formatting
      const response = await aiBackend.sendMessage(
        conversationID,
        promptText,
        model,
        { selectedNodes: false, entireGraph: false },
        false, // Don't retain conversation for node executions
        finalOptions.max_tokens || 4096,
        base64Images, // Pass images array separately, let backend handle formatting
      );

      // Simplified response handling
      if (response?.success && response.data) {
        outputObject[outputContentName] = response.data;
        this.pushStatusCode(200);
      } else {
        this.pushStatusCode(403);
        outputObject[outputContentName] = {
          error: true,
          message: `Claude API request failed`,
          details: typeof response === 'object' ? response : { response },
        };
      }
    } catch (error) {
      this.pushStatusCode(400);
      outputObject[outputContentName] = {
        error: true,
        message: `Unable to reach Claude API: ${error.message}`,
      };
    }
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }
}
