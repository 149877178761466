import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import {
  Box,
  Container as MUIContainer,
  Drawer,
  Typography,
} from '@mui/material';
import { Editor } from '@craftjs/core';
import { Container } from './Container';
import { Text } from './Text';
import PPGraph from '../../classes/GraphClass';
import { useIsSmallScreen } from '../../utils/utils';
import { DRAWER_CONSTANTS } from '../../utils/constants';
import { DrawerSide, IOverlay, TRgba } from '../../utils/interfaces';
import * as styles from '../../utils/style.module.css';
import { DashboardEditor, EmptyState } from './DashboardEditor';
import { RenderNode } from './RenderNode';
import { DynamicWidget } from './DynamicWidget';
import { DashboardContainer } from './DashboardContainer';
import { ActionHandler } from '../../classes/Action';

type GraphOverlayDashboardProps = {
  randomMainColor: string;
  overlayState: IOverlay;
  updateOverlayState: (newState: Partial<IOverlay>) => void;
  isEditMode: boolean;
  toggle: boolean;
  setDrawerWidthPercentage: (width: number) => void;
  drawerWidthPercentage: number;
};

const GraphOverlayDashboard: React.FunctionComponent<
  GraphOverlayDashboardProps
> = ({
  randomMainColor,
  overlayState,
  updateOverlayState,
  isEditMode,
  toggle,
  setDrawerWidthPercentage,
  drawerWidthPercentage,
}) => {
  const [dashboardLeft, setDashboardLeft] = useState(
    overlayState.leftSide.visible ? overlayState.leftSide.width : 0,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const drawerRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number>();

  const smallScreen = useIsSmallScreen();

  const resizeHandler = useRef({
    isResizing: false,
    startWidth: 0,
    startX: 0,
  });

  useEffect(() => {
    if (toggle) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [toggle]);

  useEffect(() => {
    setDashboardLeft(
      overlayState[DrawerSide.LEFT].visible
        ? overlayState[DrawerSide.LEFT].width
        : 0,
    );
  }, [
    overlayState[DrawerSide.LEFT].visible,
    overlayState[DrawerSide.LEFT].width,
    overlayState[DrawerSide.DASHBOARD].visible,
  ]);

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      // Cancel previous animation frame if it exists
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Request new animation frame
      animationFrameRef.current = requestAnimationFrame(() => {
        const delta = e.clientX - resizeHandler.current.startX;

        const newWidth = Math.min(
          Math.max(
            resizeHandler.current.startWidth + delta,
            DRAWER_CONSTANTS.MIN_DRAWER_WIDTH,
          ),
          DRAWER_CONSTANTS.MAX_DRAWER_WIDTH,
        );

        const newWidthPercentage = widthToPercentage(newWidth, dashboardLeft);
        const truncatedWidthPercentage = Math.floor(newWidthPercentage);
        setDrawerWidthPercentage(truncatedWidthPercentage);
        updateOverlayState({
          dashboard: {
            ...overlayState.dashboard,
            fullscreen: false,
            visible: true,
            widthPercentage: truncatedWidthPercentage,
          },
        });
      });
    },
    [setDrawerWidthPercentage, dashboardLeft],
  );

  const handlePointerUp = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      resizeHandler.current.isResizing = false;
      setIsResizing(false);

      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, setDrawerWidthPercentage, drawerWidthPercentage],
  );

  const handleMouseDown = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();
      e.stopPropagation();

      resizeHandler.current = {
        isResizing: true,
        startWidth: percentageToWidth(drawerWidthPercentage, dashboardLeft),
        startX: e.clientX,
      };

      setIsResizing(true);

      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, handlePointerUp, drawerWidthPercentage, dashboardLeft],
  );

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('pointercancel', handlePointerUp);
    };
  }, [handlePointerMove, handlePointerUp]);

  const handleNodesChange = useCallback(
    debounce((query) => {
      ActionHandler.setUnsavedChange(true);
      if (PPGraph.currentGraph.allowSelfExecution) {
        const serializedLayout = query.serialize();
        PPGraph.currentGraph.layouts = {
          default: serializedLayout,
        };
      }
    }, 100),
    [],
  );

  useEffect(() => {
    return () => {
      handleNodesChange.cancel();
    };
  }, [handleNodesChange]);

  return (
    <Box
      sx={{
        position: 'absolute',
        marginLeft: `${dashboardLeft}px`,
        transition: 'margin-left 0.225s ease-in-out',
      }}
    >
      <Drawer
        anchor={'left'}
        variant="persistent"
        hideBackdrop={true}
        open={isOpen}
        ModalProps={{
          keepMounted: true,
        }}
        transitionDuration={{
          enter: 225,
          exit: 225,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            zIndex: 5,
            width: `${smallScreen || overlayState[DrawerSide.DASHBOARD].fullscreen ? '100' : drawerWidthPercentage}%`,
            border: 0,
            background: TRgba.fromString(randomMainColor)
              .darken(0.85)
              .toString(),
            height: '100vh !important',
            boxShadow: '0 0 24px rgba(0, 0, 0, 0.5)',
            left: 'unset',
          },
          ref: drawerRef,
        }}
      >
        <div
          onPointerDown={handleMouseDown}
          className={styles.draggerLeft}
          style={{ cursor: isResizing ? 'col-resize' : undefined }}
        />
        <Editor
          resolver={{
            Box,
            Text,
            DynamicWidget,
            Container,
            DashboardContainer,
            Typography,
            EmptyState,
          }}
          enabled={isEditMode}
          onNodesChange={handleNodesChange}
          onRender={RenderNode}
        >
          <DashboardEditor
            isVisible={overlayState.dashboard.visible}
            isEditMode={isEditMode}
            randomMainColor={randomMainColor}
            overlayState={overlayState}
            updateOverlayState={updateOverlayState}
          />
        </Editor>
      </Drawer>
    </Box>
  );
};

export default GraphOverlayDashboard;

function widthToPercentage(newWidth: number, leftSideWidth: number) {
  return Math.trunc((newWidth / (window.innerWidth - leftSideWidth)) * 100);
}

function percentageToWidth(percentage: number, leftSideWidth: number) {
  console.log(percentage, window.innerWidth, leftSideWidth);
  return Math.trunc((percentage / 100) * (window.innerWidth - leftSideWidth));
}
