import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  WidgetHybridBase,
  WidgetPaper,
  colorOptions,
  labelName,
  outName,
  outIndexName,
  initialValueName,
  variantName,
  sizeName,
  offValueName,
  onValueName,
  colorName,
  defaultOptions,
} from './abstract';
import Socket from '../../classes/SocketClass';
import { AnyType } from '../datatypes/anyType';
import { ArrayType } from '../datatypes/arrayType';
import { NumberType } from '../datatypes/numberType';
import { StringType } from '../datatypes/stringType';
import { EnumType, EnumStructure } from '../datatypes/enumType';
import { BooleanType } from '../datatypes/booleanType';
import { BackPropagation } from '../../interfaces';
import {
  DEFAULT_UPDATE_FREQUENCY,
  SOCKET_TYPE,
  customTheme,
} from '../../utils/constants';
import {
  ActionHandler,
  BakedAction,
  SerializableAction,
  SerializableActionHandler,
} from '../../classes/Action';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';

const disabledName = 'Disabled';

const variantOptions: EnumStructure = [
  {
    text: 'contained',
  },
  {
    text: 'outlined',
  },
  {
    text: 'text',
  },
];

export class WidgetButton extends WidgetHybridBase {
  public getName(): string {
    return 'Button';
  }

  public getDescription(): string {
    return 'Adds a button to trigger values';
  }

  public getVersion(): number {
    return 2;
  }

  public async migrate(previousVersion: number): Promise<void> {
    await super.migrate(previousVersion);
    if (previousVersion < 2) {
      this.updateBehaviour = new UpdateBehaviourClass(
        false,
        true,
        false,
        DEFAULT_UPDATE_FREQUENCY,
        this,
      );
      await this.updateBehaviour.onNodeAdded();
    }
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, offValueName, new AnyType(), 0, false),
      new Socket(SOCKET_TYPE.IN, onValueName, new AnyType(), 1, false),
      new Socket(SOCKET_TYPE.IN, labelName, new StringType(), 'Button', false),
      new Socket(
        SOCKET_TYPE.IN,
        variantName,
        new EnumType(variantOptions, undefined, true),
        variantOptions[0].text,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        colorName,
        new EnumType(colorOptions, undefined, true),
        colorOptions[0].text,
        false,
      ),
      new Socket(SOCKET_TYPE.IN, disabledName, new BooleanType(), false, false),
      new Socket(SOCKET_TYPE.OUT, outName, new AnyType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(onValueName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    // No default execution behavior needed - button is event driven
  }

  handleOnClick = async (isDown: boolean) => {
    const id = this.id;
    const inputData = isDown
      ? this.getInputData(onValueName)
      : this.getInputData(offValueName);

    const applyFunction = async (value) => {
      const safeNode = SerializableActionHandler.getSafeNode(id);
      await safeNode.getOutputSocketByName(outName).setDataAndWait(value);
      await safeNode.executeOptimizedChain();
    };

    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          isDown ? 'Button Press' : 'Button Release',
        ),
        inputData,
        inputData,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;
    const isDisabled = props.disabled || props[disabledName];

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <Button
            variant={props[variantName]}
            color={props[colorName]}
            size={props[sizeName]}
            disabled={isDisabled}
            sx={{
              margin: 'auto',
              lineHeight: props.inDashboard
                ? '36px'
                : `${node.nodeHeight / 5}px`,
              width: '100%',
              height: '100%',
              pointerEvents: 'auto',
              py: 1.5,
            }}
            onMouseDown={() => node.handleOnClick(true)}
            onMouseUp={() => node.handleOnClick(false)}
          >
            <Typography
              sx={{
                fontSize: props.inDashboard
                  ? '16px'
                  : `${node.nodeHeight / 6}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
                textTransform: 'none',
              }}
            >
              {props[labelName]}
            </Typography>
          </Button>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}

// Add these constants at the top with your other socket names
const buttonGroupOptionsName = 'Button Options';
const selectedButtonIndex = 'Selected Button';
const isToggleGroupName = 'Toggle Mode';
const orientationName = 'Vertical';

export class WidgetButtonGroup extends WidgetHybridBase {
  public getName(): string {
    return 'Button Group';
  }

  public getDescription(): string {
    return 'Adds a group of buttons that can also function as toggle buttons';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        buttonGroupOptionsName,
        new ArrayType(),
        defaultOptions,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        isToggleGroupName,
        new BooleanType(),
        true,
        false,
      ),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        selectedButtonIndex,
        new NumberType(true, 0, 10),
        0,
        () => this.getInputData(isToggleGroupName),
      ),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        variantName,
        new EnumType(variantOptions, undefined, true),
        variantOptions[0].text,
        () => !this.getInputData(isToggleGroupName),
      ),
      new Socket(
        SOCKET_TYPE.IN,
        colorName,
        new EnumType(colorOptions, undefined, true),
        colorOptions[0].text,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        orientationName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new StringType(), undefined, false),
      new Socket(SOCKET_TYPE.OUT, outIndexName, new NumberType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 340;
  }

  public getDefaultNodeHeight(): number {
    return 90;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedButtonIndex),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);

    const options = inputObject[buttonGroupOptionsName];
    const selectedIndex = Math.max(
      0,
      Math.min(options.length - 1, inputObject[selectedButtonIndex]),
    );

    const selectedValue = options[selectedIndex];
    this.setOutputData(outName, selectedValue);
    this.setOutputData(outIndexName, selectedIndex);
  }

  handleButtonClick = async (index) => {
    const id = this.id;
    const prev = this.getInputData(selectedButtonIndex);

    // If we're in regular button mode (not toggle), or we're in exclusive toggle mode
    // simply set the selected index to the clicked button
    // If we're in non-exclusive toggle mode, we'd need state to track multiple selections
    // but since we're limited to a single output, we'll still use the last clicked

    const applyFunction = async (newIndex) => {
      const safeNode = SerializableActionHandler.getSafeNode(id);
      safeNode.setInputData(selectedButtonIndex, newIndex);
      const options = safeNode.getInputData(buttonGroupOptionsName);
      const selectedOption = options[newIndex];
      safeNode.setOutputData(outName, selectedOption);
      safeNode.setOutputData(outIndexName, newIndex);
      await safeNode.executeOptimizedChain();
    };

    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(applyFunction, applyFunction, 'Select Button'),
        index,
        prev,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;
    const options = props[buttonGroupOptionsName] || [];
    const selectedIndex = Math.max(
      0,
      Math.min(options.length - 1, props[selectedButtonIndex]),
    );

    // Get configuration props
    const isToggleMode = props[isToggleGroupName];
    const variant = props[variantName];
    const color = props[colorName];
    const isVertical = props[orientationName];

    // Calculate dynamic font sizes based on node dimensions
    const fontSize = props.inDashboard
      ? '14px'
      : `${Math.max(12, node.nodeHeight / 12)}px`;

    const headerFontSize = props.inDashboard
      ? '16px'
      : `${Math.max(14, node.nodeHeight / 10)}px`;

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                flex: 1,
              }}
            >
              {isToggleMode ? (
                // Toggle Button Group
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: isVertical ? 'column' : 'row',
                    justifyContent: 'center',
                    pointerEvents: 'auto',
                  }}
                >
                  {options.map((option, index) => {
                    const isFirst = index === 0;
                    const isLast = index === options.length - 1;

                    return (
                      <Button
                        key={index}
                        variant={
                          selectedIndex === index ? 'contained' : 'outlined'
                        }
                        color={color}
                        onClick={() => node.handleButtonClick(index)}
                        disabled={props.disabled}
                        sx={{
                          fontSize: fontSize,
                          flex: isVertical ? '0 0 auto' : '1 1 0',
                          minWidth: isVertical ? '90%' : '30%',
                          ...(selectedIndex === index && {
                            boxShadow: 2,
                          }),
                          py: 1.5,
                          textTransform: 'none',
                          borderRadius: 0,
                          ...(isVertical
                            ? {
                                ...(isFirst && {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                }),
                                ...(isLast && {
                                  borderBottomLeftRadius: 4,
                                  borderBottomRightRadius: 4,
                                }),
                              }
                            : {
                                ...(isFirst && {
                                  borderTopLeftRadius: 4,
                                  borderBottomLeftRadius: 4,
                                }),
                                ...(isLast && {
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                }),
                              }),
                          m: 0,
                          ...(isFirst
                            ? {}
                            : isVertical
                              ? { mt: '-1px' }
                              : { ml: '-1px' }),
                          ...(selectedIndex === index && {
                            position: 'relative',
                            zIndex: 1,
                          }),
                        }}
                      >
                        {option}
                      </Button>
                    );
                  })}
                </Box>
              ) : (
                // Regular Button Group
                <ButtonGroup
                  variant={variant}
                  color={color}
                  orientation={isVertical ? 'vertical' : 'horizontal'}
                  sx={{
                    justifyContent: 'center',
                    pointerEvents: 'auto',
                    width: '100%',
                    '& .MuiButtonGroup-grouped': {
                      flex: 1,
                    },
                  }}
                  disabled={props.disabled}
                >
                  {options.map((option, index) => (
                    <Button
                      key={index}
                      onClick={() => node.handleButtonClick(index)}
                      sx={{
                        fontSize: fontSize,
                        py: 1.5,
                        textTransform: 'none',
                      }}
                    >
                      {option}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}

const switchDefaultData = false;
const switchDefaultName = 'Switch';

export class WidgetSwitch extends WidgetHybridBase {
  public getName(): string {
    return 'Switch';
  }

  public getDescription(): string {
    return 'Adds a switch to toggle between values';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        initialValueName,
        new BooleanType(),
        switchDefaultData,
        false,
      ),
      new Socket(SOCKET_TYPE.IN, offValueName, new BooleanType(), false, false),
      new Socket(SOCKET_TYPE.IN, onValueName, new BooleanType(), true, false),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        switchDefaultName,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new BooleanType()),
    ];
  }

  public getVersion(): number {
    return 2;
  }

  public async migrate(previousVersion: number): Promise<void> {
    if (previousVersion < 2 && this.getInputSocketByName('Initial Selection')) {
      await this.replaceSocketWithOtherSocket(
        this.getInputSocketByName('Initial Selection'),
        this.getInputSocketByName(initialValueName),
      );
      // sockets got renamed
    }
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(initialValueName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    const onValue = inputObject[onValueName];
    const offValue = inputObject[offValueName];
    const newValue = inputObject[initialValueName] ? onValue : offValue;
    this.setInputData(initialValueName, newValue);
    this.setOutputData(outName, newValue);
  }

  handleOnChange = async (value) => {
    const id = this.id;
    const prev = this.getInputData(initialValueName);
    const applyFunction = async (newValue) => {
      const safeNode = SerializableActionHandler.getSafeNode(
        id,
      ) as WidgetSwitch;
      const onValue = safeNode.getInputData(onValueName);
      const offValue = safeNode.getInputData(offValueName);
      safeNode.setInputData(initialValueName, value);
      safeNode.setOutputData(outName, newValue ? onValue : offValue);
      await safeNode.executeOptimizedChain();
    };
    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(applyFunction, applyFunction, 'Toggle Switch'),
        value,
        prev,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      node.handleOnChange(checked);
    };

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <FormControl component="fieldset" sx={{ margin: 'auto' }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                minWidth: 'min-content',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Switch
                disabled={props.disabled}
                size="medium"
                checked={props[initialValueName]}
                color="primary"
                onChange={handleChange}
                sx={{
                  transform: props.inDashboard
                    ? 'scale(1.4)'
                    : `scale(${node.nodeHeight / 60})`,
                  pointerEvents: 'auto',
                }}
              />
              <Typography
                sx={{
                  mt: props.inDashboard ? '0' : `${node.nodeHeight / 24}px`,
                  fontSize: props.inDashboard
                    ? '16px'
                    : `${node.nodeHeight / 6}px`,
                }}
              >
                {props[labelName]}
              </Typography>
            </Stack>
          </FormControl>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}
