import React, { useEffect } from 'react';
import { useEditor } from '@craftjs/core';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { DynamicWidgetName } from './DynamicWidget';
import { getLayoutableElement } from '../../utils/utils';
import { ActionButton } from './RenderNode';
import { emptyLayout } from './DashboardEditor';

export const SettingsPanel = () => {
  const { actions, query, selected, isEnabled, isRoot } = useEditor(
    (state, query) => {
      let [currentNodeId] = state.events.selected || [];
      currentNodeId = currentNodeId === undefined ? 'ROOT' : currentNodeId;
      const isRoot = currentNodeId === 'ROOT';
      let selected = null;

      if (currentNodeId) {
        const currentNode = state.nodes[currentNodeId];
        if (currentNode) {
          selected = {
            id: currentNodeId,
            name: currentNode.data.name || '',
            displayName:
              currentNode.data.custom.displayName ||
              currentNode.data.displayName,
            settings: currentNode.related?.settings,
            isDeletable: query.node(currentNodeId).isDeletable(),
            parent: currentNode.data.parent,
          };
        }
      }

      return {
        selected,
        isEnabled: state.options.enabled,
        isRoot,
      };
    },
  );

  useEffect(() => {}, [selected]);

  return isEnabled && selected ? (
    <Stack direction="column" spacing={0}>
      <Box data-cy="settings-panel">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          data-cy="settings-panel-header"
        >
          <ActionButton
            size="small"
            sx={{ p: 1, transform: 'rotate(90deg)' }}
            onClick={() => actions.selectNode(selected.parent)}
            title="Select parent"
            disabled={!selected.parent}
            data-cy="select-parent-btn"
          >
            <SubdirectoryArrowLeftIcon sx={{ fontSize: '16px' }} />
          </ActionButton>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ flex: 1, pl: 0.5 }}
            title={selected.id}
            data-cy="selected-node-name"
          >
            {selected.displayName}
            {selected.name === DynamicWidgetName
              ? ` - ${getLayoutableElement(query.getNodes()[selected.id].data.props.id)?.getDashboardName()}`
              : ''}
          </Typography>
          {isRoot && (
            <Tooltip title="Delete all">
              <IconButton
                size="small"
                onClick={() => {
                  actions.deserialize(JSON.stringify(emptyLayout));
                  const rootNode = query.node('ROOT').get();
                  const rootChildren = rootNode.data.nodes || [];
                  rootChildren.forEach((childId) => actions.delete(childId));
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {selected.isDeletable && (
            <Tooltip title="Delete widget">
              <IconButton
                size="small"
                onClick={() => {
                  actions.delete(selected.id);
                }}
                data-cy="settings-delete-widget-btn"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <div data-cy="settings-panel">
        {selected.settings && React.createElement(selected.settings)}
      </div>
    </Stack>
  ) : null;
};
