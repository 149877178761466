/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { SelectWidget } from '../../widgets';
import {
  AbstractType,
  Compatibility,
  CompatibilityType,
  DataTypeProps,
  dataTypeWidgetDefaultProps,
} from './abstractType';
import { EnumStructure, EnumTypeProps } from './enumType';
import { AnyType } from './anyType';

export class DynamicEnumType extends AbstractType {
  getOptions = () => [];
  onChange = () => {};
  showAsButtons: boolean;

  constructor(getOptions, onChange, showAsButtons = false) {
    super();
    this.getOptions = getOptions;
    this.onChange = onChange;
    this.showAsButtons = showAsButtons;
  }
  getName(): string {
    return 'Dynamic Enum';
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return new Compatibility(
      typeof data == 'string'
        ? CompatibilityType.Compatible
        : CompatibilityType.Incompatible,
    );
  }

  getDefaultValue(): any {
    return '';
  }

  getInputWidget = (props: EnumTypeProps): any => {
    props.dataType = this;
    props.getOptions = this.getOptions;
    props.onChange = this.onChange;
    return <SelectWidget {...props} />;
  };

  getDefaultWidgetProps() {
    return {
      ...dataTypeWidgetDefaultProps,
      minHeight: '32px',
    };
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['WidgetDropdown'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'CONSTANT_String'];
  }

  configureOnLoad(): boolean {
    return false;
  }
}
