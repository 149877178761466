import { styled } from '@mui/material';

export const MDXContentStyled = styled('div')(({ theme }) => ({
  // Headings
  '& h1': {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  '& h2': {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: theme.typography.h4.lineHeight,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  '& h3': {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: theme.typography.h5.lineHeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2.5),
    color: theme.palette.text.primary,
  },
  '& h4': {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    lineHeight: theme.typography.h6.lineHeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  '& h5': {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 600,
    lineHeight: theme.typography.subtitle1.lineHeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  '& h6': {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: 600,
    lineHeight: theme.typography.subtitle2.lineHeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },

  // Paragraphs and basic text
  '& p': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  '& strong, & b': {
    fontWeight: 600,
  },
  '& em, & i': {
    fontStyle: 'italic',
  },

  // Links
  '& a': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.secondary.dark,
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.secondary.light}`,
      outlineOffset: '2px',
    },
  },

  // Lists
  '& ul, & ol': {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  '& ul': {
    listStyleType: 'disc',
  },
  '& ol': {
    listStyleType: 'decimal',
  },
  '& li': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  '& li > p': {
    marginBottom: theme.spacing(1),
  },
  '& li > ul, & li > ol': {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  // Blockquotes
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
    color: theme.palette.text.secondary,
    '& p': {
      marginBottom: theme.spacing(1),
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },

  // Code
  '& code': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    fontSize: '0.9em',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.light
        : theme.palette.secondary.dark,
  },
  '& pre': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    fontSize: '0.9em',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    '& code': {
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: 0,
    },
  },

  // Images
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },

  // Tables
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(2),
  },
  '& th': {
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    textAlign: 'left',
    fontWeight: 600,
  },
  '& td': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  '& tr:last-child td': {
    borderBottom: 'none',
  },

  // Horizontal rule
  '& hr': {
    border: 'none',
    height: '1px',
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(3, 0),
  },

  // Definition lists
  '& dl': {
    marginBottom: theme.spacing(2),
  },
  '& dt': {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  '& dd': {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  // Footnotes
  '& .footnotes': {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(4),
  },

  // Inline elements
  '& mark': {
    backgroundColor: theme.palette.warning.light,
    padding: '0 3px',
    borderRadius: '2px',
  },
  '& abbr': {
    textDecoration: 'underline dotted',
    cursor: 'help',
  },
  '& sub, & sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },
  '& sub': {
    bottom: '-0.25em',
  },
  '& sup': {
    top: '-0.5em',
  },

  // Details/Summary
  '& details': {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  '& summary': {
    cursor: 'pointer',
    fontWeight: 500,
    padding: theme.spacing(0.5, 0),
    '&:focus': {
      outline: 'none',
    },
  },

  // Margin adjustments for adjacent elements
  '& h1 + p, & h2 + p, & h3 + p, & h4 + p, & h5 + p, & h6 + p': {
    marginTop: theme.spacing(1),
  },

  // Special case for the last child to remove excess margin
  '& > *:last-child': {
    marginBottom: 0,
  },
}));
