import { Analytics } from 'firebase/analytics';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  getIdToken,
  sendEmailVerification,
} from 'firebase/auth';
import { getPerformance, trace } from 'firebase/performance';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { UserData } from '../../src-server/userService';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAp1onINhf_L8g6zW9_poghiAccxjDdwRQ',
  authDomain: 'plugandplayground.firebaseapp.com',
  databaseURL:
    'https://plugandplayground-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'plugandplayground',
  storageBucket: 'plugandplayground.firebasestorage.app',
  messagingSenderId: '451137850222',
  appId: '1:451137850222:web:37b679ab74eb174c7b0f7f',
  measurementId: 'G-Z3BTJYQ689',
};

const firebaseConfigStaging = {
  apiKey: 'AIzaSyAj0LTfKjQxZYKPYGzIGBLCdPdiv12m_IU',
  authDomain: 'pnp-staging-ad054.firebaseapp.com',
  projectId: 'pnp-staging-ad054',
  storageBucket: 'pnp-staging-ad054.firebasestorage.app',
  messagingSenderId: '430729726785',
  appId: '1:430729726785:web:990215abe6e4ac4ca1efc0',
  measurementId: 'G-5EH5DR1LDS',
};

const API_BASE_URL = window.location.origin + '/auth'; // Uses current domain

// Get the appropriate config based on environment variables
const getFirebaseConfig = () => {
  return process.env.NODE_ENV === 'development' ||
    process.env.USE_STAGING_CONFIG === 'true'
    ? firebaseConfigStaging
    : firebaseConfig;
};

// Don't initialize Firebase here, we'll do it in the constructor
// to avoid duplicate initializations

export class FirebaseAppHandler {
  private static handler: FirebaseAppHandler = undefined;
  analytics: Analytics = undefined;
  auth: Auth = undefined;
  performance: any = undefined;
  // comes from firebase directly
  currentUser: User | null = null;
  // comes from database
  currentUserData: UserData | null = null;

  private constructor() {
    // Initialize Firebase with the appropriate config
    const app = initializeApp(getFirebaseConfig());
    this.analytics = getAnalytics(app);
    this.auth = getAuth(app);

    // Only initialize performance monitoring in production
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.USE_STAGING_CONFIG !== 'true'
    ) {
      this.performance = getPerformance(app);
    }

    //connectAuthEmulator(this.auth, 'http://localhost:9099');

    // Setup auth state listener
    onAuthStateChanged(this.auth, async (user) => {
      this.currentUser = user;
      this.refreshCurrentUserData();
      InterfaceController.notifyListeners(
        ListenEvent.UserIsLoggedIn,
        user !== null,
      );
      InterfaceController.notifyListeners(
        ListenEvent.UserHasProAccessChanged,
        this.getHasProAccess(),
      );
    });
  }

  public static getInstance() {
    if (this.handler == undefined) {
      this.handler = new FirebaseAppHandler();
    }
    return this.handler;
  }

  // Authentication methods
  async signUpWithEmail(email: string, password: string): Promise<User> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password,
      );

      // Send verification email
      await sendEmailVerification(userCredential.user);

      // Show message to verify email
      InterfaceController.showSnackBar(
        'A verification email has been sent. Please verify your email before logging in.',
      );

      // Sign out - user must verify email before they can use the app
      await this.signOutUser();

      return userCredential.user;
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  }

  async signInWithEmail(email: string, password: string): Promise<User> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );

      // Check if email is verified
      if (!userCredential.user.emailVerified) {
        // Show message that verification is required
        InterfaceController.showSnackBar(
          'Email verification required. Please check your inbox and verify your email.',
        );

        // Resend verification email
        await sendEmailVerification(userCredential.user);

        // Sign out - user must verify email before they can use the app
        await this.signOutUser();

        throw new Error(
          'Email not verified. A new verification email has been sent.',
        );
      }

      return userCredential.user;
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async signInWithGoogle(): Promise<User> {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(this.auth, provider);

      return userCredential.user;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  }

  async signOutUser(): Promise<void> {
    try {
      await signOut(this.auth);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  }

  private async getUserData() {
    try {
      if (!this.currentUser) {
        return null;
      }

      const response = await this.authenticatedFetch(
        `${API_BASE_URL}/user-data`,
      );

      if (response.status === 401) {
        console.log('User not authenticated. Returning null from getUserData');
        return null;
      }

      if (!response.ok) {
        const errorData = await response
          .json()
          .catch(() => ({ error: 'Unknown error' }));
        throw new Error(errorData.error || 'Failed to fetch user data');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null; // Return null instead of throwing to handle errors gracefully
    }
  }

  // TODO: check against database
  getHasProAccess(): boolean {
    return this.getIsLoggedIn();
  }

  getIsLoggedIn(): boolean {
    return this.getCurrentUser() !== null;
  }

  getCurrentUser(): User | null {
    return this.currentUser;
  }
  async refreshCurrentUserData(): Promise<UserData | null> {
    this.currentUserData = await this.getUserData();
    return this.currentUserData;
  }

  getCurrentUserData(): UserData | null {
    return this.currentUserData;
  }

  /**
   * Get the current user's authentication token for backend requests
   */
  async getAuthToken(): Promise<string | null> {
    const user = this.currentUser;
    if (!user) return null;

    try {
      return await getIdToken(user, true); // true forces token refresh
    } catch (error) {
      console.error('Error getting auth token:', error);
      return null;
    }
  }

  /**
   * Create an authorization header with the Firebase token
   */
  async getAuthHeader(): Promise<Record<string, string>> {
    const token = await this.getAuthToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  /**
   * Create a performance trace to measure a specific operation
   * @param traceName The name of the trace
   * @returns A Trace object that can be started and stopped
   */
  createPerformanceTrace(traceName: string): any {
    // If performance monitoring is not initialized, return a dummy trace object
    if (!this.performance) {
      return {
        start: () => {},
        stop: () => {},
        putAttribute: () => {},
      };
    }
    return trace(this.performance, traceName);
  }

  /**
   * Make an authenticated API request to your backend with performance tracing
   */
  async authenticatedFetch(
    url: string,
    options: RequestInit = {},
  ): Promise<Response> {
    const headers = await this.getAuthHeader();
    const fetchTrace = this.createPerformanceTrace(
      `fetch_${url.split('/').pop()}`,
    );

    fetchTrace.start();
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          ...headers,
          'Content-Type': 'application/json',
        },
      });

      fetchTrace.putAttribute('status', response.status.toString());
      fetchTrace.stop();
      return response;
    } catch (error) {
      fetchTrace.putAttribute('error', 'true');
      fetchTrace.stop();
      throw error;
    }
  }
}
