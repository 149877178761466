import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import PPNode from '../../classes/NodeClass';
import PPSocket from '../../classes/SocketClass';
import { EnumType } from '../datatypes/enumType';
import { StringType } from '../datatypes/stringType';
import { NumberType } from '../datatypes/numberType';
import { ColorType } from '../datatypes/colorType';
import { BooleanType } from '../datatypes/booleanType';
import {
  DashboardWidgetProps,
  IOverlay,
  Layoutable,
  WidgetMode,
  WidgetProps,
} from '../../utils/interfaces';
import { SOCKETNAME_BACKGROUNDCOLOR, SOCKET_TYPE } from '../../utils/constants';
import {
  SOCKET_NAME_DASHBOARD_CONTENT,
  directionName,
  widthModeName,
  widthName,
  heightModeName,
  heightName,
  getDimension,
} from '../../utils/layoutableHelpers';
import { useIsSmallScreen } from '../../utils/utils';
import { ArrayType } from '../datatypes/arrayType';
import { LayoutableNodeBase } from './dynamicLayoutBase';

type VisibilityMode = 'visible' | 'hidden';
type CollapseMode = 'collapse' | 'expand' | 'none';

type DashboardContainerBaseProps = {
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  color: Record<'r' | 'g' | 'b' | 'a', number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  height: string;
  padding: number[];
  minWidth: string;
  minHeight: string;
  widthMode: WidgetMode;
  heightMode: WidgetMode;
  gap: number;
  children?: React.ReactNode;
  visibility: VisibilityMode;
  collapseMode: CollapseMode;
};

const defaultProps: DashboardContainerBaseProps = {
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  padding: [8, 8, 8, 8],
  background: { r: 127, g: 225, b: 88, a: 0.1 },
  color: { r: 51, g: 51, b: 51, a: 1 },
  width: '100%',
  height: 'auto',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'hug',
  gap: 8,
  visibility: 'visible',
  collapseMode: 'none',
};

const VISIBILITY_OPTIONS = ['visible', 'hidden'];

const COLLAPSE_OPTIONS = [
  { text: 'No Control', value: 'none' },
  { text: 'Collapsed', value: 'collapse' },
  { text: 'Expanded', value: 'expand' },
];

const DIRECTION_OPTIONS = [
  { text: 'Vertical', value: 'column' },
  { text: 'Horizontal', value: 'row' },
];
const MODE_OPTIONS = [
  { text: 'Hug', value: 'hug' },
  { text: 'Fill', value: 'fill' },
  { text: 'Fixed', value: 'fixed' },
];
const HORIZONTAL_ALIGNMENT_OPTIONS = [
  { text: 'Left', value: 'flex-start' },
  { text: 'Center', value: 'center' },
  { text: 'Right', value: 'flex-end' },
  { text: 'Space between', value: 'space-between' },
];
const VERTICAL_ALIGNMENT_OPTIONS = [
  { text: 'Top', value: 'flex-start' },
  { text: 'Center', value: 'center' },
  { text: 'Bottom', value: 'flex-end' },
  { text: 'Space between', value: 'space-between' },
];

const verticalAlignmentName = 'Vertical alignment';
const horizontalAlignmentName = 'Horizontal alignment';
const gapName = 'Gap';
const visibilityName = 'Visible';
const collapseName = 'Collapse Mode';
const paddingTopName = 'PaddingTop';
const paddingRightName = 'PaddingRight';
const paddingBottomName = 'PaddingBottom';
const paddingLeftName = 'PaddingLeft';

export class DashboardContainerNode
  extends LayoutableNodeBase
  implements Layoutable
{
  listenID;

  public getName(): string {
    return 'Dashboard container';
  }

  public getDescription(): string {
    return 'Controls properties and content of a dynamic dashboard container widget';
  }

  public onNodeAdded = async (source): Promise<void> => {
    await super.onNodeAdded(source);
    this.listenID = InterfaceController.addListener(
      ListenEvent.DashboardItemAdded,
      (data: any) => {
        if (data.dashboardId === this.getDashboardId()) {
          setTimeout(() => {
            this.executeOptimizedChain();
          }, 100);
        }
      },
    );
  };

  async onRemoved(): Promise<void> {
    await super.onRemoved();
    InterfaceController.removeListener(this.listenID);
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.IN,
        visibilityName,
        new BooleanType(),
        true,
        true,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        collapseName,
        new EnumType(COLLAPSE_OPTIONS, undefined, true),
        COLLAPSE_OPTIONS[0].text,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        directionName,
        new EnumType(DIRECTION_OPTIONS, undefined, true),
        getEnumText(DIRECTION_OPTIONS, defaultProps.flexDirection),
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        widthModeName,
        new EnumType(MODE_OPTIONS, undefined, true),
        getEnumText(MODE_OPTIONS, defaultProps.widthMode),
        false,
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        widthName,
        new StringType(),
        defaultProps.width,
        () => this.getInputData(widthModeName) === 'Fixed',
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        heightModeName,
        new EnumType(MODE_OPTIONS, undefined, true),
        getEnumText(MODE_OPTIONS, defaultProps.heightMode),
        false,
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        heightName,
        new StringType(),
        defaultProps.height,
        () => this.getInputData(heightModeName) === 'Fixed',
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        verticalAlignmentName,
        new EnumType(VERTICAL_ALIGNMENT_OPTIONS, undefined, true),
        getEnumText(VERTICAL_ALIGNMENT_OPTIONS, defaultProps.justifyContent),
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        horizontalAlignmentName,
        new EnumType(HORIZONTAL_ALIGNMENT_OPTIONS, undefined, true),
        getEnumText(HORIZONTAL_ALIGNMENT_OPTIONS, defaultProps.alignItems),
        false,
      ),
      new PPSocket(SOCKET_TYPE.IN, gapName, new NumberType(true), 8, false),
      new PPSocket(
        SOCKET_TYPE.IN,
        paddingTopName,
        new NumberType(true),
        defaultProps.padding[0],
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        paddingRightName,
        new NumberType(true),
        defaultProps.padding[1],
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        paddingBottomName,
        new NumberType(true),
        defaultProps.padding[2],
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        paddingLeftName,
        new NumberType(true),
        defaultProps.padding[3],
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        defaultProps.background,
        false,
      ),
    ];
  }

  getWidgetProps(): WidgetProps {
    return { ...defaultProps };
  }

  getWidgetContent(props: any): React.ReactElement {
    return <MuiComponent {...props} />;
  }

  isContainer?(): boolean {
    return true;
  }

  protected async onExecute(input: any, output: any): Promise<void> {
    InterfaceController.notifyListeners(ListenEvent.DashboardContainerChanged, {
      id: this.getDashboardId(),
      input: {
        flexDirection: getEnumValue(DIRECTION_OPTIONS, input[directionName]),
        width: input[widthName],
        widthMode: getEnumValue(MODE_OPTIONS, input[widthModeName]),
        height: input[heightName],
        heightMode: getEnumValue(MODE_OPTIONS, input[heightModeName]),
        backgroundColor: input[SOCKETNAME_BACKGROUNDCOLOR],
      },
    });

    const ReactUI = {
      renderFunction: (props) => {
        return this.getDashboardWrapper({
          ...props,
        });
      },
    };
    output[SOCKET_NAME_DASHBOARD_CONTENT] = ReactUI;
  }
}

const MuiComponent = (props): React.ReactElement => {
  const node = props.node;
  const nodeComponentId = `${node.id}-dashboard`;
  const [localCollapseMode, setLocalCollapseMode] = useState(
    getEnumValue(COLLAPSE_OPTIONS, props[collapseName]),
  );

  // Update local state when props change
  useEffect(() => {
    setLocalCollapseMode(getEnumValue(COLLAPSE_OPTIONS, props[collapseName]));
  }, [props[collapseName]]);

  const isSmallScreen = useIsSmallScreen();
  const direction = getEnumValue(DIRECTION_OPTIONS, props[directionName]);
  const widthFixed =
    getEnumValue(MODE_OPTIONS, props[widthModeName]) === 'fixed';
  const heightFixed =
    getEnumValue(MODE_OPTIONS, props[heightModeName]) === 'fixed';
  const isFixed = direction === 'row' ? widthFixed : heightFixed;
  const collapseMode = getEnumValue(COLLAPSE_OPTIONS, props[collapseName]);
  const visibility = props[visibilityName]
    ? VISIBILITY_OPTIONS[0]
    : VISIBILITY_OPTIONS[1];
  const verticalAlignment = getEnumValue(
    VERTICAL_ALIGNMENT_OPTIONS,
    props[verticalAlignmentName],
  );
  const horizontalAlignment = getEnumValue(
    HORIZONTAL_ALIGNMENT_OPTIONS,
    props[horizontalAlignmentName],
  );

  // Extract display logic into readable variables
  const showAccordionControl =
    visibility === 'visible' && collapseMode !== 'none';
  const shouldDisplay = props.isEditMode || visibility === 'visible';

  // Use useMemo for accordion styles with proper dependencies
  const accordionStyles = useMemo(
    () => ({
      boxShadow: 'none',
      background: 'transparent',
      width: '100%',
      '&:before': { display: 'none' },
      '& .MuiAccordionSummary-root': {
        display: showAccordionControl ? 'flex' : 'none',
        minHeight: 32,
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    }),
    [showAccordionControl],
  );

  const containerStyles = useMemo(
    () => ({
      justifyContent:
        direction === 'row' ? horizontalAlignment : verticalAlignment,
      flexDirection: isSmallScreen ? 'column' : direction,
      alignItems: direction === 'row' ? verticalAlignment : horizontalAlignment,
      gap: `${props[gapName]}px`,
      padding: `${props[paddingTopName]}px ${props[paddingRightName]}px ${props[paddingBottomName]}px ${props[paddingLeftName]}px`,
      display: shouldDisplay ? 'flex' : 'none',
      transition: 'height 0.3s ease-in-out',
      height: getDimension(
        'height',
        getEnumValue(MODE_OPTIONS, props[heightModeName]) as WidgetMode,
        props[heightName],
        isSmallScreen,
        true,
      ),
      overflow: isFixed ? 'auto' : 'unset',
    }),
    [
      direction,
      horizontalAlignment,
      verticalAlignment,
      isSmallScreen,
      props[gapName],
      props[paddingTopName],
      props[paddingRightName],
      props[paddingBottomName],
      props[paddingLeftName],
      shouldDisplay,
      props[heightModeName],
      props[heightName],
      isFixed,
    ],
  );

  // Accordion state change handler
  const handleAccordionChange = useCallback(() => {
    if (collapseMode === 'none') return;
    setLocalCollapseMode(
      localCollapseMode === 'collapse' ? 'expand' : 'collapse',
    );
  }, [collapseMode, localCollapseMode]);

  return (
    <Box
      id={nodeComponentId}
      sx={{
        position: 'relative',
      }}
    >
      <Accordion
        expanded={localCollapseMode !== 'collapse'}
        disabled={props.isEditMode}
        onChange={handleAccordionChange}
        disableGutters
        sx={accordionStyles}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} />
        <AccordionDetails>
          <Box
            data-cy={`container of ${node.getDashboardId()}`}
            sx={containerStyles}
          >
            {props.components}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

type EnumOption = {
  text: string;
  value: string;
};

const getEnumValue = (options: EnumOption[], text: string): string => {
  const option = options.find((opt) => opt.text === text);
  return option?.value ?? options[0].value;
};

const getEnumText = (options: EnumOption[], value: string): string => {
  const option = options.find((opt) => opt.value === value);
  return option?.text ?? options[0].text;
};

type DynamicWidgetContainerNodeProps = DashboardWidgetProps & {
  property: PPNode & Layoutable;
};

export const DynamicWidgetContainerNode: React.FunctionComponent<
  DynamicWidgetContainerNodeProps
> = (props) => {
  const [showDashboard, setShowDashboard] = useState(true);
  const [executionCount, setExecutionCount] = useState(0);
  // needs separate focus state to only trigger onBlurHandler if the dashboard one was focused
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const listenerId = InterfaceController.addListener(
      ListenEvent.OverlayStateChanged,
      (newState: IOverlay) => {
        setShowDashboard(newState.dashboard.visible);
      },
    );

    return () => InterfaceController.removeListener(listenerId);
  }, []);

  useEffect(() => {
    const executionListener = () => {
      setExecutionCount((prevCount) => prevCount + 1);
    };
    props.property.addExecutionListener(executionListener);

    return () => {
      props.property.removeExecutionListener(executionListener);
    };
  }, [props.property]);

  return (
    <Box
      id={`inspector-node-${props.property.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
        pointerEvents: showDashboard ? 'unset' : 'none',
      }}
    >
      <props.property.getWidgetContent
        {...PPNode.remapInput(props.property.inputSocketArray)}
        id={props.property.id}
        selected={props.property.selected}
        randomMainColor={props.randomMainColor}
        disabled={props.disabled}
        node={props.property}
        showDashboard={showDashboard}
        inDashboard={true}
        executionCount={executionCount}
        dataCyId={`${props.property.id}-dashboard`}
        widthMode={props.widthMode}
        width={props.width}
        heightMode={props.heightMode}
        height={props.height}
        isEditMode={props.isEditMode}
        components={props.components}
      />
    </Box>
  );
};

const inputHtmlArrayName = 'HtmlArray';
const numberPerColumnRowName = 'Number Per Column/Row';
const drawingOrderName = 'Change Column/Row drawing order';
const gapXName = 'Gap X';
const gapYName = 'Gap Y';
const objectsInteractive = 'Objects Interactive';
const outputMultiplierIndex = 'Output Multiplier Index';
const outputMultiplierPointerDown = 'Output Multiplier Pointer Down';

export class ReactUICombineArray
  extends LayoutableNodeBase
  implements Layoutable
{
  public getName(): string {
    return 'Dashboard grid array';
  }

  public getDescription(): string {
    return 'Combines an array of ReactUI elements into a grid layout';
  }

  // public getTags(): string[] {
  //   return ['HTML'].concat(super.getTags());
  // }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.IN,
        inputHtmlArrayName,
        new ArrayType(),
        [],
        true,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        numberPerColumnRowName,
        new NumberType(true, 1, 100),
        3,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        drawingOrderName,
        new BooleanType(),
        true,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        gapXName,
        new NumberType(true, 0, 100),
        8,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        gapYName,
        new NumberType(true, 0, 100),
        8,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        widthModeName,
        new EnumType(MODE_OPTIONS, undefined, true),
        getEnumText(MODE_OPTIONS, defaultProps.widthMode),
        false,
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        widthName,
        new StringType(),
        defaultProps.width,
        () => this.getInputData(widthModeName) === 'Fixed',
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        heightModeName,
        new EnumType(MODE_OPTIONS, undefined, true),
        getEnumText(MODE_OPTIONS, defaultProps.heightMode),
        false,
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        heightName,
        new StringType(),
        defaultProps.height,
        () => this.getInputData(heightModeName) === 'Fixed',
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        defaultProps.background,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        objectsInteractive,
        new BooleanType(),
        false,
        false,
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.OUT,
        outputMultiplierIndex,
        new NumberType(true),
        -1,
        () => this.getInputData(objectsInteractive),
      ),
      PPSocket.getOptionalVisibilitySocket(
        SOCKET_TYPE.OUT,
        outputMultiplierPointerDown,
        new BooleanType(),
        false,
        () => this.getInputData(objectsInteractive),
      ),
    ];
  }

  getWidgetProps(): WidgetProps {
    return { ...defaultProps };
  }

  getWidgetContent(props: any): React.ReactElement {
    return <ReactUICombineArrayComponent {...props} />;
  }
}

const ReactUICombineArrayComponent: React.FunctionComponent<any> = (props) => {
  const node = props.node;
  const nodeComponentId = `${node.id}-${props.inDashboard ? 'dashboard' : 'canvas'}`;

  const {
    [inputHtmlArrayName]: htmlArray = [],
    [numberPerColumnRowName]: numberPerColumn = 3,
    [drawingOrderName]: changeDrawingOrder = false,
    [gapXName]: gapX = 8,
    [gapYName]: gapY = 8,
    [widthModeName]: widthMode = 'hug',
    [heightModeName]: heightMode = 'hug',
    [SOCKETNAME_BACKGROUNDCOLOR]: background,
    [objectsInteractive]: isInteractive = false,
  } = props;

  // Calculate grid template columns based on layout mode
  const totalItems = htmlArray?.length || 0;

  // If rowFirst (changeDrawingOrder=true), we need numberPerColumn columns
  // If columnFirst (changeDrawingOrder=false), we calculate columns based on total items
  const columnsCount = changeDrawingOrder
    ? numberPerColumn
    : Math.ceil(totalItems / numberPerColumn);

  // For empty arrays, default to a safer value
  const effectiveColumnsCount = Math.max(columnsCount, 1);

  // Get width mode from enum text to value
  const widthModeValue = getEnumValue(MODE_OPTIONS, widthMode) || 'hug';
  const heightModeValue = getEnumValue(MODE_OPTIONS, heightMode) || 'hug';

  // Determine grid template column sizing based on width mode
  // For true "hug" content behavior in Grid:
  // - Use min-content (not auto) to prevent all columns from having the same width
  // - min-content will make columns as narrow as possible without overflowing content
  const gridColumnSize = widthModeValue === 'hug' ? 'min-content' : '1fr';

  // Compute styles for container and items
  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${effectiveColumnsCount}, ${gridColumnSize})`,
    gap: `${gapY}px ${gapX}px`,
    width: props.width,
    height: props.height,
    background: background?.toString() || 'transparent',
    overflow: 'auto',
    justifyItems: widthModeValue === 'hug' ? 'start' : 'stretch', // Prevent horizontal stretching for hug mode
    alignItems: heightModeValue === 'hug' ? 'start' : 'stretch', // Prevent vertical stretching for hug mode
  };

  // Handle interactivity for items
  const handleItemClick = (index: number) => {
    if (isInteractive && node) {
      // Set the output data
      node.setOutputData(outputMultiplierIndex, index);
      node.setOutputData(outputMultiplierPointerDown, true);

      // Execute children to propagate updates
      node.executeChildren();
    }
  };

  const handleItemRelease = () => {
    if (isInteractive && node) {
      node.setOutputData(outputMultiplierPointerDown, false);
      node.executeChildren();
    }
  };

  // Prepare child item styles based on width/height modes
  const getItemStyle = (isActive = false) => {
    const style: any = {
      cursor: isInteractive ? 'pointer' : 'default',
      transition: 'opacity 0.15s ease',
      opacity: isActive ? 0.7 : 1,
    };

    // Apply width styles - key changes for better "hug" behavior
    if (widthModeValue === 'hug') {
      // Don't set width - let content determine it
      // Add justifySelf to prevent stretching
      style.justifySelf = 'start';
    } else if (widthModeValue === 'fill') {
      style.width = '100%';
    } else if (widthModeValue === 'fixed') {
      style.width = props[widthName] || 'auto';
    }

    // Apply height styles with same principle
    if (heightModeValue === 'hug') {
      style.alignSelf = 'start';
    } else if (heightModeValue === 'fill') {
      style.height = '100%';
    } else if (heightModeValue === 'fixed') {
      style.height = props[heightName] || 'auto';
    }

    return style;
  };

  // Get child modes to pass to renderFunction
  const getChildModes = () => {
    // Map our container modes to appropriate child modes
    const childWidthMode = getEnumText(MODE_OPTIONS, 'hug');
    const childHeightMode = getEnumText(MODE_OPTIONS, 'hug');

    return {
      widthMode: childWidthMode,
      heightMode: childHeightMode,
    };
  };

  // Track active (pressed) item
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);

  // Arrange the items in the grid, respecting the drawing order
  const arrangedItems = useMemo(() => {
    if (!htmlArray || htmlArray.length === 0) return null;

    const childModes = getChildModes();

    return htmlArray.map((item, index) => {
      if (!item?.renderFunction) return null;

      // Calculate position based on drawing order
      let row, col;

      if (changeDrawingOrder) {
        // Row-first ordering: fill rows left-to-right, then move down
        row = Math.floor(index / numberPerColumn);
        col = index % numberPerColumn;
      } else {
        // Column-first ordering: fill columns top-to-bottom, then move right
        col = Math.floor(index / numberPerColumn);
        row = index % numberPerColumn;
      }

      // CSS grid areas are 1-indexed
      const gridArea = `${row + 1} / ${col + 1}`;
      const isActive = activeItemIndex === index;

      return (
        <Box
          key={`item-${index}`}
          style={{
            ...getItemStyle(isActive),
            gridArea: gridArea,
          }}
          onMouseDown={() => {
            if (isInteractive) {
              setActiveItemIndex(index);
              handleItemClick(index);
            }
          }}
          onMouseUp={() => {
            if (isInteractive) {
              setActiveItemIndex(null);
              handleItemRelease();
            }
          }}
          onMouseLeave={() => {
            if (isInteractive && activeItemIndex === index) {
              setActiveItemIndex(null);
              handleItemRelease();
            }
          }}
        >
          {item.renderFunction({
            index,
            randomMainColor: props.randomMainColor,
            disabled: props.disabled,
            ...childModes,
          })}
        </Box>
      );
    });
  }, [
    htmlArray,
    numberPerColumn,
    changeDrawingOrder,
    widthMode,
    heightMode,
    props[widthName],
    props[heightName],
    props.randomMainColor,
    props.disabled,
    isInteractive,
    activeItemIndex,
  ]);

  return (
    <Box id={nodeComponentId} sx={containerStyle}>
      {arrangedItems}
    </Box>
  );
};
