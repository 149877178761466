import React, { Suspense } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ThemeProvider,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import PPSocket from '../classes/SocketClass';
import { CodeType } from './datatypes/codeType';
import { downloadFile, formatDate } from '../utils/utils';
import { SOCKET_TYPE, customTheme } from '../utils/constants';
import HybridNode2 from '../classes/HybridNode2';
import { CustomFunction } from './data/dataFunctions';
import { BackPropagation } from '../interfaces';

// Lazy load MonacoEditor
const MonacoEditor = React.lazy(() =>
  import('react-monaco-editor').then((module) => ({
    default: module.default,
  })),
);

// Loading component
const EditorLoading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <CircularProgress />
  </Box>
);

const outputSocketName = 'output';
export const codeEditorInputSocketName = 'input';

export class CodeEditor extends HybridNode2 {
  public async onExecute(input, output): Promise<void> {
    output[outputSocketName] = input[codeEditorInputSocketName];
    await super.onExecute(input, output);
  }
  getPreferredInputSocketName(): string {
    return codeEditorInputSocketName;
  }

  public getName(): string {
    return 'Code editor';
  }

  public getDescription(): string {
    return 'Adds a code editor';
  }

  public getTags(): string[] {
    return ['Widget'].concat(super.getTags());
  }

  public getRoundedCorners(): boolean {
    return false;
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(SOCKET_TYPE.OUT, outputSocketName, new CodeType()),
      new PPSocket(SOCKET_TYPE.IN, codeEditorInputSocketName, new CodeType()),
    ];
  }

  public getMinNodeWidth(): number {
    return 200;
  }

  public getMinNodeHeight(): number {
    return 150;
  }

  public getDefaultNodeWidth(): number {
    return 400;
  }

  public getDefaultNodeHeight(): number {
    return 300;
  }

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(codeEditorInputSocketName),
    };
  }

  public isCallingMacro(macroName: string): boolean {
    return this.getInputData(codeEditorInputSocketName)
      ?.replaceAll("'", '"')
      .includes('acro("' + macroName);
  }

  public calledMacroChangedName(oldName: string, newName: string): void {
    if (!this.getInputSocketByName(codeEditorInputSocketName).links.length) {
      this.setInputData(
        codeEditorInputSocketName,
        CustomFunction.replaceMacroNameInCode(
          this.getInputData(codeEditorInputSocketName),
          oldName,
          newName,
        ),
      );
    }
  }

  public loadData() {
    const parsed = this.getInputData(codeEditorInputSocketName);
    this.setOutputData(outputSocketName, parsed);
  }

  onChange = async (value: string) => {
    this.setInputData(codeEditorInputSocketName, value);
    this.setOutputData(outputSocketName, value);
    await this.executeChildren();
  };

  onExport = (codeString) => {
    downloadFile(
      codeString,
      `${this.name} - ${formatDate()}.txt`,
      'text/plain',
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;

    const EditorComponent = ({ disabled }) => {
      return (
        <Suspense fallback={<EditorLoading />}>
          <MonacoEditor
            width="100%"
            height="100%"
            language="javascript"
            theme="vs-dark"
            value={props[codeEditorInputSocketName]}
            options={{
              automaticLayout: true,
              lineNumbersMinChars: 4,
              readOnly: node
                .getInputSocketByName(codeEditorInputSocketName)
                .hasLink(),
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              tabSize: 2,
              wordWrap: 'on',
            }}
            onChange={async (change) => {
              await node.onChange(change);
            }}
          />
        </Suspense>
      );
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            <EditorComponent disabled={props.disabled} />
            {props.isFocused && (
              <ButtonGroup
                variant="contained"
                size="small"
                sx={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  zIndex: 10,
                }}
              >
                <Button
                  onClick={() =>
                    node.onExport(props[codeEditorInputSocketName])
                  }
                >
                  <DownloadIcon sx={{ ml: 0.5, fontSize: '16px' }} />
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}
