/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */

import * as PIXI from 'pixi.js';
import React, { useCallback, useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Box, Button, ClickAwayListener } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import UpdateBehaviourClass from './UpdateBehaviourClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import * as styles from '../utils/style.module.css';
import {
  CustomArgs,
  DashboardWidgetProps,
  IOverlay,
  Layoutable,
  TNodeSource,
  TRgba,
  WidgetMode,
  WidgetProps,
} from '../utils/interfaces';
import {
  COLOR_WHITE,
  NINE_SLICE_SHADOW,
  NODE_MARGIN,
  NODE_CORNERRADIUS,
  NODE_SOURCE,
  MAIN_COLOR,
} from '../utils/constants';
import { SOCKET_NAME_DASHBOARD_CONTENT } from '../utils/layoutableHelpers';
import { DeferredReactTypeInterface } from '../nodes/datatypes/deferredHtmlType';
const backgroundColor = TRgba.fromString(COLOR_WHITE);

export const defaultHybridProps: WidgetProps = {
  background: backgroundColor.setAlpha(0),
  width: '100%',
  height: '370px',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'fixed',
};

interface HybridProps extends WidgetProps {
  disabled?: boolean;
}

function pixiToContainerNumber(value: number) {
  return `${Math.round(value)}px`;
}

const blurAmount = 48;

const EXTRA_EDGES = 6;

export default abstract class HybridNode2 extends PPNode implements Layoutable {
  root: Root;
  static: HTMLElement;
  staticRoot: Root;
  container: HTMLElement;
  shadowPlane: PIXI.NineSliceSprite;
  listenId: string[] = [];
  hybridNodeRenderID = 0;
  prevWasFocused = false;
  mounted = false;
  prevInputObject = undefined; // hack...
  refreshKey: number = 0;

  public getRoundedCorners(): boolean {
    return false;
  }

  protected getHybridNodeWidth() {
    return this.nodeWidth - EXTRA_EDGES * 2;
  }

  protected getHybridNodeHeight() {
    return this.nodeHeight - EXTRA_EDGES * 2;
  }

  constructor(name: string, customArgs?: CustomArgs) {
    super(name, {
      ...customArgs,
    });
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await PIXI.Assets.load(NINE_SLICE_SHADOW);
    const texture = PIXI.Texture.from(NINE_SLICE_SHADOW);
    this.shadowPlane = new PIXI.NineSliceSprite({
      texture,
      leftWidth: blurAmount,
      topHeight: blurAmount,
      rightWidth: blurAmount,
      bottomHeight: blurAmount,
    });
    this.addChildAt(this.shadowPlane, 0);
    await super.onNodeAdded(source);
    if (this.shouldFocusWhenNew() && source === NODE_SOURCE.NEW) {
      setTimeout(() => {
        this.onFocusHandler();
      }, 500); // timeout is problematic
    }
  }

  public shouldFocusWhenNew(): boolean {
    return false;
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  isLayoutable(): boolean {
    return true;
  }

  getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  getDashboardName(): string {
    return this.nodeName;
  }

  public getWidgetProps(): HybridProps {
    return { ...defaultHybridProps, disabled: false };
  }

  getDashboardWrapper(props: DashboardWidgetProps): React.ReactNode {
    if (!this.container && this.prevInputObject !== undefined) {
      this.createContainerComponent(this.prevInputObject);
    }

    // Use default heightMode if not provided
    const { heightMode = 'hug', ...otherProps } = props;

    return (
      <DynamicWidgetContainerHybridNode
        property={this}
        heightMode={heightMode}
        {...otherProps}
      />
    );
  }

  getRelatedNode(): PPNode {
    return this;
  }
  protected visualOffsetXY(x: number, y: number) {
    super.visualOffsetXY(x, y);
    this.redraw();
  }

  redraw() {
    if (this.destroyed) {
      return;
    }
    const scale = PPGraph.currentGraph.viewportScaleX;
    const screenPointBackgroundRectTopLeft =
      this.screenPointBackgroundRectTopLeft();
    const screenX = screenPointBackgroundRectTopLeft.x + EXTRA_EDGES * scale;
    const screenY = screenPointBackgroundRectTopLeft.y + EXTRA_EDGES * scale;
    if (!this.container) {
      if (this.prevInputObject !== undefined && this.isVisible()) {
        this.createContainerComponent(this.prevInputObject);
      }
      return;
    }

    if (this.isVisible()) {
      if (this.container.style.display !== 'block') {
        this.container.style.display = 'block';
      }

      const newScale = scale.toPrecision(3);
      const newLeft = pixiToContainerNumber(screenX);
      const newTop = pixiToContainerNumber(screenY);

      // Batch DOM updates
      if (
        this.container.style.transform !== `scale(${newScale})` ||
        this.container.style.left !== newLeft ||
        this.container.style.top !== newTop
      ) {
        // Use requestAnimationFrame to batch updates
        requestAnimationFrame(() => {
          this.container.style.transform = `scale(${newScale})`;
          this.container.style.left = newLeft;
          this.container.style.top = newTop;
        });
      }
    } else {
      if (this.container.style.display !== 'none') {
        this.container.style.display = 'none';
      }
    }
  }

  public fadeAllNonPIXIParts(alpha: number): void {
    if (this.container) {
      this.container.style.opacity = alpha.toString();
    }
  }

  // this function can be called for hybrid nodes, it
  // • creates a container component
  // • adds the onNodeDragOrViewportMove listener to it
  // • adds a react parent component with props
  createContainerComponent(reactProps, customStyles = {}): HTMLElement {
    const reactElement = document.createElement('div');
    this.container = document
      .getElementById('container')
      .appendChild(reactElement);
    this.root = createRoot(this.container!);
    this.container.id = `Container-${this.id}`;

    const scale = PPGraph.currentGraph.viewportScaleX;
    this.container.classList.add(styles.hybridContainer);
    Object.assign(this.container.style, customStyles);

    // set initial position
    this.container.style.width = `${this.getHybridNodeWidth()}px`;
    this.container.style.height = `${this.getHybridNodeHeight()}px`;
    this.container.style.transform = `scale(${scale}`;

    // when the Node is removed also remove the react component and its container
    this.onNodeRemoved = () => {
      this.removeContainerComponent(this.container, this.root);
      this.mounted = false;
    };

    this.mounted = true;
    // render react component
    this.renderReactComponent(
      {
        ...reactProps,
      },
      this.root,
      this,
    );

    this.redraw();

    return this.container;
  }

  abstract getWidgetContent(inputObject: any): any;

  // the render method, takes a component and props, and renders it to the page
  renderReactComponent = (
    props: {
      [key: string]: any;
    },
    root = this.root,
    node: HybridNode2 = this,
  ): void => {
    if (this.mounted) {
      root.render(
        <>
          <this.getWidgetContent
            {...props}
            id={this.id}
            selected={this.selected}
            randomMainColor={MAIN_COLOR}
            node={node}
            isFocused={this.hasFocus()}
            inDashboard={false}
            dataCyId={`${this.id}-canvas`}
            refreshKey={this.refreshKey}
          />
          <HybridNodeOverlay node={this} />
        </>,
      );
    } else {
      this.createContainerComponent(props);
    }
  };

  protected async onHybridNodeExit(): Promise<void> {}

  removeContainerComponent(container: HTMLElement, root: Root): void {
    root.unmount();
    document.getElementById('container').removeChild(container);
  }

  protected onViewportMove(): void {
    this.redraw();
  }

  setPosition(x: number, y: number, isRelative = false): void {
    super.setPosition(x, y, isRelative);
    this.onViewportMove(); // trigger this once, so the react components get positioned properly
  }

  resizeAndDraw(
    width = this.nodeWidth,
    height = this.nodeHeight,
    maintainAspectRatio = false,
  ): void {
    super.resizeAndDraw(width, height, maintainAspectRatio);
    if (this.container) {
      this.container.style.width = `${width - EXTRA_EDGES * 2}px`;
      this.container.style.height = `${height - EXTRA_EDGES * 2}px`;
    }
  }

  public forceRerender(): void {
    this.refreshKey++;
    if (this.mounted) {
      this.renderReactComponent(this.prevInputObject || {});
    }
  }

  async makeEditable(): Promise<void> {
    // register hybrid nodes to listen to outside clicks
    this.container.classList.add(styles.hybridContainerFocused);
    this.drawBackground();
    await this.execute();
  }

  public onNodeDoubleClick = () => {
    if (this.getActivateByDoubleClick()) {
      this.onFocusHandler();
    }
  };

  public hasFocus(): boolean {
    return PPGraph.currentGraph.focusedNode?.id == this.id;
  }

  async onFocusHandler(): Promise<void> {
    this.listenId.push(
      InterfaceController.addListener(ListenEvent.EscapeKeyUsed, () =>
        this.onBlurHandler(),
      ),
    );
    if (PPGraph.currentGraph.focusedNode) {
      // unfocus the previous node
      PPGraph.currentGraph.focusedNode.unFocus();
    }
    PPGraph.currentGraph.focusedNode = this;
    this.prevWasFocused = true;
    await this.makeEditable();
  }

  onBlurHandler(): void {
    if (PPGraph.currentGraph.focusedNode?.id == this.id) {
      this.unFocus();
    }
  }

  public async unFocus() {
    this.prevWasFocused = false;
    PPGraph.currentGraph.focusedNode = undefined;

    this.listenId.forEach((id) => InterfaceController.removeListener(id));
    this.listenId = [];
    // this allows to zoom and drag when the hybrid node is not selected
    this.container.classList.remove(styles.hybridContainerFocused);
    this.drawBackground();
    await this.execute();
    await this.onHybridNodeExit();
  }

  public getShrinkOnSocketRemove(): boolean {
    return false;
  }

  public getActivateByDoubleClick(): boolean {
    return true;
  }

  public getShowLabels(): boolean {
    return false;
  }

  protected async onExecute(input: any, output: any): Promise<void> {
    this.prevInputObject = input;

    if (this.isVisible()) {
      cancelAnimationFrame(this.hybridNodeRenderID);
      this.hybridNodeRenderID = requestAnimationFrame(() => {
        this.renderReactComponent(input);
      });
    }

    const ReactUI: DeferredReactTypeInterface = {
      renderFunction: (props) =>
        this.getDashboardWrapper({
          ...props,
        }),
      nodeId: this.id,
    };
    output[SOCKET_NAME_DASHBOARD_CONTENT] = ReactUI;

    await super.onExecute(input, output);
  }

  public getOpacity(): number {
    return 0.3;
  }

  public getColor(): TRgba {
    return new TRgba(200, 200, 200);
  }
  public drawBackground(): void {
    this._BackgroundGraphicsRef.roundRect(
      NODE_MARGIN,
      0,
      this.nodeWidth,
      this.nodeHeight,
      this.getRoundedCorners() ? NODE_CORNERRADIUS : 0,
    );
    if (this.hasFocus()) {
      this.shadowPlane.x = -blurAmount + NODE_MARGIN;
      this.shadowPlane.y = -blurAmount;
      this.shadowPlane.width = this.nodeWidth + blurAmount * 2;
      this.shadowPlane.height = this.nodeHeight + blurAmount * 2;
      this.shadowPlane.visible = true;
    } else {
      this.shadowPlane.visible = false;
    }
    this._BackgroundGraphicsRef.fill({
      color: this.getColor().hexNumber(),
      alpha: this.getOpacity(),
    });
  }

  onNodeRemoved = (): void => {
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
  };
}

type HybridNodeOverlayProps = {
  node: HybridNode2;
};

const HybridNodeOverlay: React.FunctionComponent<HybridNodeOverlayProps> = (
  props,
) => {
  const node = props.node;
  return (
    !node.hasFocus() && (
      <>
        {node.getActivateByDoubleClick() && (
          <Button
            data-cy={`${node.id}-edit-hybridnode-btn`}
            title={'Click to edit OR Double click node'}
            className={styles.hybridContainerEditButton}
            size="small"
            onClick={() => node.onFocusHandler()}
            color="primary"
            sx={{
              background: MAIN_COLOR,
              color: TRgba.fromString(MAIN_COLOR).getContrastTextColor().hex(),
            }}
          >
            <EditIcon sx={{ fontSize: '16px' }} />
          </Button>
        )}
      </>
    )
  );
};

type DynamicWidgetContainerHybridNodeProps = DashboardWidgetProps & {
  property: HybridNode2;
};

export const DynamicWidgetContainerHybridNode: React.FunctionComponent<
  DynamicWidgetContainerHybridNodeProps
> = (props) => {
  const [showDashboard, setShowDashboard] = useState(true);
  const [executionCount, setExecutionCount] = useState(0);
  // needs separate focus state to only trigger onBlurHandler if the dashboard one was focused
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const listenerId = InterfaceController.addListener(
      ListenEvent.OverlayStateChanged,
      (newState: IOverlay) => {
        setShowDashboard(newState.dashboard.visible);
      },
    );

    return () => InterfaceController.removeListener(listenerId);
  }, []);

  useEffect(() => {
    const executionListener = () => {
      setExecutionCount((prevCount) => prevCount + 1);
    };
    props.property.addExecutionListener(executionListener);

    return () => {
      props.property.removeExecutionListener(executionListener);
    };
  }, [props.property]);

  useEffect(() => {
    setIsFocused(props.property.hasFocus());
  }, [props.property.hasFocus()]);

  const onDashboardBlurHandler = useCallback(() => {
    if (isFocused) {
      props.property.onBlurHandler();
    }
    setIsFocused(false);
  }, [isFocused]);

  return (
    <ClickAwayListener onClickAway={onDashboardBlurHandler}>
      <Box
        id={`inspector-node-${props.property.getName()}`}
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          pointerEvents: showDashboard ? 'unset' : 'none',
        }}
        onPointerDown={() => {
          if (!isFocused) {
            props.property.onFocusHandler();
          }
        }}
      >
        <props.property.getWidgetContent
          {...HybridNode2.remapInput(props.property.inputSocketArray)}
          id={props.property.id}
          selected={props.property.selected}
          isFocused={props.property.hasFocus()}
          randomMainColor={props.randomMainColor}
          disabled={props.disabled}
          node={props.property}
          showDashboard={showDashboard}
          inDashboard={true}
          executionCount={executionCount}
          dataCyId={`${props.property.id}-dashboard`}
          refreshKey={props.property.refreshKey}
          widthMode={props.widthMode}
          width={props.width}
          heightMode={props.heightMode}
          height={props.height}
        />
      </Box>
    </ClickAwayListener>
  );
};
