import { useState } from 'react';
import PPGraph from '../../classes/GraphClass';
import { Layoutable } from '../../utils/interfaces';

export function useHoverEvents(layoutableElement: Layoutable) {
  const [localHover, setLocalHover] = useState(false);

  const handleMouseEnter = async (e: React.MouseEvent) => {
    setLocalHover(true);
    const nodeToJumpTo = layoutableElement.getRelatedNode();
    if (nodeToJumpTo) {
      PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
    }
  };

  const handleMouseLeave = async (e: React.MouseEvent) => {
    setLocalHover(false);
    PPGraph.currentGraph.selection.clearFocus();
  };

  return {
    localHover,
    handleMouseEnter,
    handleMouseLeave,
  };
}
