import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  styled,
  useTheme,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShareIcon from '@mui/icons-material/Share';
import TuneIcon from '@mui/icons-material/Tune';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MenuIcon from '@mui/icons-material/Menu';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PPStorage from './../PPStorage';
import PPGraph from '../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import GraphOverlayDrawer from './GraphOverlayDrawer';
import GraphOverlayDashboard from './dashboard/GraphOverlayDashboard';
import { useIsSmallScreen } from '../utils/utils';
import {
  DrawerContent,
  DrawerSide,
  IOverlay,
  TRgba,
} from '../utils/interfaces';
import {
  CONTEXTMENU_GRAPH_HEIGHT,
  CONTEXTMENU_WIDTH,
  DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
  DRAWER_CONSTANTS,
} from '../utils/constants';
import { NarrowIcon, PNPIconNoShadow, WidenIcon } from '../utils/icons';
import { VISIBILITY_ACTION } from '../utils/constants_shared';
import SocialIcons from '../components/SocialIcons';
import CompanionComponent from '../components/CompanionComponent';
import ShareContextMenu from './contextmenus/ShareContextMenu';
import PersonIcon from '@mui/icons-material/Person';
import Authentication from './../firebase/Authentication';
import { FirebaseAppHandler } from '../firebase/FirebaseAppHandler';

type GraphOverlayProps = {
  randomMainColor: string;
  setContextMenuPosition: React.Dispatch<React.SetStateAction<number[]>>;
  setIsGraphContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GraphOverlay: React.FunctionComponent<GraphOverlayProps> = (props) => {
  const [currentGraph, setCurrentGraph] = useState(PPGraph.currentGraph);
  const [overlayState, setOverlayState] = useState<IOverlay>({
    leftSide: {
      visible: false,
      width: DRAWER_CONSTANTS.DEFAULT_DRAWER_WIDTH,
      activeContent: 'graphs',
    },
    dashboard: {
      visible: false,
      fullscreen: false,
      widthPercentage: DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
      locked: false,
    },
    rightSide: { visible: false, width: DRAWER_CONSTANTS.DEFAULT_DRAWER_WIDTH },
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const setIsDashboardInEditMode = useCallback(
    (action: VISIBILITY_ACTION) => {
      action === VISIBILITY_ACTION.TOGGLE
        ? setIsEditMode((edit) => !edit)
        : setIsEditMode(action === VISIBILITY_ACTION.OPEN);
    },
    [setIsEditMode],
  );

  useEffect(() => {
    InterfaceController.toggleDashboardInEditMode = setIsDashboardInEditMode;
  }, [setIsEditMode]);

  const updateOverlayState = useCallback((newState: Partial<IOverlay>) => {
    setOverlayState((prevState) => {
      const state = { ...prevState, ...newState };
      InterfaceController.notifyListeners(
        ListenEvent.OverlayStateChanged,
        state,
      );
      return state;
    });
  }, []);

  const toggleDrawer = useCallback(
    (side: DrawerSide, action: VISIBILITY_ACTION, content?: DrawerContent) => {
      if (side === DrawerSide.DASHBOARD) {
        return;
      }

      // Check if we should close the drawer (same content and already open)
      const shouldClose =
        side === DrawerSide.LEFT &&
        content === overlayState[side].activeContent &&
        overlayState[side].visible;

      if (side === DrawerSide.LEFT && content) {
        // Only notify about content change if we're not closing
        if (!shouldClose) {
          InterfaceController.notifyListeners(
            ListenEvent.DrawerContentChanged,
            content,
          );
        }
      }

      updateOverlayState({
        [side]: {
          visible: shouldClose
            ? false
            : action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState[side].visible
              : action === VISIBILITY_ACTION.OPEN,
          width: overlayState[side].width,
          ...(side === DrawerSide.LEFT &&
            content && { activeContent: content }),
        },
      });
    },
    [overlayState, updateOverlayState],
  );

  const toggleLeftSideDrawer = useCallback(
    (action: VISIBILITY_ACTION, content?: DrawerContent) =>
      toggleDrawer(DrawerSide.LEFT, action, content),
    [toggleDrawer],
  );

  const toggleRightSideDrawer = useCallback(
    (action: VISIBILITY_ACTION) => toggleDrawer(DrawerSide.RIGHT, action),
    [toggleDrawer],
  );

  const toggleDashboard = useCallback(
    (action: VISIBILITY_ACTION) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          visible:
            action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState.dashboard.visible
              : action === VISIBILITY_ACTION.OPEN,
        },
      });
    },
    [overlayState.dashboard, updateOverlayState],
  );

  const toggleFullscreen = useCallback(
    (action: VISIBILITY_ACTION) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          fullscreen:
            action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState.dashboard.fullscreen
              : action === VISIBILITY_ACTION.OPEN,
        },
      });
    },
    [overlayState.dashboard, updateOverlayState],
  );

  const setDashboardWidthPercentage = useCallback(
    (percentage: number) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          widthPercentage: percentage,
        },
      });
    },
    [
      overlayState.dashboard.widthPercentage,
      overlayState.dashboard.visible,
      updateOverlayState,
    ],
  );

  useEffect(() => {
    // Update InterfaceController methods
    InterfaceController.toggleLeftSideDrawer = toggleLeftSideDrawer;
    InterfaceController.toggleShowDashboard = toggleDashboard;
    InterfaceController.toggleRightSideDrawer = toggleRightSideDrawer;

    InterfaceController.getOverlayState = () => overlayState;
    InterfaceController.updateOverlayState = updateOverlayState;

    const ids = [];

    ids.push(
      InterfaceController.addListener(
        ListenEvent.GraphChanged,
        setCurrentGraph,
      ),
    );

    return () => ids.forEach((id) => InterfaceController.removeListener(id));
  }, [
    overlayState,
    toggleLeftSideDrawer,
    toggleDashboard,
    toggleRightSideDrawer,
    updateOverlayState,
  ]);

  const updateDrawerWidth = useCallback(
    (width: number) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          fullscreen: false,
          visible: true,
          widthPercentage: width,
        },
      });
    },
    [updateOverlayState],
  );

  return (
    <>
      <PlaygroundButtonGroup
        setContextMenuPosition={props.setContextMenuPosition}
        setIsGraphContextMenuOpen={props.setIsGraphContextMenuOpen}
        randomMainColor={props.randomMainColor}
        overlayState={overlayState}
        toggleFullscreen={toggleFullscreen}
        setDashboardWidthPercentage={setDashboardWidthPercentage}
        isEditMode={isEditMode}
        currentGraph={currentGraph}
      />
      <GraphOverlayDrawer
        randomMainColor={props.randomMainColor}
        overlayState={overlayState}
        updateOverlayState={updateOverlayState}
        disableHoverOpen={isEditMode}
      />
      {currentGraph && (
        <GraphOverlayDashboard
          randomMainColor={props.randomMainColor}
          overlayState={overlayState}
          updateOverlayState={updateOverlayState}
          isEditMode={isEditMode}
          toggle={overlayState[DrawerSide.DASHBOARD].visible}
          setDrawerWidthPercentage={updateDrawerWidth}
          drawerWidthPercentage={overlayState.dashboard.widthPercentage}
        />
      )}
    </>
  );
};

export default GraphOverlay;

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['isSelected'].includes(prop as string),
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  minWidth: 0,
  padding: theme.spacing(0.5),
  backgroundColor: isSelected
    ? theme.palette.primary.main
    : theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: isSelected
      ? theme.palette.primary.dark
      : theme.palette.grey[200],
  },
  width: 32,
  height: 32,
  '& .MuiSvgIcon-root': {
    color: isSelected ? theme.palette.common.white : 'inherit',
  },
  '--svg-fill-color': isSelected
    ? theme.palette.common.white
    : theme.palette.primary.main,
}));

type PlaygroundButtonGroupProps = {
  randomMainColor: string;
  setContextMenuPosition: React.Dispatch<React.SetStateAction<number[]>>;
  setIsGraphContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  overlayState: IOverlay;
  toggleFullscreen: (open: VISIBILITY_ACTION) => void;
  setDashboardWidthPercentage: (percentage: number) => void;
  isEditMode: boolean;
  currentGraph: PPGraph;
};

const PlaygroundButtonGroup: React.FunctionComponent<PlaygroundButtonGroupProps> =
  React.memo(
    (props) => {
      const theme = useTheme();
      const smallScreen = useIsSmallScreen();
      const isDashboardVisible = props.overlayState.dashboard.visible;
      const isDashboardFullscreen =
        isDashboardVisible && props.overlayState.dashboard.fullscreen;

      const [isCollapsed, setIsCollapsed] = useState(
        smallScreen ||
          (props.overlayState.dashboard.visible &&
            props.overlayState.dashboard.fullscreen),
      );

      const [isCreatingNewGraph, setIsCreatingNewGraph] = useState(false);
      const [
        isGraphContextMenuOpenInternal,
        setIsGraphContextMenuOpenInternal,
      ] = useState(false);
      const [shareMenuAnchor, setShareMenuAnchor] = useState<{
        hasClick: boolean;
        top: number;
        left: number;
      }>({
        hasClick: false,
        top: 0,
        left: 0,
      });
      const [authDialogOpen, setAuthDialogOpen] = useState(false);
      const [currentUser, setCurrentUser] = useState(
        FirebaseAppHandler.getInstance().getCurrentUser(),
      );

      const posX = smallScreen
        ? 8
        : window.innerWidth / 2 - CONTEXTMENU_WIDTH / 2;
      const posY = smallScreen
        ? 8
        : window.innerHeight - CONTEXTMENU_GRAPH_HEIGHT - 88;

      const openDashboardClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ): void => {
        event.stopPropagation();
        InterfaceController.toggleShowDashboard(VISIBILITY_ACTION.TOGGLE);
        if (smallScreen) {
          InterfaceController.toggleLeftSideDrawer(VISIBILITY_ACTION.CLOSE);
        }
      };

      const handleCreateNewClick = async () => {
        if (isCreatingNewGraph) return;

        setIsCreatingNewGraph(true);
        await Promise.all([
          PPStorage.getInstance().createAndSaveNewGraph(),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ]);
        setIsCreatingNewGraph(false);
      };

      useEffect(() => {
        setIsCollapsed(
          smallScreen ||
            (props.overlayState.dashboard.visible &&
              props.overlayState.dashboard.fullscreen),
        );
      }, [props.currentGraph]);

      useEffect(() => {
        // Listen for auth state changes
        const handleAuthChange = (isLoggedIn) => {
          if (isLoggedIn) {
            setCurrentUser(FirebaseAppHandler.getInstance().getCurrentUser());
          } else {
            setCurrentUser(null);
          }
        };

        // Subscribe to the user login event
        const listenerId = InterfaceController.addListener(
          ListenEvent.UserIsLoggedIn,
          handleAuthChange,
        );

        // Cleanup
        return () => {
          InterfaceController.removeListener(listenerId);
        };
      }, []);

      return (
        <>
          {props.currentGraph &&
            !(
              props.overlayState.leftSide.visible ||
              props.overlayState.dashboard.visible ||
              props.overlayState.rightSide.visible ||
              isGraphContextMenuOpenInternal
            ) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '13px',
                  left: '48px',
                  color: theme.palette.primary.main,
                  fontSize: '14px',
                  fontWeight: 500,
                  zIndex: '1300',
                  userSelect: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  InterfaceController.setShowGraphEdit(true);
                }}
              >
                {props.currentGraph.name}
              </Box>
            )}
          <Box
            id="playground-button-group"
            sx={{
              '--svg-fill-color': TRgba.fromString(props.randomMainColor).hex(),
              position: 'fixed',
              top: '4px',
              left: '8px',
              zIndex: '1310',
              maxHeight: 'calc(100vh - 16px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            <Stack spacing={0.5} alignItems="left">
              <IconButton
                data-cy="toggle-playground-button"
                size="small"
                onClick={() => {
                  setIsCollapsed((prev) => !prev);
                }}
                sx={{
                  padding: 0,
                  width: '32px',
                  '--svg-fill-color':
                    props.overlayState.leftSide.visible ||
                    props.overlayState.dashboard.visible
                      ? TRgba.white().hex()
                      : TRgba.fromString(props.randomMainColor).hex(),
                }}
              >
                <PNPIconNoShadow />
              </IconButton>

              {!isCollapsed && (
                <>
                  <Tooltip title="Open playground list (1)" placement="right">
                    <StyledButton
                      isSelected={
                        props.overlayState.leftSide.visible &&
                        props.overlayState.leftSide.activeContent === 'graphs'
                      }
                      onClick={() =>
                        InterfaceController.toggleLeftSideDrawer(
                          VISIBILITY_ACTION.OPEN,
                          'graphs',
                        )
                      }
                    >
                      <MenuIcon />
                    </StyledButton>
                  </Tooltip>
                  <Divider sx={{ width: '32px', my: 0.5 }} />
                  <Tooltip title="Open dashboard (2)" placement="right">
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      <StyledButton
                        data-cy="toggle-dashboard-btn"
                        isSelected={props.overlayState.dashboard.visible}
                        onClick={openDashboardClick}
                      >
                        <DashboardIcon />
                      </StyledButton>
                    </Box>
                  </Tooltip>
                  {!smallScreen &&
                    props.overlayState.dashboard.visible &&
                    (props.overlayState.dashboard.fullscreen ? (
                      <Tooltip title="Shrink dashboard" placement="right">
                        <StyledButton
                          data-cy="shrink-dashboard-btn"
                          isSelected={props.overlayState.dashboard.visible}
                          onClick={(event) => {
                            event.stopPropagation();
                            props.toggleFullscreen(VISIBILITY_ACTION.CLOSE);
                          }}
                        >
                          <NarrowIcon />
                        </StyledButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Maximise dashboard" placement="right">
                        <StyledButton
                          data-cy="maximise-dashboard-btn"
                          isSelected={props.overlayState.dashboard.visible}
                          onClick={(event) => {
                            event.stopPropagation();
                            props.toggleFullscreen(VISIBILITY_ACTION.OPEN);
                          }}
                        >
                          <WidenIcon />
                        </StyledButton>
                      </Tooltip>
                    ))}
                  {!smallScreen && props.overlayState.dashboard.visible && (
                    <Tooltip
                      title={
                        props.isEditMode
                          ? 'View dashboard (E)'
                          : 'Edit dashboard (E)'
                      }
                      placement="right"
                    >
                      <StyledButton
                        data-cy="toggle-edit-mode-btn"
                        isSelected={props.overlayState.dashboard.visible}
                        onClick={(event) => {
                          event.stopPropagation();
                          InterfaceController.toggleDashboardInEditMode(
                            VISIBILITY_ACTION.TOGGLE,
                          );
                        }}
                      >
                        {props.isEditMode ? <CloseIcon /> : <EditIcon />}
                      </StyledButton>
                    </Tooltip>
                  )}
                  {!isDashboardFullscreen && (
                    <>
                      <Divider sx={{ width: '32px', my: 0.5 }} />

                      <Tooltip
                        title="Open node inspector (3)"
                        placement="right"
                      >
                        <StyledButton
                          data-cy="inspector-container-toggle-button"
                          isSelected={props.overlayState.rightSide.visible}
                          onClick={() => {
                            InterfaceController.toggleRightSideDrawer(
                              VISIBILITY_ACTION.TOGGLE,
                            );
                            if (smallScreen) {
                              InterfaceController.toggleLeftSideDrawer(
                                VISIBILITY_ACTION.CLOSE,
                              );
                              InterfaceController.toggleShowDashboard(
                                VISIBILITY_ACTION.CLOSE,
                              );
                            }
                          }}
                        >
                          <TuneIcon />
                        </StyledButton>
                      </Tooltip>
                    </>
                  )}
                  <Divider sx={{ width: '32px', my: 0.5 }} />
                  {smallScreen && (
                    <>
                      <StyledButton
                        data-cy="toggle-context-menu-btn"
                        isSelected={isGraphContextMenuOpenInternal}
                        onClick={(event) => {
                          event.stopPropagation();

                          props.setContextMenuPosition([posX, posY]);
                          props.setIsGraphContextMenuOpen((isOpen) => {
                            setIsGraphContextMenuOpenInternal(!isOpen);
                            return !isOpen;
                          });
                        }}
                      >
                        <MoreVertIcon />
                      </StyledButton>
                    </>
                  )}
                  <>
                    <Tooltip title="Open AI assistant" placement="right">
                      <StyledButton
                        data-cy="aiButton"
                        isSelected={
                          props.overlayState.leftSide.visible &&
                          props.overlayState.leftSide.activeContent === 'ai'
                        }
                        onClick={() => {
                          InterfaceController.toggleLeftSideDrawer(
                            VISIBILITY_ACTION.OPEN,
                            'ai',
                          );
                        }}
                      >
                        <AutoAwesomeIcon />
                      </StyledButton>
                    </Tooltip>

                    <Tooltip title="Open help" placement="right">
                      <StyledButton
                        data-cy="helpButton"
                        isSelected={
                          props.overlayState.leftSide.visible &&
                          props.overlayState.leftSide.activeContent === 'help'
                        }
                        onClick={() => {
                          InterfaceController.toggleLeftSideDrawer(
                            VISIBILITY_ACTION.OPEN,
                            'help',
                          );
                        }}
                      >
                        <QuestionMarkIcon />
                      </StyledButton>
                    </Tooltip>

                    <Divider sx={{ width: '32px', my: 0.5 }} />

                    <Tooltip title="Share this playground" placement="right">
                      <StyledButton
                        data-cy="shareCurrentButton"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          event.stopPropagation();
                          const rect =
                            event.currentTarget.getBoundingClientRect();
                          setShareMenuAnchor({
                            hasClick: true,
                            top: rect.top - 4,
                            left: rect.left + rect.width + 4,
                          });
                        }}
                      >
                        <ShareIcon />
                      </StyledButton>
                    </Tooltip>

                    {shareMenuAnchor.hasClick && (
                      <>
                        <Box
                          sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1300,
                          }}
                          onClick={() =>
                            setShareMenuAnchor((state) => ({
                              ...state,
                              hasClick: false,
                            }))
                          }
                        />
                        <ShareContextMenu
                          anchorPosition={shareMenuAnchor}
                          onClose={() =>
                            setShareMenuAnchor((state) => ({
                              ...state,
                              hasClick: false,
                            }))
                          }
                        />
                      </>
                    )}

                    <Divider sx={{ width: '32px', my: 0.5 }} />

                    <Tooltip title="Create new playground" placement="right">
                      <StyledButton
                        data-cy="createNewPlaygroundButton"
                        disabled={isCreatingNewGraph}
                        onClick={handleCreateNewClick}
                      >
                        {isCreatingNewGraph ? (
                          <CircularProgress size={20} />
                        ) : (
                          <AddIcon />
                        )}
                      </StyledButton>
                    </Tooltip>

                    <Tooltip title="Account" placement="right">
                      <StyledButton
                        data-cy="auth-button"
                        onClick={() => setAuthDialogOpen(true)}
                      >
                        {currentUser !== null ? (
                          <Avatar
                            src={currentUser.photoURL || ''}
                            alt={
                              currentUser.displayName ||
                              currentUser.email ||
                              'User'
                            }
                            sx={{
                              width: 28,
                              height: 28,
                              bgcolor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                              fontWeight: 'bold',
                              fontSize: '12px',
                            }}
                          >
                            {currentUser.displayName
                              ? currentUser.displayName[0].toUpperCase()
                              : currentUser.email
                                ? currentUser.email[0].toUpperCase()
                                : 'U'}
                          </Avatar>
                        ) : (
                          <PersonIcon />
                        )}
                      </StyledButton>
                    </Tooltip>

                    <CompanionComponent />
                  </>
                </>
              )}
            </Stack>
          </Box>
          {!isCollapsed && (
            <Box
              id="playground-button-group"
              sx={{
                position: 'fixed',
                bottom: '8px',
                left: '8px',
                zIndex: '1300',
              }}
            >
              <SocialIcons randomMainColor={props.randomMainColor} />
            </Box>
          )}

          {/* Authentication Dialog */}
          {authDialogOpen && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setAuthDialogOpen(false)}
            >
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  maxHeight: '90vh',
                  overflowY: 'auto',
                }}
              >
                <Authentication />
              </Box>
            </Box>
          )}
        </>
      );
    },
    (prevProps, nextProps) => {
      const { overlayState: prev } = prevProps;
      const { overlayState: next } = nextProps;

      return (
        prevProps.randomMainColor === nextProps.randomMainColor &&
        prevProps.isEditMode === nextProps.isEditMode &&
        prevProps.currentGraph?.id === nextProps.currentGraph?.id &&
        compareOverlayProps(prev.dashboard, next.dashboard, [
          'visible',
          'fullscreen',
          'locked',
        ]) &&
        compareOverlayProps(prev.leftSide, next.leftSide, [
          'visible',
          'activeContent',
        ]) &&
        compareOverlayProps(prev.rightSide, next.rightSide, ['visible'])
      );
    },
  );

const compareOverlayProps = (
  prev: Record<string, any>,
  next: Record<string, any>,
  propsToCompare: string[] = [],
) => {
  return propsToCompare.every((prop) => prev[prop] === next[prop]);
};
