import React from 'react';
import {
  Paper,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import InterfaceController from '../../InterfaceController';
import PPStorage from '../../PPStorage';
import { controlOrMetaKey } from '../../utils/utils';

export function shareOptions(): any {
  return [
    <MenuItem
      data-cy="download-plain-text"
      onClick={() => PPStorage.getInstance().downloadCurrentGraph(false)}
    >
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Download (Plain Text)</ListItemText>
    </MenuItem>,
    <MenuItem
      data-cy="download-compressed"
      onClick={() => PPStorage.getInstance().downloadCurrentGraph(true)}
    >
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Download (Compressed)</ListItemText>
    </MenuItem>,
    <MenuItem
      data-cy="share-playground-link"
      onClick={PPStorage.getInstance().copyCurrentGraphURLToClipboard}
    >
      <ListItemIcon>
        <ShareIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Share playground embedded in link</ListItemText>
      <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
        {`${controlOrMetaKey()}+K`}
      </Typography>
    </MenuItem>,
    <MenuItem
      data-cy="share-via-github"
      onClick={() => InterfaceController.setShowSharePlayground(true)}
    >
      <ListItemIcon>
        <ShareIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Share via Github</ListItemText>
    </MenuItem>,
  ];
}

interface ShareContextMenuProps {
  anchorPosition: { top: number; left: number };
  onClose: () => void;
}

const ShareContextMenu: React.FC<ShareContextMenuProps> = (props) => {
  return (
    <Paper
      sx={{
        position: 'fixed',
        top: props.anchorPosition.top,
        left: props.anchorPosition.left,
        zIndex: 1400,
      }}
    >
      <MenuList dense>{shareOptions()}</MenuList>
    </Paper>
  );
};

export default ShareContextMenu;
