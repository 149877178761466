import React, { memo, Suspense, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  ThemeProvider,
  styled,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as styles from '../utils/style.module.css';
import PPGraph from './../classes/GraphClass';
import PPStorage from './../PPStorage';
import InterfaceController from './../InterfaceController';
import { IGraphSearch, TRgba } from './../utils/interfaces';
import { customTheme } from './../utils/constants';
import {
  getLoadGraphExampleURL,
  removeExtension,
  useIsSmallScreen,
  writeTextToClipboard,
} from './../utils/utils';
import { VISIBILITY_ACTION } from './../utils/constants_shared';
import MDXCreate from '../help/help.mdx';
import MDXAbout from '../help/about.mdx';
import { MDXContentStyled } from './MDXContentStyled';

const NodesContent = React.lazy(() => import('./NodesContent'));

const AIConversationBrowser = React.lazy(
  () => import('../components/AIConversationBrowser'),
);
const AIHelpContainer = React.lazy(() => import('./AIHelpContainer'));

const Item = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(1),
  elevation: 0,
  borderRadius: 0,
  overflow: 'auto',
  userSelect: 'text',
  a: {
    color: theme.palette.secondary.light,
  },
}));

async function loadAllGraphsFromDatabase(): Promise<{
  newGraphSearchItems: IGraphSearch[];
  remoteGraphSearchItems: IGraphSearch[];
}> {
  console.log('LOADING ALL GRAPHS FROM DATABASE');
  const remoteGraphs: any[] =
    await PPStorage.getInstance().getRemoteGraphsList();
  const remoteGraphSearchItems = remoteGraphs
    .filter((file) => file.endsWith('.ppgraph'))
    .map((graph) => {
      const name = removeExtension(graph);
      return {
        id: graph,
        name: name,
        label: 'remote',
        isRemote: true,
      } as IGraphSearch;
    });

  // add remote header entry
  if (remoteGraphSearchItems.length > 0) {
    remoteGraphSearchItems.unshift({
      id: `remote-header`,
      name: 'Remote playgrounds', // opening a remote playground creates a local copy
      isDisabled: true,
    });
  }

  const newGraphSearchItems: IGraphSearch[] =
    await PPStorage.getInstance().getGraphsList();

  // add local header entry
  if (newGraphSearchItems.length > 0) {
    newGraphSearchItems.unshift({
      id: `local-header`,
      name: 'Local playgrounds',
      isDisabled: true,
    });
  }

  return { newGraphSearchItems, remoteGraphSearchItems };
}

async function load(setGraphSearchItems, setGraphSearchActiveItem) {
  const { newGraphSearchItems, remoteGraphSearchItems } =
    await loadAllGraphsFromDatabase();
  const allGraphSearchItems = [
    ...newGraphSearchItems,
    ...remoteGraphSearchItems,
  ];
  setGraphSearchItems(allGraphSearchItems);

  if (PPGraph.currentGraph) {
    const selectedItem = newGraphSearchItems.find(
      (item) => item.id === PPGraph.currentGraph.id,
    );
    setGraphSearchActiveItem(selectedItem);
  }
}

export const LeftsideContainer = (props) => {
  const [graphSearchItems, setGraphSearchItems] = useState<
    IGraphSearch[] | null
  >([{ id: '', name: '' }]);
  const [graphSearchActiveItem, setGraphSearchActiveItem] =
    useState<IGraphSearch | null>(null);

  const updateGraphSearchItems = () => {
    load(setGraphSearchItems, setGraphSearchActiveItem);
  };

  const loadGraph = (id, isRemote) => {
    if (isRemote) {
      PPStorage.getInstance().cloneRemoteGraph(id);
    } else {
      PPStorage.getInstance().loadGraphFromDB(id);
      const selectedItem = graphSearchItems.find((item) => item.id === id);
      setGraphSearchActiveItem(selectedItem);
    }
  };

  InterfaceController.onGraphListChanged = updateGraphSearchItems;

  useEffect(() => {
    updateGraphSearchItems();
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <Stack
        id="inspector-container-left"
        spacing={1}
        className={`${styles.inspectorContainer}`}
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
          height: '100vh',
          padding: 0,
          paddingLeft: '44px',
        }}
      >
        {props.activeContent === 'graphs' && (
          <GraphsContent
            graphs={graphSearchItems}
            graphSearchActiveItem={graphSearchActiveItem}
            loadGraph={loadGraph}
            randomMainColor={props.randomMainColor}
          />
        )}
        {props.activeContent === 'help' && (
          <Box
            sx={{
              padding: '8px',
              overflow: 'auto',
              userSelect: 'text',
            }}
          >
            <MDXContentStyled>
              <MDXCreate />
              <MDXAbout />
            </MDXContentStyled>
          </Box>
        )}
        {props.activeContent === 'nodes' && (
          <Suspense>
            <NodesContent randomMainColor={props.randomMainColor} />
          </Suspense>
        )}
        {props.activeContent === 'ai' && (
          <Suspense>
            <AIHelpContainer />
            <AIConversationBrowser />
          </Suspense>
        )}
      </Stack>
    </ThemeProvider>
  );
};

const GraphsContent = (props) => {
  return (
    <>
      <List
        id="graphs-list"
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 40px)',
          paddingLeft: '0 !important',
        }}
        subheader={<li />}
      >
        {props.graphs.map((property) => {
          return (
            <GraphItem
              key={`item-${property.id}`}
              graphSearchActiveItem={props.graphSearchActiveItem}
              loadGraph={props.loadGraph}
              property={property}
              randomMainColor={props.randomMainColor}
              sx={{
                listStyleType: 'none',
                m: 1,
              }}
            />
          );
        })}
      </List>
    </>
  );
};

const handleMainClick = (
  graph: IGraphSearch,
  loadGraph: (id: string, isRemote: boolean) => void,
  smallScreen: boolean,
) => {
  loadGraph(graph.id, graph.isRemote);
  if (smallScreen) {
    InterfaceController.toggleLeftSideDrawer(VISIBILITY_ACTION.CLOSE);
  }
};

const handleCopyUrl = (url: string | null, event: React.MouseEvent) => {
  event.stopPropagation();
  InterfaceController.setIsGraphSearchOpen(false);
  if (url) writeTextToClipboard(url);
};

const handleOpenNewTab = (url: string | null, event: React.MouseEvent) => {
  event.stopPropagation();
  InterfaceController.setIsGraphSearchOpen(false);
  if (url) window.open(url, '_blank')?.focus();
};

const handleDownload = (graphId: string, event: React.MouseEvent) => {
  event.stopPropagation();
  InterfaceController.setIsGraphSearchOpen(false);
  PPStorage.getInstance().downloadGraph(graphId);
};

const handleEdit = (graph: IGraphSearch, event: React.MouseEvent) => {
  event.stopPropagation();
  InterfaceController.setGraphToBeModified(graph);
  InterfaceController.setShowGraphEdit(true);
};

const handleDelete = (graph: IGraphSearch, event: React.MouseEvent) => {
  event.stopPropagation();
  InterfaceController.setGraphToBeModified(graph);
  InterfaceController.setShowGraphDelete(true);
};

interface GraphItemProps {
  property: IGraphSearch;
  graphSearchActiveItem: IGraphSearch | null;
  randomMainColor: string;
  loadGraph: (id: string, isRemote: boolean) => void;
  sx: any;
}

const GraphItem = memo(
  ({
    property: graph,
    graphSearchActiveItem,
    randomMainColor,
    loadGraph,
  }: GraphItemProps) => {
    const smallScreen = useIsSmallScreen();

    const url = graph.isRemote ? getLoadGraphExampleURL(graph.name) : null;
    const contrastTextColor = TRgba.fromString(randomMainColor)
      .getContrastTextColor()
      .hex();

    if (graph.isDisabled) {
      return (
        <ListSubheader
          sx={{
            lineHeight: '40px',
            paddingLeft: '8px',
            bgcolor: `${TRgba.fromString(randomMainColor).darken(0.7)}`,
          }}
        >
          {graph.name}
        </ListSubheader>
      );
    }

    return (
      <ListItem
        sx={{
          p: 0,
          '&:hover + .MuiListItemSecondaryAction-root': {
            visibility: 'visible',
          },
          bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6)}`,
          margin: '1px 0',
        }}
        title={
          graph.isRemote
            ? `Load remote playground\nNOTE: Save it after loading, if you want to make changes to it`
            : `Load local playground\n${graph.id}`
        }
      >
        <ListItemButton
          selected={graph.id === graphSearchActiveItem?.id}
          onClick={(e) => handleMainClick(graph, loadGraph, smallScreen)}
          sx={{
            px: 1,
            py: 0,
            '&.Mui-selected': {
              bgcolor: `${TRgba.fromString(randomMainColor)}`,
              color: contrastTextColor,
            },
          }}
        >
          <ListItemText
            primary={graph.name}
            primaryTypographyProps={{
              sx: { fontStyle: graph.isRemote ? 'italic' : 'inherit' },
            }}
            secondary={graph.label}
            secondaryTypographyProps={{
              sx: {
                fontSize: '10px',
                '.Mui-selected &': {
                  color: contrastTextColor,
                },
              },
            }}
          />
        </ListItemButton>
        <ListItemSecondaryAction
          data-cy={`hover-${graph.name}`}
          sx={{
            visibility: 'hidden',
            '&&:hover': {
              visibility: 'visible',
            },
            '.MuiListItem-root:has(+ &:hover)': {
              background: 'rgba(255, 255, 255, 0.08)',
            },
            bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6)}`,
            right: '8px',
          }}
        >
          {graph.isRemote ? (
            <>
              <IconButton
                size="small"
                onClick={(e) => handleCopyUrl(url, e)}
                title="Copy URL"
                data-cy="copyURLButton"
                className={styles.menuItemButton}
              >
                <LinkIcon />
              </IconButton>
              <IconButton
                size="small"
                title="Open in new tab"
                data-cy="openInNewtabButton"
                className={styles.menuItemButton}
                onClick={(e) => handleOpenNewTab(url, e)}
              >
                <OpenInNewIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={(e) => handleDownload(graph.id, e)}
                title="Download playground"
                data-cy="downloadButton"
                className={styles.menuItemButton}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => handleEdit(graph, e)}
                title="Rename playground"
                data-cy="editButton"
                className={styles.menuItemButton}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                title="Delete playground"
                data-cy="deleteButton"
                className={styles.menuItemButton}
                onClick={(e) => handleDelete(graph, e)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function for memo
    return (
      prevProps.property.id === nextProps.property.id &&
      prevProps.property.name === nextProps.property.name &&
      prevProps.property.isRemote === nextProps.property.isRemote &&
      prevProps.graphSearchActiveItem?.id ===
        nextProps.graphSearchActiveItem?.id &&
      prevProps.randomMainColor === nextProps.randomMainColor
    );
  },
);
