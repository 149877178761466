/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { inspect } from 'util';
import Socket from '../../classes/SocketClass';
import { DefaultOutputWidget, CodeWidget } from '../../widgets';
import { TParseType, TRgba, WidgetMode } from '../../utils/interfaces';
import {
  STATUS_SEVERITY,
  SOCKET_COLOR_HEX,
  SOCKET_CORNERRADIUS,
  SOCKET_WIDTH,
} from '../../utils/constants';
import * as PIXI from 'pixi.js';
import { PNPStatus, SocketParsingWarning } from '../../classes/ErrorClass';
import { AnyType } from './anyType';
import PPNode from '../../classes/NodeClass';

export interface DataTypeProps {
  key?: string;
  property: Socket;
  index: number;
  randomMainColor: any;
  dataType: AbstractType;
  language: string;
  inDashboard?: boolean;
}

type DataTypeWidgetProps = {
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  width: string;
  height: string;
  minWidth: string;
  minHeight: string;
  widthMode: WidgetMode;
  heightMode: WidgetMode;
};

export const dataTypeWidgetDefaultProps: DataTypeWidgetProps = {
  background: { r: 9, g: 13, b: 26, a: 1 },
  width: '100%',
  height: 'auto',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'hug',
};

export function isDirectlyCompatible(type: CompatibilityType) {
  return (
    type == CompatibilityType.Exact || type == CompatibilityType.Compatible
  );
}

export function IsCompatible(type: CompatibilityType) {
  return (
    type == CompatibilityType.Exact ||
    CompatibilityType.Preferred ||
    type == CompatibilityType.Compatible ||
    type == CompatibilityType.NeedDirectConversion
  );
}

export enum CompatibilityType {
  Exact = 0,
  Preferred = 0,
  Compatible = 1,
  NeedDirectConversion = 2,
  Incompatible = 3,
}

export class Compatibility {
  type: CompatibilityType;
  conversionNode: undefined | string;

  constructor(
    inType: CompatibilityType,
    inConversionNode: string | undefined = undefined,
  ) {
    this.type = inType;
    this.conversionNode = inConversionNode;
  }
}

export class AbstractType {
  // cursed
  onNodeAdded(node: PPNode) {}
  drawValueSpecificGraphics(graphics: PIXI.Graphics, data: any) {}
  async onDataSet(data: any, socket: Socket): Promise<void> {
    return;
  }

  // override any and all of these in child classes
  getName(): string {
    return this.constructor.name;
  }
  toString(data: any): string {
    return this.getComment(data);
  }

  // optional, used to give extra information that should be written at all times next to the sockets, keep it short
  getMetaText(data: any): string {
    return '';
  }

  getComment(data: any): string {
    if (data !== undefined) {
      return inspect(data, null, 1);
    }
    return 'null';
  }

  getInputWidget = (props: DataTypeProps): any => {
    props.dataType = this;
    return <CodeWidget {...props} />;
  };

  getOutputWidget = (props: DataTypeProps): any => {
    props.dataType = this;
    return <DefaultOutputWidget {...props} />;
  };

  getDefaultWidgetProps() {
    return dataTypeWidgetDefaultProps;
  }

  getInputWidgetProps(): any {
    return this.getDefaultWidgetProps();
  }

  getOutputWidgetProps(): any {
    return this.getDefaultWidgetProps();
  }

  getDefaultValue(): any {
    return {};
  }

  getColor(): TRgba {
    return TRgba.fromString(SOCKET_COLOR_HEX);
  }

  parse(data: any): TParseType {
    return { value: data, warnings: [] };
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CONSTANT_String', 'WidgetRadio'];
  }

  recommendedOutputNodeWidgets(): string[] {
    return [];
  }

  allowedAsInput(): boolean {
    return true;
  }

  allowedAsOutput(): boolean {
    return true;
  }

  allowedToAutomaticallyAdapt(): boolean {
    return true;
  }

  roundedCorners(): boolean {
    return true;
  }

  // load the saved dataType on configure
  configureOnLoad(): boolean {
    return true;
  }

  prepareDataForSaving(data: any) {
    return data;
  }

  // call this from outside!!!!
  public getCompatability(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    if (data == undefined || data == null) {
      return new Compatibility(CompatibilityType.Incompatible); // dont bring such filth
    } else if (convertFrom.getName() == this.getName()) {
      return new Compatibility(CompatibilityType.Exact);
    } else {
      return this.dataIsCompatible(data, convertFrom);
    }
  }

  // override this in children!!!
  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return new Compatibility(CompatibilityType.Compatible);
  }

  public static warningsToCompatibility(warnings: SocketParsingWarning[]) {
    if (warnings.length == 0) {
      return new Compatibility(CompatibilityType.Compatible);
    } else {
      return new Compatibility(CompatibilityType.NeedDirectConversion);
    }
  }

  prefersToChangeAwayFromThisType(): boolean {
    return false;
  }

  protected drawSocket(graphics: PIXI.Graphics) {
    graphics.roundRect(
      0,
      0,
      SOCKET_WIDTH,
      SOCKET_WIDTH,
      !this.roundedCorners() ? 0 : SOCKET_CORNERRADIUS,
    );
  }

  public drawBox(
    errorBox: PIXI.Graphics,
    socketRef: PIXI.Graphics,
    location: PIXI.Point,
    isInput: boolean,
    status: PNPStatus,
  ) {
    errorBox.clear();
    if (status.getSeverity() >= STATUS_SEVERITY.WARNING) {
      const errorBoxWidth = SOCKET_WIDTH * 2 - SOCKET_WIDTH / 2;
      errorBox
        .roundRect(
          location.x +
            (isInput ? -errorBoxWidth - SOCKET_WIDTH / 4 : SOCKET_WIDTH / 4),
          -SOCKET_WIDTH / 4,
          errorBoxWidth,
          SOCKET_WIDTH + SOCKET_WIDTH / 2,
          0,
        )
        .fill({ color: status.getColor().hex() });
    }
    socketRef.x = location.x;
    socketRef.y = location.y;
    socketRef.pivot = new PIXI.Point(SOCKET_WIDTH / 2, SOCKET_WIDTH / 2);
    this.drawSocket(socketRef);
    socketRef.fill({ color: this.getColor().hexNumber() });

    socketRef.name = 'SocketRef';
    socketRef.eventMode = 'static';
  }
}
