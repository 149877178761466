/* eslint-disable */
import * as PIXI from 'pixi.js';
import { hri } from 'human-readable-ids';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import {
  CustomArgs,
  IWarningHandler,
  SerializedNode,
  SerializedSocket,
  TRgba,
  TNodeId,
  TNodeSource,
  TSocketType,
} from '../utils/interfaces';
import {
  COLOR_MAIN,
  COMMENT_TEXTSTYLE,
  NODE_TYPE_COLOR,
  NODE_CORNERRADIUS,
  NODE_HEADER_HEIGHT,
  NODE_HEADER_TEXTMARGIN_LEFT,
  NODE_HEADER_TEXTMARGIN_TOP,
  NODE_MARGIN,
  NODE_PADDING_BOTTOM,
  NODE_PADDING_TOP,
  NODE_SOURCE,
  NODE_TEXTSTYLE,
  NODE_WIDTH,
  ONCLICK_DOUBLECLICK,
  STATUS_SEVERITY,
  SOCKET_HEIGHT,
  SOCKET_TYPE,
  SOCKET_WIDTH,
  TEXT_RESOLUTION,
  SOCKET_TEXTSTYLE,
  SMALL_NODE_WIDTH,
  DEFAULT_UPDATE_FREQUENCY,
} from '../utils/constants';
import UpdateBehaviourClass from './UpdateBehaviourClass';
import NodeHeaderClass from './NodeHeaderClass';
import PPGraph from './GraphClass';
import Socket from './SocketClass';
import {
  calculateAspectRatioFit,
  perform_action_connectNodeToSocket,
  getNodeCommentPosX,
  getNodeCommentPosY,
  safeRemoveChildren,
} from '../utils/utils';
import { AbstractType, IsCompatible } from '../nodes/datatypes/abstractType';
import { AnyType } from '../nodes/datatypes/anyType';
import { TriggerType } from '../nodes/datatypes/triggerType';
import { deSerializeType } from '../nodes/datatypes/typehelper';
import FlowLogic from './FlowLogic';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { TextStyle } from 'pixi.js';
import {
  NodeConfigurationError,
  NodeExecutionError,
  PNPCustomStatus,
  PNPError,
  PNPStatus,
  PNPSuccess,
} from './ErrorClass';
import { Interaction } from './selection/SelectionClass';
import {
  CONSTANT_NAME,
  ENTIRE_OBJECT_NAME,
  INDEX_NAME,
} from '../nodes/datatypes/inputArrayKeysType';
import { PNPHitArea } from './selection/PNPHitArea';
import { drawExecutionFilter } from '../utils/visuals';
import { DropShadowFilter, GlowFilter, OutlineFilter } from 'pixi-filters';
import { getObjectsInsideBounds } from '../pixi/utils-pixi';
import { BackPropagation } from '../interfaces';

export default class PPNode extends PIXI.Container implements IWarningHandler {
  _NodeNameRef: PIXI.Text;
  _BackgroundRef: PIXI.Container;
  _NodeTextStringRef: PIXI.Text;
  _BackgroundGraphicsRef: PIXI.Graphics;
  _CommentRef: PIXI.Graphics;
  _StatusesRef: PIXI.Graphics;
  _ErrorBoundaryRef: PIXI.Graphics;
  _ForegroundRef: PIXI.Container;
  _SlowExecutionGraphics: PIXI.Graphics;

  _isHovering: boolean;

  id: TNodeId;
  type: string; // Type
  nodePosX: number;
  nodePosY: number;
  nodeWidth: number;
  nodeHeight: number;

  updateBehaviour: UpdateBehaviourClass;
  nodeSelectionHeader: NodeHeaderClass;
  lastTimeTicked = 0;
  lastExecutionTime = 0;
  lastRenderID = 0;

  status: { node: PNPStatus; socket: PNPStatus; custom: PNPStatus[] } = {
    node: new PNPSuccess(),
    socket: new PNPSuccess(),
    custom: [],
  };

  inputSocketArray: Socket[] = [];
  nodeTriggerSocketArray: Socket[] = [];
  outputSocketArray: Socket[] = [];

  listenId: string[] = [];

  debug_timesDrawn;
  debug_timesExecuted = 0;

  hasBeenAdded = false;
  hasBeenDrawn = false;

  executionFilter = undefined;
  outlineFilter = undefined;

  // for when user is hovering over the node
  hoverState = 0;
  hoverDirectionUp = false;

  // auto set values on first plug after placement, IF not coming from serialized
  hasAutoSetValues: boolean = false;
  shouldAutoSetValues: boolean = false;

  // a node cant execute multiple times overlapping!!
  isExecuting: boolean = false;
  isDrawingSlowExecutionGraphic: boolean = false;
  wantsToExecute: boolean = false;

  private executionListeners: Set<() => void> = new Set();

  // supported callbacks
  onNodeDoubleClick: (event: PIXI.FederatedPointerEvent) => void = () => {};
  onViewportMoveHandler: (event?: PIXI.FederatedPointerEvent) => void =
    () => {};
  onViewportPointerUpHandler: (event?: PIXI.FederatedPointerEvent) => void =
    () => {};
  onNodeRemoved: () => void = () => {}; // called when the node is removed from the graph
  onNodeResize: (width: number, height: number) => void = () => {}; // called when the node is resized

  // called when the node is added to the graph
  public async onNodeAdded(source: TNodeSource): Promise<void> {
    this._NodeTextStringRef = new PIXI.Text({
      text: this.getNodeTextString(),
      style: NODE_TEXTSTYLE,
      resolution: TEXT_RESOLUTION,
    });
    if (this.getIsSimpleStyleNode()) {
      this._NodeTextStringRef.x = this.nodeWidth / 2;
      this._NodeTextStringRef.y = this.nodeHeight / 2;
      this._NodeTextStringRef.anchor.x = 0.5;
      this._NodeTextStringRef.anchor.y = 0.5;
    } else {
      this._NodeTextStringRef.x = NODE_HEADER_TEXTMARGIN_LEFT;
      this._NodeTextStringRef.y = NODE_PADDING_TOP + NODE_HEADER_TEXTMARGIN_TOP;
    }
    //this._NodeTextStringRef.resolution = 8;

    this._BackgroundRef = new PIXI.Container();
    this.addChild(this._BackgroundRef);
    this._BackgroundRef.name = 'background';
    const backgroundGraphics = new PIXI.Graphics();
    this._BackgroundGraphicsRef =
      this._BackgroundRef.addChild(backgroundGraphics);
    this._BackgroundGraphicsRef.name = 'backgroundGraphics';

    this._NodeNameRef = this._BackgroundRef.addChild(this._NodeTextStringRef);
    this._CommentRef = this._BackgroundRef.addChild(new PIXI.Graphics());
    this._ErrorBoundaryRef = this._BackgroundRef.addChild(new PIXI.Graphics());
    this._StatusesRef = this._BackgroundRef.addChild(new PIXI.Graphics());

    // only get default updateBehaviour when newly added
    if (source !== NODE_SOURCE.SERIALIZED) {
      this.updateBehaviour = this.getUpdateBehaviour();
    }

    this.nodeSelectionHeader = new NodeHeaderClass();
    await this.nodeSelectionHeader.init();
    if (this.getShouldShowHoverActions()) {
      this._BackgroundRef.addChild(this.nodeSelectionHeader);
    }
    this.nodeSelectionHeader.x = NODE_MARGIN + this.nodeWidth - 96;
    this.nodeSelectionHeader.y = -24;

    // do not show the node name
    if (!this.getShowNodeNameLabel()) {
      this._NodeNameRef.alpha = 0;
    }

    this._ForegroundRef = new PIXI.Container();
    this.addChild(this._ForegroundRef);
    this._ForegroundRef.name = 'foreground';

    this.hasBeenAdded = true;
    this.getAllSockets().forEach((socket) => {
      this._BackgroundRef.addChild(socket);
      socket.onNodeAdded(this);
    });

    this.executionFilter = new GlowFilter({
      distance: 4,
      outerStrength: 0,
    });
    this.executionFilter.resolution = 2;
    //this.filters = [this.executionFilter];

    this.updateBehaviour.onNodeAdded();

    this.eventMode = 'dynamic';

    this._addListeners();
    this.resizeAndDraw();

    if (source === NODE_SOURCE.NEWCONNECTED) {
      this.shouldAutoSetValues = true;
    } else {
      this.shouldAutoSetValues = false;
    }

    if (
      source !== NODE_SOURCE.SERIALIZED &&
      source !== NODE_SOURCE.NEWCONNECTED &&
      (this.updateBehaviour.load || this.updateBehaviour.update) &&
      PPGraph.currentGraph.allowSelfExecution
    ) {
      await this.executeOptimizedChain();
    }
  }

  public getMinNodeWidth(): number {
    return NODE_WIDTH;
  }

  public getMinNodeHeight(): number {
    const base = this.headerHeight + NODE_PADDING_BOTTOM;
    let socketsHeight = 0;
    if (this.getParallelInputsOutputs()) {
      socketsHeight = Math.max(
        this.countOfVisibleInputSockets + this.countOfVisibleNodeTriggerSockets,
        this.countOfVisibleOutputSockets,
      );
    } else {
      socketsHeight =
        this.countOfVisibleInputSockets +
        this.countOfVisibleNodeTriggerSockets +
        this.countOfVisibleOutputSockets;
    }
    return base + socketsHeight * SOCKET_HEIGHT;
  }

  protected getAllInitialSockets(): Socket[] {
    return this.getDefaultIO();
  }

  public getNodeTextString(
    useMaxCharacters = true,
    usedName = this.getName(),
  ): [string, PIXI.TextStyleFontStyle] {
    let usedTextStyleFont: PIXI.TextStyleFontStyle = 'normal';

    if (
      this.name !== this.type &&
      this.getName() !== this.name &&
      this.name.length > 0
    ) {
      usedName = this.name;
      usedTextStyleFont = 'italic';
    }
    let maxCharacters = 10;
    try {
      maxCharacters = this.nodeWidth / (NODE_TEXTSTYLE.fontSize * 0.7); //0.7 is magic number, this is not exactly right for some reason, in text editor "this" is undefined when this is called, not sure why, TODO fix this (in text editor most likely)
    } catch (e) {
      console.warn(
        'Exception when trying to catch this.width in: ' + this.name,
      );
    }

    if (usedName.length > maxCharacters && useMaxCharacters) {
      usedName = usedName.substring(0, maxCharacters) + '...';
    }
    return [usedName, usedTextStyleFont];
  }

  constructor(type: string, customArgs?: CustomArgs) {
    super({ isRenderGroup: true });
    this.id = customArgs?.overrideId || hri.random();
    this.setNodeName(this.getName());
    this.type = type;
    this.nodeTriggerSocketArray = [];
    this.inputSocketArray = [];
    this.outputSocketArray = [];

    // customArgs
    this.x = customArgs?.nodePosX ?? 0;
    this.y = customArgs?.nodePosY ?? 0;
    this.nodeWidth = customArgs?.nodeWidth ?? this.getDefaultNodeWidth();
    this.nodeHeight = customArgs?.nodeHeight ?? this.getDefaultNodeHeight(); // if not set height is defined by in/out sockets
    this._isHovering = false;

    // add static inputs and outputs
    this.getAllInitialSockets().forEach((IO) => {
      // add in default data if supplied
      const newDefault = customArgs?.defaultArguments?.[IO.name];
      if (newDefault) {
        IO.data = newDefault;
      }
      this.addSocket(IO);
    });
    this.debug_timesDrawn = 0;
  }

  // GETTERS & SETTERS

  get selected(): boolean {
    return PPGraph.currentGraph.selection.isNodeSelected(this);
  }

  get isHovering(): boolean {
    return this._isHovering;
  }

  set isHovering(state: boolean) {
    this._isHovering = state;
  }

  get countOfVisibleNodeTriggerSockets(): number {
    return this.nodeTriggerSocketArray.filter((item) => item.visible).length;
  }

  get countOfVisibleInputSockets(): number {
    return this.inputSocketArray.filter((item) => item.visible).length;
  }

  get countOfVisibleOutputSockets(): number {
    return this.outputSocketArray.filter((item) => item.visible).length;
  }

  get headerHeight(): number {
    // hide header if !showLabels
    return this.getShowLabels() && !this.getIsSimpleStyleNode()
      ? NODE_PADDING_TOP + NODE_HEADER_HEIGHT
      : NODE_PADDING_TOP;
  }

  get nodeName(): string {
    return this.name;
  }

  public setNodeName(text: string) {
    this.name = text;
    if (this.hasBeenAdded) {
      const [text, fontStyle] = this.getNodeTextString();
      this._NodeNameRef.text = text;
      this._NodeNameRef.style.fontStyle = fontStyle;
    }
    this.nameChanged(text);
  }

  getSourceCode(): string {
    return this.constructor.toString();
  }

  // sockets that should go together with the dynamically made ones
  protected getDependentDynamicSockets(socketName: string): Socket[] {
    return [];
  }

  // useful bc of child classes wanting to get notification (dynamicinputnode)
  addDynamicSocket(socket: Socket): void {
    this.addSocket(socket);
    this.getDependentDynamicSockets(socket.name).forEach((dependent) => {
      dependent.dependentSocketName = socket.name;
      this.addSocket(dependent);
    });
  }

  addSocket(socket: Socket): void {
    if (this.hasBeenAdded) {
      this._BackgroundRef.addChild(socket);
      socket.onNodeAdded(this);
    }
    switch (socket.socketType) {
      case SOCKET_TYPE.TRIGGER: {
        this.nodeTriggerSocketArray.push(socket);
        break;
      }
      case SOCKET_TYPE.IN: {
        this.inputSocketArray.push(socket);
        break;
      }
      case SOCKET_TYPE.OUT: {
        this.outputSocketArray.push(socket);
        break;
      }
    }
    if (this.hasBeenAdded) {
      this.socketAdded();
    }
  }

  removeSocket(socket: Socket): void {
    if (socket == undefined) {
      return;
    }

    const checkAndRemoveFrom = (nameOfArrayToCheck: string): void => {
      this[nameOfArrayToCheck] = this[nameOfArrayToCheck].filter(
        (socketRef: Socket) =>
          !(
            socketRef.name === socket.name &&
            socketRef.socketType === socket.socketType
          ),
      );
    };

    if (socket.hasLink()) {
      socket.removeLink(undefined, false);
    }

    const socketName = socket.name;

    //remove from arrays
    checkAndRemoveFrom('nodeTriggerSocketArray');
    checkAndRemoveFrom('inputSocketArray');
    checkAndRemoveFrom('outputSocketArray');
    if (this.getShrinkOnSocketRemove()) {
      this.resizeAndDraw(0, 0);
    }

    const allSockets = this.getAllSockets();
    allSockets.forEach((otherSocket) =>
      otherSocket.nodeSocketRemoved(socketName),
    );

    socket.destroy();
    this.socketRemoved();
  }

  addTrigger(
    name: string,
    type: AbstractType,
    data?: unknown,
    visible?: boolean,
    custom?: Record<string, any>,
    redraw = true,
  ): void {
    this.addSocket(
      new Socket(SOCKET_TYPE.TRIGGER, name, type, data, visible, custom),
    );
    // redraw background due to size change
    if (redraw) {
      this.resizeAndDraw();
    }
  }

  addInput(
    name: string,
    type: AbstractType,
    data?: unknown,
    visible?: boolean,
    custom?: Record<string, any>,
    redraw = true,
  ): void {
    this.addSocket(
      new Socket(SOCKET_TYPE.IN, name, type, data, visible, custom),
    );
    // redraw background due to size change
    if (redraw) {
      this.resizeAndDraw();
    }
  }

  addOutput(
    name: string,
    type: AbstractType,
    visible?: boolean,
    redraw = true,
  ): void {
    this.addSocket(
      new Socket(SOCKET_TYPE.OUT, name, type, type.getDefaultValue(), visible),
    );
    // redraw background due to size change
    if (redraw) {
      this.resizeAndDraw();
    }
  }

  serialize(): SerializedNode {
    //create serialization object
    const node: SerializedNode = {
      id: this.id,
      name: this.name == this.getName() ? undefined : this.name,
      type: this.type,
      x: Math.round(this.x), // round to save space
      y: Math.round(this.y),
      width: Math.round(this.nodeWidth),
      height: Math.round(this.nodeHeight),
      socketArray: this.getAllSockets().map((socket) => socket.serialize()),
      updateBehaviour: {
        load: this.updateBehaviour.load,
        update: this.updateBehaviour.update,
        interval: this.updateBehaviour.interval,
        intervalFrequency:
          this.updateBehaviour.intervalFrequency == DEFAULT_UPDATE_FREQUENCY
            ? undefined
            : this.updateBehaviour.intervalFrequency,
      },
      version: this.getVersion() === 1 ? undefined : this.getVersion(), // we only bother saving if version is different from 1
    };

    return node;
  }
  mapSocket = (item: SerializedSocket) => {
    let matchingSocket = this.getSocketByNameAndType(
      item.name,
      item.socketType,
    );
    let addingSocket = false;
    item.socketType =
      item.socketType == undefined ? SOCKET_TYPE.IN : item.socketType;
    item.visible = item.visible == undefined ? true : item.visible;
    if (matchingSocket === undefined) {
      matchingSocket = new Socket(
        item.socketType,
        item.name,
        deSerializeType(item.dataType),
        item.data,
        item.visible,
      );
      addingSocket = true;
    }
    // ignore output sockets as no data is stored for them
    if (item.socketType !== SOCKET_TYPE.OUT) {
      matchingSocket.data = item.data;
      matchingSocket.defaultData = item.data;
    }
    const dataType = deSerializeType(item.dataType);
    if (dataType.configureOnLoad()) {
      matchingSocket.dataType = deSerializeType(item.dataType);
    }
    matchingSocket.visible = item.visible;
    matchingSocket.existOnlyOnLink =
      item.existOnlyIfLink == undefined ? false : item.existOnlyIfLink;
    matchingSocket.dependentSocketName =
      item.dependentSocketName == undefined ? '' : item.dependentSocketName;

    if (addingSocket) {
      this.addSocket(matchingSocket);
    }
  };

  // Remember, this is called before the node is added, so no visual operations needed
  async configure(
    nodeConfig: SerializedNode,
    includeSocketData = true,
  ): Promise<void> {
    this.x = nodeConfig.x;
    this.y = nodeConfig.y;
    this.nodeWidth = nodeConfig.width || this.getMinNodeWidth();
    this.nodeHeight = nodeConfig.height || this.getMinNodeHeight();
    this.setNodeName(
      nodeConfig.name == undefined ? this.getName() : nodeConfig.name,
    );
    this.updateBehaviour = new UpdateBehaviourClass(
      nodeConfig.updateBehaviour.load ?? false,
      nodeConfig.updateBehaviour.update,
      nodeConfig.updateBehaviour.interval,
      nodeConfig.updateBehaviour.intervalFrequency ?? DEFAULT_UPDATE_FREQUENCY,
      this,
    );
    if (includeSocketData) {
      try {
        // ugly - filter out old "Meta" sockets YOU CANNOT HAVE SOCKETS CALLED META AS LONG AS WE HAVE THIS HERE
        const sockets = nodeConfig.socketArray.filter(
          (socket) => socket.name !== 'Meta',
        );
        sockets.forEach((item) => this.mapSocket(item));
      } catch (error) {
        this.setStatus(new NodeConfigurationError(error));
        console.error(
          `Could not configure node: ${this.name}(${this.id})`,
          error,
        );
      }
    }
  }

  public addExecutionListener(listener: () => void): void {
    this.executionListeners.add(listener);
  }

  public removeExecutionListener(listener: () => void): void {
    this.executionListeners.delete(listener);
  }

  private notifyExecutionListeners(): void {
    this.executionListeners.forEach((listener) => listener());
  }

  public getDirectDependents(onlyInputs = true): { [key: string]: PPNode } {
    const currDependents: { [key: string]: PPNode } = {};
    this.outputSocketArray.forEach((socket) => {
      Object.values(socket.getDirectDependents(onlyInputs)).forEach(
        (dependent) => {
          currDependents[dependent.id] = dependent;
        },
      );
    });
    return currDependents;
  }

  public getHasDependencies(): boolean {
    return (
      this.getAllInputSockets().find((socket) => socket.hasLink()) !== undefined
    );
  }

  async executeOptimizedChain(): Promise<void> {
    //if (PPGraph.currentGraph.allowSelfExecution) {
    await FlowLogic.executeOptimizedChainBatch([this]);
    //}
  }

  // for when you dont want to execute your own node (you probably already did), but run all children that react to updates
  async executeChildren(): Promise<void> {
    await FlowLogic.executeOptimizedChainBatch(
      Object.values(this.getDirectDependents()).filter(
        (node) => node.updateBehaviour.update,
      ),
      this.id,
    );
  }

  public setPosition(x: number, y: number, isRelative = false): void {
    if (isRelative) {
      this.x = this.x + (x ?? 0);
      this.y = this.y + (y ?? 0);
    } else {
      this.x = x ?? this.x;
      this.y = y ?? this.y;
    }

    this.updateConnectionPosition();

    this.onViewportMove();
  }

  onBeingScaled(
    width: number = this.nodeWidth,
    height: number = this.nodeHeight,
    maintainAspectRatio = false,
  ): void {
    this.resizeAndDraw(width, height, maintainAspectRatio);
  }

  resizeAndDraw(
    width: number = this.nodeWidth,
    height: number = this.nodeHeight,
    maintainAspectRatio = false,
  ): void {
    // set new size
    let newNodeWidth = Math.max(width, this.getMinNodeWidth());
    let newNodeHeight = Math.max(height, this.getMinNodeHeight());

    if (maintainAspectRatio) {
      const oldWidth = this.nodeWidth;
      const oldHeight = this.nodeHeight;
      const newRect = calculateAspectRatioFit(
        oldWidth,
        oldHeight,
        newNodeWidth,
        newNodeHeight,
        this.getMinNodeWidth(),
        this.getMinNodeHeight(),
      );
      newNodeWidth = newRect.width;
      newNodeHeight = newRect.height;
    }

    this.nodeHeight = newNodeHeight;
    this.nodeWidth = newNodeWidth;
    if (this.getIsSimpleStyleNode()) {
      this._NodeTextStringRef.x = this.nodeWidth / 2;
      this._NodeTextStringRef.y = this.nodeHeight / 2;
    }
    // update node shape
    this.drawNodeShape();

    this.updateConnectionPosition();

    this.nodeSelectionHeader.x = NODE_MARGIN + this.nodeWidth - 96;

    this.onNodeResize(this.nodeWidth, this.nodeHeight);

    if (this.selected) {
      PPGraph.currentGraph.selection.drawRectanglesFromSelection();
    }
  }

  public resetSize(): void {
    this.resizeAndDraw(this.getDefaultNodeWidth(), this.getDefaultNodeHeight());
  }

  // get all sockets that are not part of the base kit for the node
  // do not include trigger sockets regardless of if they are default or not
  public getAllNonDefaultInputSockets(): Socket[] {
    const defaultIONames = this.getAllInitialSockets()
      .filter((socket) => socket.isInput())
      .map((socket) => socket.name);
    const nonDefault = this.inputSocketArray.filter(
      (socket) => !defaultIONames.includes(socket.name),
    );
    return nonDefault;
  }

  // this is a little bit ugly, but some nodes really want to look through all input sockets, and exclude something like the "Meta" one
  public getAllInterestingInputSockets(): Socket[] {
    return this.inputSocketArray;
  }

  public getAllInputSockets(): Socket[] {
    return this.inputSocketArray.concat(this.nodeTriggerSocketArray);
  }

  // includes sockets which are dynamically added. See: DynamicInputNode
  public hasInputSockets(): boolean {
    return this.inputSocketArray.length > 0;
  }

  getDataSockets(): Socket[] {
    return this.inputSocketArray.concat(this.outputSocketArray);
  }

  getAllSockets(): Socket[] {
    return this.inputSocketArray.concat(
      this.nodeTriggerSocketArray,
      this.outputSocketArray,
    );
  }

  getNodeTriggerSocketByName(slotName: string): Socket {
    return this.nodeTriggerSocketArray.find((el) => el.name === slotName);
  }

  getInputSocketByName(slotName: string): Socket {
    return this.inputSocketArray.find((el) => el.name === slotName);
  }

  getInputOrTriggerSocketByName(
    slotName: string,
    createIfNotExisting = true,
  ): Socket {
    const found = this.getAllInputSockets().find((el) => el.name === slotName);
    if (found === undefined && createIfNotExisting) {
      // create new socket for this ask, maybe this is a bit ugly
      console.log(
        'creating new socket because someone is trying to get a socket that didnt exist: ' +
          slotName,
      );
      const newSocket = new Socket(SOCKET_TYPE.IN, slotName, new AnyType());
      this.addSocket(newSocket);
      this.resizeAndDraw();
      return newSocket;
    } else {
      return found;
    }
  }

  getOutputSocketByName(slotName: string): Socket {
    return this.outputSocketArray.find((el) => el.name === slotName);
  }

  public getSocketByName(name: string): Socket {
    return this.getAllSockets().find((socket) => socket.name === name);
  }

  public getSocketByNameAndType(name: string, socketType: TSocketType): Socket {
    switch (socketType) {
      case SOCKET_TYPE.TRIGGER: {
        return this.getNodeTriggerSocketByName(name);
      }
      case SOCKET_TYPE.IN: {
        return this.getInputSocketByName(name);
      }
      case SOCKET_TYPE.OUT: {
        return this.getOutputSocketByName(name);
      }
      default:
        return;
    }
  }

  public drawErrorBoundary(): void {
    if (!this.hasBeenAdded) {
      return;
    }

    this._ErrorBoundaryRef.clear();
    if (
      this.status.node.getSeverity() >= STATUS_SEVERITY.WARNING ||
      this.status.socket.getSeverity() >= STATUS_SEVERITY.WARNING
    ) {
      const status =
        this.status.node.getSeverity() >= STATUS_SEVERITY.WARNING
          ? this.status.node
          : this.status.socket;

      this._ErrorBoundaryRef
        .roundRect(
          NODE_MARGIN - 3,
          -3,
          this.nodeWidth + 6,
          this.nodeHeight + 6,
          this.getRoundedCorners() ? NODE_CORNERRADIUS + 3 : 0,
        )
        .stroke({
          width: 3,
          color: status.getColor().hexNumber(),
          alpha: 1,
        });
    }
  }

  public drawBackground(): void {
    this._BackgroundGraphicsRef
      .roundRect(
        NODE_MARGIN,
        0,
        this.nodeWidth,
        this.nodeHeight,
        this.getRoundedCorners() ? NODE_CORNERRADIUS : 0,
      )
      .fill({
        color: this.getColor().hexNumber(),
        alpha: this.getOpacity(),
      });
  }

  public drawTriggers(): void {
    this.nodeTriggerSocketArray
      .filter((item) => item.visible)
      .forEach((item, index) => {
        item.y = this.headerHeight + index * SOCKET_HEIGHT;
        item.redraw();
      });
  }

  public drawSockets(): void {
    const triggerHeight = this.countOfVisibleNodeTriggerSockets * SOCKET_HEIGHT;
    const outputHeight = this.countOfVisibleOutputSockets * SOCKET_HEIGHT;
    // redraw outputs
    this.outputSocketArray
      .filter((item) => item.visible)
      .forEach((item, index) => {
        item.y = this.headerHeight + triggerHeight + index * SOCKET_HEIGHT;
        item.redraw();
      });

    // redraw inputs
    this.inputSocketArray
      .filter((item) => item.visible)
      .forEach((item, index) => {
        item.y =
          this.headerHeight +
          triggerHeight +
          (!this.getParallelInputsOutputs() ? outputHeight : 0) +
          index * SOCKET_HEIGHT;
        item.redraw();
      });
  }

  protected drawStatuses(): void {
    if (!this.hasBeenAdded) {
      return;
    }

    this._StatusesRef.clear();
    safeRemoveChildren(this._StatusesRef);

    let flattenedStatus = [];
    for (const key in this.status) {
      if (Array.isArray(this.status[key])) {
        flattenedStatus = this.status[key].concat(flattenedStatus);
      } else if (this.status[key].getSeverity() >= STATUS_SEVERITY.WARNING) {
        flattenedStatus.push(this.status[key]);
      }
    }

    const padding = 5;
    let startY = this.countOfVisibleOutputSockets * SOCKET_HEIGHT + 40;
    const startX = this.nodeWidth - 60;

    flattenedStatus.forEach((nStatus, index) => {
      const color = nStatus.getColor();

      let shortenedMessage = nStatus.message;
      const lines = nStatus.message.split('\n');
      const maxLines = 3;
      if (lines.length > maxLines) {
        shortenedMessage = lines.slice(0, maxLines).join('\n');
      }

      const text = new PIXI.Text({
        text: shortenedMessage,
        style: new TextStyle({
          fontSize: 18,
          fill: COLOR_MAIN,
        }),
      });
      text.x = startX + padding;
      text.y = startY + padding;
      this._StatusesRef.addChild(text);
      this._StatusesRef
        .roundRect(
          startX,
          startY,
          text.width + padding * 2,
          text.height + padding * 2,
          nStatus.getSeverity() >= STATUS_SEVERITY.WARNING
            ? 0
            : NODE_CORNERRADIUS,
        )
        .fill(color.hexNumber());
      startY += text.height + padding;
    });
  }

  protected getHitArea(): PNPHitArea {
    let rect = new PIXI.Rectangle(0, 0, this.nodeWidth, this.nodeHeight);
    rect = PPNode.boundsToSelectionBounds(rect);
    return new PNPHitArea((x, y) => rect.contains(x, y));
  }

  public getForegroundDimensions(): { width: number; height: number } {
    if (!this._ForegroundRef) {
      return { width: 0, height: 0 };
    }

    const bounds = this._ForegroundRef.getLocalBounds();
    return {
      width: Math.ceil(bounds.width),
      height: Math.ceil(bounds.height),
    };
  }

  THROTTLE_DEBOUNCE_DRAWING_MIN_TIME = 100;

  private animationFrameRedraw() {
    cancelAnimationFrame(this.lastRenderID);
    this.lastRenderID = requestAnimationFrame(() => {
      if (!this.destroyed) {
        this.drawNodeShape();
      }
    });
  }

  protected debounceDrawShape = debounce(
    this.animationFrameRedraw,
    this.THROTTLE_DEBOUNCE_DRAWING_MIN_TIME,
  );

  protected throttleDrawShape = throttle(
    this.animationFrameRedraw,
    this.THROTTLE_DEBOUNCE_DRAWING_MIN_TIME,
    { trailing: true, leading: true },
  );

  public drawNodeShape(): void {
    if (!this.hasBeenAdded) {
      return;
    }
    this.debug_timesDrawn += 1;
    //console.log('drawing node shape: ' + this.name);
    //console.trace();
    // update selection

    this._BackgroundGraphicsRef.clear();
    this.drawErrorBoundary();
    this.drawBackground();

    this.drawTriggers();
    this.drawSockets();
    this.drawDebugInfo();
    this.drawStatuses();
    this._NodeTextStringRef.text = this.getNodeTextString()[0];
    this.hasBeenDrawn = true;

    this.hitArea = this.getHitArea();
  }

  constructSocketName(prefix: string, existing: Socket[]): string {
    let count = 1;
    let newName = prefix + ' ' + count;
    while (existing.find((socket) => socket.name === newName)) {
      newName = prefix + ' ' + count++;
    }
    return newName;
  }

  public addDefaultTrigger(): void {
    this.addTrigger(
      this.constructSocketName('Trigger', this.nodeTriggerSocketArray),
      new TriggerType(),
    );
  }

  public addDefaultOutput(): void {
    this.addOutput(
      this.constructSocketName('Custom Output', this.outputSocketArray),
      new AnyType(),
    );
  }

  updateConnectionPosition(): void {
    // check for connections and move them too
    this.getAllSockets().forEach((socket) => {
      socket.links.forEach((link) => {
        link.updateConnection();
      });
    });
  }

  public setStatus(status: PNPStatus, type: 'node' | 'socket' = 'node') {
    const currentMessage = JSON.stringify(this.status[type].message);
    const newMessage = JSON.stringify(status.message);
    if (currentMessage !== newMessage) {
      this.status[type] = status;
      this.drawStatuses();
      this.drawErrorBoundary();
    }
  }

  public pushExclusiveCustomStatus(status: PNPStatus) {
    this.status.custom = [];
    this.status.custom.push(status);
  }

  adaptToSocketErrors(): void {
    const hasWarningsOrErrors = this.getAllSockets().some(
      (socket) => socket.status.getSeverity() >= STATUS_SEVERITY.WARNING,
    );
    if (!hasWarningsOrErrors) {
      this.setStatus(new PNPSuccess(), 'socket');
      this.drawStatuses();
      this.drawErrorBoundary();
    }
  }

  drawDebugInfo(): void {
    if (!this.hasBeenAdded) {
      return;
    }

    this._CommentRef.removeChildren();
    if (PPGraph.currentGraph._showDebugInfo) {
      const bounds = this.getLocalBounds();
      const debugText = new PIXI.Text({
        text: `${this.id}
${Math.round(this.position.x)}, ${Math.round(this.position.y)}
${Math.round(bounds.minX)}, ${Math.round(
          bounds.minY,
        )}, ${Math.round(bounds.maxX)}, ${Math.round(bounds.maxY)}, Execution time: ${this.lastExecutionTime}, `,
        style: COMMENT_TEXTSTYLE,
      });
      debugText.resolution = 1;

      debugText.x = getNodeCommentPosX(this.width);
      debugText.y = getNodeCommentPosY() - 48;

      this._CommentRef.addChild(debugText);
    }
  }

  screenPointBackgroundRectTopLeft(): PIXI.Point {
    return PPGraph.currentGraph.viewport.toScreen(this.x + NODE_MARGIN, this.y);
  }

  screenPointBackgroundRectCenter(): PIXI.Point {
    return PPGraph.currentGraph.viewport.toScreen(
      this.x + NODE_MARGIN + this._BackgroundGraphicsRef.width / 2,
      this.y + this._BackgroundGraphicsRef.height / 2,
    );
  }

  // avoid calling this directly when possible, instead use the input/output objects in onExecute and keep it encapsulated in that flow (not always possible but most of the time is)
  public setInputData(name: string, data: any): void {
    const inputSocket = this.inputSocketArray.find((input: Socket) => {
      return name === input.name;
    });

    if (!inputSocket) {
      console.error('No input socket found with the name: ', name);
      return;
    }

    inputSocket.data = data;
  }

  private static getArrayData(array: Socket[], name: string) {
    const socket = array.find((input: Socket) => {
      return name === input.name;
    });

    if (!socket) {
      return undefined;
    }

    return socket.data;
  }

  // avoid calling this directly when possible
  public getInputData(name: string): any {
    return PPNode.getArrayData(this.inputSocketArray, name);
  }

  // avoid calling this directly when possible
  public getOutputData(name: string): any {
    return PPNode.getArrayData(this.outputSocketArray, name);
  }

  // avoid calling this directly if possible, instead use the input/output objects in onExecute
  public setOutputData(name: string, data: any): void {
    const outputSocket = this.outputSocketArray.find((output: Socket) => {
      return name === output.name;
    });
    if (outputSocket) {
      outputSocket.data = data;
    } else {
      console.warn('Failed to set socket data on socket: ' + name);
    }
  }

  async tick(currentTime: number, deltaTime: number): Promise<void> {
    if (
      this.updateBehaviour?.interval &&
      currentTime - this.lastTimeTicked >=
        this.updateBehaviour?.intervalFrequency
    ) {
      this.lastTimeTicked = currentTime;
      await this.executeOptimizedChain();
    }
  }

  static remapInput(sockets: Socket[]): any {
    const inputObject = {};
    sockets.forEach((input: Socket) => {
      inputObject[input.name] = input.data;
    });
    return inputObject;
  }

  // if you want to optimize the mapping of arguments, override this function instead of execute(), but most of the time just override onExecute()
  protected async rawExecute(): Promise<void> {
    if (!this.hasBeenAdded) {
      return;
    }

    this.debug_timesExecuted++;
    // remap input
    const inputObject = PPNode.remapInput(this.inputSocketArray);
    const outputObject = {};

    await this.onExecute(inputObject, outputObject);

    // output whatever the user has put in
    this.outputSocketArray.forEach((output: Socket) => {
      if (outputObject[output.name] !== undefined) {
        output.data = outputObject[output.name];
      }
    });
  }

  TIME_BEFORE_SLOW_EXECUTION_SHOWS = 500;

  public renderSlowExecutionDebounce = debounce(async () => {
    await this.renderSlowExecutionGraphic();
  }, this.TIME_BEFORE_SLOW_EXECUTION_SHOWS);

  public renderSlowExecutionGraphic = async () => {
    if (!this.destroyed && this.isExecuting){
      this.isDrawingSlowExecutionGraphic = true;
      if (this._SlowExecutionGraphics == undefined){
        this._SlowExecutionGraphics = new PIXI.Graphics();
        const radius = 30;
        let angle = 0;
        const segments = 15;
        for (let i = 0; i < segments; i++){
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;
          this._SlowExecutionGraphics.circle(x, y, 5);
          angle += 2 * Math.PI / segments;
        }
        this._SlowExecutionGraphics.fill({color: 0xffffff, alpha: 0.7});
        this._SlowExecutionGraphics.y = -50;
        this._SlowExecutionGraphics.x = this.nodeWidth / 2;
      }
      this.addChild(this._SlowExecutionGraphics);
      const start = Date.now();
      const timeUntilMaxAlpha = 1000;
      while (this.isDrawingSlowExecutionGraphic){
        const curr = Date.now() - start;
        this._SlowExecutionGraphics.rotation = (curr - start) / 500;
        this._SlowExecutionGraphics.alpha = Math.min(1, Math.max(0, curr / timeUntilMaxAlpha));
        await new Promise(resolve => setTimeout(resolve, 16));
      }
      this.removeChild(this._SlowExecutionGraphics);
    }
  }
  public removeSlowExecutionGraphic() {
    if (this._SlowExecutionGraphics !== undefined){
      this.isDrawingSlowExecutionGraphic = false;
      this.removeChild(this._SlowExecutionGraphics);
    }

  }

  public renderOutlineThrottled = throttle(this.drawExecutionVisualizer, 500, {
    trailing: false,
    leading: true,
  });

  private async drawExecutionVisualizer(): Promise<void> {
    if (!this.hasBeenAdded || this.destroyed) {
      return;
    }
    await drawExecutionFilter(
      this.executionFilter,
      this._BackgroundGraphicsRef,
    );
  }

  // Don't call this from outside unless you know very well what you are doing, you are probably looking for executeOptimizedChain()
  public async execute(): Promise<void> {
    this.setStatus(new PNPSuccess());
    if (this.isExecuting) {
      this.wantsToExecute = true;
      return;
    }
    this.isExecuting = true;
    try {
      if (PPGraph.currentGraph.showExecutionVisualisation && this.isVisible()) {
        this.renderOutlineThrottled();
        this.renderSlowExecutionDebounce();
      }
      const pre = Date.now();
      await this.rawExecute();
      const post = Date.now();
      this.lastExecutionTime = post - pre;
      if (PPGraph.currentGraph.showExecutionVisualisation && this.isVisible()) {
        this.outputSocketArray.forEach((socket) => {
          socket.links.forEach((link) => link.renderOutlineThrottled());
        });
      }
      
      // Notify listeners after execution
      this.notifyExecutionListeners();

      this.drawDebugInfo();
      this.isExecuting = false;
      this.removeSlowExecutionGraphic();
    } catch (error) {
      const errorText = error?.stack == undefined ? error : error.stack;
      this.isExecuting = false;
      if (error instanceof PNPError) {
        this.setStatus(error);
      } else {
        this.setStatus(new NodeExecutionError(errorText));
      }
      console.warn(
        `Node ${this.name}(${this.id}) execution error:  ${errorText}`,
      );

      // set default data on output sockets, dont let data just linger around
      this.outputSocketArray.forEach(
        (socket) => (socket.data = socket.dataType.getDefaultValue()),
      );
    }
    // previous run was cancelled - pick it up here and run again
    if (this.wantsToExecute) {
      this.wantsToExecute = false;
      console.log('Picking up execution desired from before: ' + this.name);
      await this.execute();
    }
  }

  // helper function for nodes who want execution to just be a passthrough
  protected async passThrough(input, output): Promise<void> {
    Object.keys(input).forEach((key) => {
      output[key] = input[key];
    });
  }

  // SETUP

  _addListeners(): void {
    this.addEventListener('pointerdown', this.onPointerDown.bind(this));
    this.addEventListener('pointerup', this.onPointerUp.bind(this));
    this.addEventListener('pointerover', this.onPointerOver.bind(this));
    this.addEventListener('pointerout', this.onPointerOut.bind(this));
    this.addEventListener('click', this.onPointerClick.bind(this));
    this.addEventListener('removed', this.onRemoved.bind(this));

    this.onViewportPointerUpHandler = this.onViewportPointerUp.bind(this);
    this.onViewportMoveHandler = this.onViewportMove.bind(this);
    PPGraph.currentGraph.viewport.addEventListener(
      'moved',
      (this as any).onViewportMoveHandler,
    );
  }

  async onPointerDown(event: PIXI.FederatedPointerEvent): Promise<void> {
    console.log('Node: onPointerDown');
    InterfaceController.spamToast(
      `${event.shiftKey ? 'node_shift_clicked' : 'node_clicked'} ${this.id}`,
    );
    event.stopPropagation();
    const eventTarget = event.target;
    PPGraph.currentGraph.viewport.plugins.resume('mouse-edges');

    if (eventTarget == this) {
      if (event.shiftKey) {
        PPGraph.currentGraph.selection.selectNodes([this], true);
      } else if (PPGraph.currentGraph.overInputRef != undefined) {
        // this clause is a bit hacky, it happened for me under some edge cases where i would drag the selected node (macro in my case) instead of dragging socket connection
        PPGraph.currentGraph.overInputRef.onSocketPointerDown(event);
      } else {
        // start dragging the node
        PPGraph.currentGraph.selection.selectNodes([this], false);
        PPGraph.currentGraph.selection.onPointerDown(event);
      }
    }
    if (event.button == 2) {
      if (event.target == this) {
        InterfaceController.onRightClick(event, this);
      }
      PPGraph.currentGraph.selection.stopDragAction(event);
    }
  }

  public getNewSocketName(
    preferredName: string,
    existingSockets: Socket[] = this.inputSocketArray,
  ): string {
    const existing = this.getAllInputSockets();
    let newParamName = preferredName;
    let count: number = 2;
    // find a new param name that is unique
    while (existing.find((param) => param.name === newParamName)) {
      newParamName = preferredName + ' ' + count;
      count += 1;
    }
    return newParamName;
  }

  private static calculateCompatibility(
    otherSocket: Socket,
    preferredSocketName: string,
    socket: Socket,
  ) {
    const isPreferred = socket.name === preferredSocketName;
    const isVisible = socket.visible;
    const hasLink = socket.hasLink();
    const compatibility = socket.dataType.getCompatability(
      otherSocket.data,
      otherSocket.dataType,
    ).type;
    return (
      (isPreferred ? 1000 : 0) +
      (isVisible ? 100 : 0) +
      (hasLink ? 0 : 1) -
      compatibility
    );
  }

  public getSocketForNewConnection(socket: Socket): Socket {
    const socketArray = socket.isInput()
      ? this.outputSocketArray
      : this.inputSocketArray;
    const preferredSocketName = socket.isInput()
      ? this.getPreferredOutputSocketName()
      : this.getPreferredInputSocketName();
    if (socketArray.length > 0) {
      // get best match first, then others
      const sortedMatchQuality = socketArray.sort((s1: Socket, s2: Socket) => {
        return (
          PPNode.calculateCompatibility(socket, preferredSocketName, s2) -
          PPNode.calculateCompatibility(socket, preferredSocketName, s1)
        );
      });

      // make sure the best match is not incompatible
      if (
        IsCompatible(
          sortedMatchQuality[0].dataType.getCompatability(
            socket.data,
            socket.dataType,
          ).type,
        )
      ) {
        return sortedMatchQuality[0];
      }
    }

    InterfaceController.showSnackBar(
      'Failed to connect socket to node, no sockets on target or bad input data',
    );
    return undefined;
  }

  protected async mouseReleasedOverWithSourceSocketSelected(
    source: Socket,
  ): Promise<void> {
    await perform_action_connectNodeToSocket(source, this);
  }

  onPointerUp(event: PIXI.FederatedPointerEvent): void {
    PPGraph.currentGraph.viewport.plugins.pause('mouse-edges');

    const source = PPGraph.currentGraph.selectedSocket;
    const hoveredOver = PPGraph.currentGraph.overInputRef;
    if (hoveredOver) {
      hoveredOver.onPointerUp(event);
      return;
    }
    if (source && this !== source.getNode()) {
      PPGraph.currentGraph.selectedSocket = undefined; // hack // ????
      this.mouseReleasedOverWithSourceSocketSelected(source);
    }
    PPGraph.currentGraph.selection.stopDragAction(event);
  }

  protected onViewportMove(): void {}

  async onRemoved(): Promise<void> {
    // remove added listener from graph.viewport
    PPGraph.currentGraph.viewport.removeEventListener(
      'moved',
      this.onViewportMoveHandler,
    );

    // Clean up event listeners
    this.removeAllListeners();
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
    this.executionListeners.clear();

    // Clean up socket connections
    await Promise.all(
      this.getAllSockets().map(async (socket) => {
        await Promise.all(
          socket.links.map(async (link) => await link.delete()),
        );
      }),
    );

    // Clear references
    this.filters = null;
    this.executionFilter = null;
    this.outlineFilter = null;

    this.onNodeRemoved();
  }

  pointerOverMoving(): void {
    this.getAllSockets().forEach((socket) => socket.pointerOverSocketMoving());
  }

  OFFSET_TRANSLATION_ITERATION = 0.02;
  BLUR_CHANGE_ITERATION = 0.05;
  MAX_HOVER_CHANGE_ITERATIONS = 10;

  protected visualOffsetXY(x: number, y: number) {
    this.x += x;
    this.y += y;
  }

  private changeHoverState(increase: boolean) {
    if (this.destroyed) {
      return;
    }
    const curr = increase ? this.hoverState : this.hoverState - 1;

    const cosFactor = this.MAX_HOVER_CHANGE_ITERATIONS - curr;
    let changeXY = -this.OFFSET_TRANSLATION_ITERATION * cosFactor;
    let changeBlur = this.OFFSET_TRANSLATION_ITERATION * cosFactor;
    if (!increase) {
      changeXY = -changeXY;
      changeBlur = -changeBlur;
    }
    this.visualOffsetXY(changeXY, changeXY);
    this.outlineFilter.offset.x -= changeXY;
    this.outlineFilter.offset.y -= changeXY;

    this.outlineFilter.blur += changeBlur;
    this.outlineFilter.blur = Math.max(0, this.outlineFilter.blur);
    this.updateConnectionPosition();
    this.hoverState = Math.max(
      0,
      Math.min(
        this.MAX_HOVER_CHANGE_ITERATIONS,
        this.hoverState + (increase ? 1 : -1),
      ),
    );
  }

  private async selectionFilterIn() {
    this.hoverDirectionUp = true;
    if (!this.outlineFilter) {
      this.outlineFilter = new DropShadowFilter({
        offset: new PIXI.Point(0.0, 0.0),
        blur: this.BLUR_CHANGE_ITERATION,
      });
      this.outlineFilter.resolution = 2;
    }
    this.filters = [this.outlineFilter];
    while (
      this.hoverDirectionUp &&
      this.hoverState < this.MAX_HOVER_CHANGE_ITERATIONS &&
      !this.destroyed
    ) {
      this.changeHoverState(true);
      await new Promise((r) => setTimeout(r, 16));
    }
  }

  private async selectionFilterOut() {
    this.hoverDirectionUp = false;
    while (!this.hoverDirectionUp && this.hoverState > 0 && !this.destroyed) {
      this.changeHoverState(false);
      await new Promise((r) => setTimeout(r, 16));
    }
    if (!this.hoverDirectionUp && !this.destroyed) {
      this.filters = [];
      this.outlineFilter.offset.set(0, 0);
      this.outlineFilter.blur = this.BLUR_CHANGE_ITERATION;
      this.x = Math.round(this.x);
      this.y = Math.round(this.y);
    }
  }
  onPointerOver(): void {
    if (this.isHovering) {
      return;
    }
    this.isHovering = true;
    this.updateBehaviour.graphics.redrawAnythingChanging();
    this.nodeSelectionHeader.redrawAnythingChanging(true);
    this.addEventListener('pointermove', this.pointerOverMoving);
    this.selectionFilterIn();

    this.getAllSockets().forEach((socket) => socket.nodeHoveredOver());
  }

  onPointerOut(): void {
    this.isHovering = false;
    this.removeEventListener('pointermove', this.pointerOverMoving);
    this.updateBehaviour.graphics.redrawAnythingChanging();
    this.nodeSelectionHeader.redrawAnythingChanging(false);
    this.selectionFilterOut();

    this.getAllSockets().forEach((socket) => socket.nodeHoveredOut());
  }

  onPointerClick(event: PIXI.FederatedPointerEvent): void {
    // check if double clicked
    if (event.detail === ONCLICK_DOUBLECLICK) {
      //event.stopPropagation();
      this.listenId.push(
        InterfaceController.addListener(
          ListenEvent.EscapeKeyUsed,
          this.onViewportPointerUpHandler,
        ),
      );
      if (this.onNodeDoubleClick) {
        this.onNodeDoubleClick(event);
      }
    }
  }

  onViewportPointerUp(): void {
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
  }

  public hasSocketNameInDefaultIO(name: string, type: TSocketType): boolean {
    return (
      this.getAllInitialSockets().find(
        (socket) => socket.name == name && socket.socketType == type,
      ) !== undefined
    );
  }

  // mean to be overridden with custom behaviour

  public metaInfoChanged(): void {
    this.resizeAndDraw();
    this.updateConnectionPosition();
  }

  // This is the main one you'll want to override this in child classes
  protected async onExecute(input, output): Promise<void> {
    // just define function
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(
      false,
      true,
      false,
      DEFAULT_UPDATE_FREQUENCY,
      this,
    );
  }

  public allowResize(): boolean {
    return true;
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return false;
  }

  protected getDefaultIO(): Socket[] {
    return [];
  }

  public getShowNodeNameLabel(): boolean {
    return this.getShowLabels() || this.getIsSimpleStyleNode();
  }

  public getShowLabels(): boolean {
    return true;
  }

  public getIsSimpleStyleNode(): boolean {
    return false;
  }

  public getDefaultNodeWidth(): number {
    return this.getMinNodeWidth();
  }

  public getDefaultNodeHeight(): number {
    return this.getMinNodeHeight();
  }

  public getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.DEFAULT);
  }

  public getSocketDisplayName(socket: Socket): string {
    let usedName = socket.name;
    let maxCharacters = 10;
    try {
      maxCharacters = this.nodeWidth / (SOCKET_TEXTSTYLE.fontSize * 0.7); //0.7 is magic number, this is not exactly right for some reason, in text editor "this" is undefined when this is called, not sure why, TODO fix this (in text editor most likely)
    } catch (e) {
      console.warn(
        'Exception when trying to catch this.width in: ' + this.name,
      );
    }
    if (usedName.length > maxCharacters) {
      usedName = usedName.substring(0, maxCharacters) + '...';
    }
    return usedName;
  }

  public isLayoutable(): boolean {
    return false;
  }

  public reactsToCombineDrawKeyBinding(): boolean {
    return false;
  }

  // for hybrid/transparent nodes, set this value to 0.01, if set to 0, the node is not clickable/selectable anymore
  public getOpacity(): number {
    return 1;
  }

  public getCanAddInput(): boolean {
    return false;
  }

  public getShouldShowHoverActions(): boolean {
    return true;
  }

  public getParallelInputsOutputs(): boolean {
    return false;
  }

  public getRoundedCorners(): boolean {
    return true;
  }

  getPreferredInputSocketName(): string {
    return 'MyPreferredInputSocket';
  }

  getPreferredOutputSocketName(): string {
    return 'MyPreferredOutputSocket';
  }

  public getInputSocketXPos(): number {
    return 0;
  }
  public getOutputSocketXPos(): number {
    return this.nodeWidth;
  }

  public getShrinkOnSocketRemove(): boolean {
    return true;
  }

  public getAdditionalRightClickOptions(): any {
    return {};
  }

  public isCallingMacro(macroName: string): boolean {
    return false;
  }

  public calledMacroChangedName(oldName: string, newName: string): void {}

  public async calledMacroUpdated(): Promise<void> {
    if (
      this.updateBehaviour.update &&
      PPGraph.currentGraph.allowSelfExecution
    ) {
      await this.executeOptimizedChain();
    }
  }

  public calledMacroUpdatedMeta(): void {
    this.debounceDrawShape();
  }

  // we should migrate all nodes to use these functions instead of specifying the field themselves in constructor
  public getName(): string {
    return this.name;
  }

  public getDescription(): string {
    return '';
  }

  // displayed in the info tab and can contain HTML
  // not visible when searching nodes
  public getAdditionalDescription(): string {
    return '';
  }

  // enable if a node example graph exists on github
  public hasExample(): boolean {
    return false;
  }

  // used when searching for nodes
  public getTags(): string[] {
    return [];
  }

  public getPreferredNodesPerSocket(): Map<string, string[]> {
    return new Map();
  }

  // observers

  public socketTypeChanged(): void {}
  public nameChanged(newName: string): void {}
  public async inputPlugged(socket: Socket): Promise<void> {
    if (this.shouldAutoSetValues && !this.hasAutoSetValues) {
      this.autoSetDefaultValues();
      this.hasAutoSetValues = true;
    }
  }

  // just got pasted into the graph
  public async pasted() {
    this.inputSocketArray
      .map((socket) => socket.name)
      .map((name) =>
        this.getInputSocketByName(name)?.selfDestructIfNoLinkAndNeedOne(),
      );
  }

  public async inputUnplugged(socket: Socket): Promise<void> {
    socket.selfDestructIfNoLinkAndNeedOne();
  }
  public async outputPlugged(socket: Socket): Promise<void> {}
  public async outputUnplugged(socket: Socket): Promise<void> {}

  protected socketAdded(): void {}
  protected socketRemoved(): void {}

  // content here means that if an output gets plugged immediately we take that linked value and send it back to the input

  protected getBackPropagationTargets(): BackPropagation {
    return { SocketToGetValue: undefined, SocketToTakeName: undefined };
  }

  // incoming socket is always an input socket, but we are not connected to it yet
  public async populateDefaults(inputSocket: Socket): Promise<void> {
    console.assert(inputSocket.socketType == SOCKET_TYPE.IN);
    const propagationTarget = this.getBackPropagationTargets();
    if (propagationTarget.SocketToTakeName !== undefined) {
      propagationTarget.SocketToTakeName.data = inputSocket.name;
    }
    if (
      propagationTarget.SocketToGetValue !== undefined &&
      IsCompatible(
        propagationTarget.SocketToGetValue.dataType.getCompatability(
          inputSocket.data,
          inputSocket.dataType,
        ).type,
      )
    ) {
      propagationTarget.SocketToGetValue.data = inputSocket.data;
    }
  }

  // these are imported before node is added to the graph
  public getDynamicImports(): string[] {
    return [];
  }

  // hacky helper, was a problem with "Button" node specifically where we wanted the button to update the label
  public socketChangedFromWidget() {}

  static EXTRA_NODE_SELECTION_MARGIN = 26;

  protected static boundsToSelectionBounds(bounds: PIXI.Rectangle) {
    bounds.x -= PPNode.EXTRA_NODE_SELECTION_MARGIN - SOCKET_WIDTH / 2;
    bounds.y -= PPNode.EXTRA_NODE_SELECTION_MARGIN;
    bounds.width += PPNode.EXTRA_NODE_SELECTION_MARGIN * 2;
    bounds.height += PPNode.EXTRA_NODE_SELECTION_MARGIN * 2;
    return bounds;
  }

  // GLOBAL COORDINATES
  public getSelectionBounds(): PIXI.Rectangle[] {
    let bounds = new PIXI.Rectangle(
      this.x,
      this.y,
      this.nodeWidth,
      this.nodeHeight,
    );
    bounds = PPNode.boundsToSelectionBounds(bounds);
    return [bounds];
  }

  getInputKeyOptions(
    name: string,
    allowIndex = true,
    allowConstant = false,
  ): any {
    const inputArray: any[] = this.getInputData(name);
    let toReturn = [{ text: ENTIRE_OBJECT_NAME }];
    if (allowIndex) {
      toReturn.push({ text: INDEX_NAME });
    }
    if (allowConstant) {
      toReturn.push({ text: CONSTANT_NAME });
    }
    if (
      inputArray != undefined &&
      inputArray.length > 1 &&
      inputArray[0] != undefined &&
      typeof inputArray[0] === 'object'
    ) {
      toReturn = toReturn.concat(
        Object.keys(inputArray[0]).map((key) => ({ text: key })),
      );
    }
    return toReturn;
  }

  // if you make breaking changes to a node it is recommended up increment this and also handle migration in the migrate function
  public getVersion(): number {
    return 1;
  }

  public async migrate(previousVersion: number): Promise<void> {}
  // helper function for migration
  protected async replaceSocketWithOtherSocket(
    oldSocket: Socket,
    newSocket: Socket,
  ) {
    const links = oldSocket.links;
    if (oldSocket.socketType !== newSocket.socketType) {
      console.error(
        'Unable to replace an a socket with another of incompatible type',
      );
      return;
    }
    if (links.length) {
      if (oldSocket.socketType == SOCKET_TYPE.IN) {
        const prevSource = links[0].getSource();
        await PPGraph.currentGraph.linkConnect(
          prevSource.getNode().id,
          prevSource.name,
          this.id,
          newSocket.name,
          true,
        );
      } else if (oldSocket.socketType == SOCKET_TYPE.OUT) {
        for (let i = 0; i < links.length; i++) {
          const prevTarget = links[i].getTarget();
          await PPGraph.currentGraph.linkConnect(
            this.id,
            newSocket.name,
            prevTarget.getNode().id,
            prevTarget.name,
            true,
          );
        }
      }
      while (oldSocket.links.length) {
        await PPGraph.currentGraph.linkDisconnect(
          oldSocket.links[0].getTarget().getNode().id,
          oldSocket.links[0].getTarget().name,
          false,
        );
      }
    }
    this.removeSocket(oldSocket);
  }
  // hacky, to help when manually executing nodes inside macro and properly triggering global dependent execution
  public shouldPropagateExecutionThrough(): boolean {
    return true;
  }

  public fadeAllNonPIXIParts(alpha: number): void {}

  isVisible(): boolean {
    const screenPointBackgroundRectTopLeft =
      this.screenPointBackgroundRectTopLeft();
    const screenX = screenPointBackgroundRectTopLeft.x;
    const screenY = screenPointBackgroundRectTopLeft.y;
    const scale = PPGraph.currentGraph.viewportScaleX;
    // Get the visible screen dimensions
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Calculate the scaled dimensions of the container
    const containerWidth = this.nodeWidth * scale;
    const containerHeight = this.nodeHeight * scale;

    // Check if the container is at least partially visible on screen
    const isVisible =
      screenX < screenWidth &&
      screenY < screenHeight &&
      screenX + containerWidth > 0 &&
      screenY + containerHeight > 0;
    return isVisible;
  }

  public deOverlap(moveDown = true) {
    // move down Y until we dont overlap anything
    const otherNodes = Object.values(PPGraph.currentGraph.nodes).filter(
      (node) => node.id !== this.id,
    );
    while (
      getObjectsInsideBounds(otherNodes, this.getSelectionBounds()[0]).filter(
        (node) => !node.allowOverlap(),
      ).length
    ) {
      this.setPosition(0, moveDown ? 10 : -10, true);
    }
  }
  public allowOverlap(): boolean {
    return false;
  }

  // when doing auto align, certain nodes want custom behaviour (macro)
  public isPostPassForAutoAlign(): boolean {
    return false;
  }
  public postPassForAutoAlign(nodes: PPNode[], iterations: number): void {}

  // called after the first input data is set, DO override
  protected autoSetDefaultValues(): void {}
}

export class SmallNode extends PPNode {
  public getIsSimpleStyleNode(): boolean {
    return true;
  }

  public getParallelInputsOutputs(): boolean {
    return true;
  }
  public getMinNodeWidth(): number {
    return SMALL_NODE_WIDTH;
  }
}
