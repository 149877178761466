import React, { useEffect, useRef, useState, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Frame from 'react-frame-component';
import ErrorFallback from '../../components/ErrorFallback';
import PPSocket from '../../classes/SocketClass';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import { CodeType } from '../datatypes/codeType';
import { ColorType } from '../datatypes/colorType';
import { HtmlType } from '../datatypes/htmlType';
import { DeferredReactTypeInterface } from '../datatypes/deferredHtmlType';
import {
  getDimension,
  widthModeName,
  widthName,
  heightModeName,
  heightName,
  addDashboardContentOutput,
  SOCKET_NAME_DASHBOARD_CONTENT,
} from '../../utils/layoutableHelpers';
import { TNodeSource, TRgba, WidgetProps } from '../../utils/interfaces';
import {
  COLOR_WHITE,
  NODE_TYPE_COLOR,
  SOCKETNAME_BACKGROUNDCOLOR,
  SOCKET_TYPE,
} from '../../utils/constants';
import { useIsSmallScreen } from '../../utils/utils';
import HybridNode2, { defaultHybridProps } from '../../classes/HybridNode2';

const inputSocketNameHeader = 'Header';
export const htmlInputSocketName = 'Html';
const reloadSocketName = 'Reload';
const mainHtmlElementName = 'HtmlElement';
const backgroundColor = TRgba.fromString(COLOR_WHITE);

export class IFrameRenderer extends HybridNode2 {
  eventTarget: EventTarget;

  public onNodeAdded = async (source: TNodeSource): Promise<void> => {
    this.eventTarget = new EventTarget();
    await super.onNodeAdded(source);
    addDashboardContentOutput(this);
  };

  public getName(): string {
    return 'IFrame renderer';
  }

  public getDescription(): string {
    return 'Renders an iframe';
  }

  public getTags(): string[] {
    return ['Draw'].concat(super.getTags());
  }

  getShowLabels(): boolean {
    return false;
  }

  getOpacity(): number {
    return 0.001;
  }

  getPreferredInputSocketName(): string {
    return htmlInputSocketName;
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  getDefaultHeader(): string {
    return '<script src="https://cdn.tailwindcss.com"></script>';
  }

  getDefaultHTMLCode(): string {
    return `<div class="p-4">
<h2>HTML Node</h2>
<p class="mb-2 text-sky-500 dark:text-sky-400">Embed an iframe or write your own HTML</p>
<form>
  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" formtarget="_blank" formaction="https://github.com/fakob/plug-and-play/">Click me!</button>
</form>
</div>
`;
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.IN,
        inputSocketNameHeader,
        new CodeType(),
        this.getDefaultHeader(),
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        htmlInputSocketName,
        new HtmlType(),
        this.getDefaultHTMLCode(),
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        backgroundColor,
        false,
      ),
    ];
  }

  public getMinNodeHeight(): number {
    return 30;
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 150;
  }

  // small presentational component
  getWidgetContent(props: any): React.ReactElement {
    return <IFrameComponent {...props} />;
  }
}

const IFrameComponent = (props): React.ReactElement => {
  const node = props.node;
  const nodeComponentId = `${node.id}-${props.inDashboard ? 'dashboard' : 'canvas'}`;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const idWithDiv = `${node.id}-div`;
  const [contentHeight, setContentHeight] = useState(0);

  const updateHeight = useCallback(() => {
    if (props.inDashboard) {
      const iframe = iframeRef.current as HTMLIFrameElement;
      const contentDiv = iframe?.contentWindow?.document?.querySelector(
        `#${idWithDiv}`,
      );
      if (contentDiv) {
        const height = contentDiv.scrollHeight;

        // If heightMode is "hug", resize the node
        if (props.heightMode === 'hug') {
          requestAnimationFrame(() => {
            setContentHeight(height);
          });
        }
      }
    }
  }, [node, props.heightMode]);

  // Listen for height changes
  useEffect(() => {
    const iframe = iframeRef.current as HTMLIFrameElement;
    if (iframe) {
      iframe.addEventListener('load', updateHeight);

      // Create a MutationObserver to watch for height changes
      const observer = new MutationObserver(updateHeight);

      iframe.addEventListener('load', () => {
        if (iframe.contentWindow?.document?.body) {
          observer.observe(iframe.contentWindow.document.body, {
            attributes: true,
            childList: true,
            subtree: true,
          });
        }
      });

      return () => {
        iframe.removeEventListener('load', updateHeight);
        observer.disconnect();
      };
    }
  }, [updateHeight]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Frame
        key={props[reloadSocketName]}
        id={nodeComponentId}
        ref={iframeRef}
        style={{
          width: '100%',
          height: props.heightMode === 'hug' ? contentHeight : '100%',
          borderWidth: 0,
          pointerEvents: (props.inDashboard ? props.disabled : !node.hasFocus())
            ? 'none'
            : 'auto',
          background: props[SOCKETNAME_BACKGROUNDCOLOR].toString(),
          display: 'block',
        }}
        initialContent={`<!DOCTYPE html><html><head><style>* {border: none;}</style>${props[inputSocketNameHeader]}</head><body style='overflow:auto; border-width: 0px; background: transparent;'><div></div></body></html>`}
        contentDidMount={() => {
          updateHeight();
        }}
        contentDidUpdate={() => {
          updateHeight();
        }}
      >
        <div
          id={idWithDiv}
          style={{
            position: 'relative',
            height: 'calc(100vh - 16px)',
          }}
          dangerouslySetInnerHTML={{ __html: props[htmlInputSocketName] }}
        />
      </Frame>
    </ErrorBoundary>
  );
};

export class IFrameRendererDiv extends IFrameRenderer {
  public getName(): string {
    return 'IFrame renderer (Div)';
  }

  public getDescription(): string {
    return 'Renders a div element inside an iframe';
  }

  public getDefaultNodeWidth(): number {
    return 800;
  }

  public getDefaultNodeHeight(): number {
    return 400;
  }

  public getDefaultHeader(): string {
    return '';
  }

  public getDefaultHTMLCode(): string {
    return '<div id="myDiv"></div>';
  }
}

export class IFrameRendererCanvas extends IFrameRendererDiv {
  public getName(): string {
    return 'IFrame renderer (Canvas)';
  }

  public getDescription(): string {
    return 'Renders a canvas element inside an iframe';
  }

  public getDefaultHTMLCode(): string {
    return '<canvas style="width:100%;" id="myCanvas"></canvas>';
  }
}

export class EmbedWebsite extends IFrameRendererDiv {
  public getName(): string {
    return 'Embed website';
  }

  public getDescription(): string {
    return 'Embed a website using an iframe. You can also just paste a URL into the playground';
  }

  public getDefaultHTMLCode(): string {
    return '<iframe src="https://en.wikipedia.org/wiki/Special:Random" style="width: 100%; height: 100%;"></iframe>';
  }
}

export class HtmlRenderer extends HybridNode2 {
  eventTarget: EventTarget;

  public onNodeAdded = async (source: TNodeSource): Promise<void> => {
    this.eventTarget = new EventTarget();
    await super.onNodeAdded(source);
    addDashboardContentOutput(this);
  };

  public getName(): string {
    return 'HTML renderer';
  }

  public getDescription(): string {
    return 'Renders HTML content directly';
  }

  public getTags(): string[] {
    return ['Draw'].concat(super.getTags());
  }

  getShowLabels(): boolean {
    return false;
  }

  getOpacity(): number {
    return 0.001;
  }

  public getWidgetProps(): WidgetProps {
    return { ...defaultHybridProps, heightMode: 'hug' };
  }

  getPreferredInputSocketName(): string {
    return htmlInputSocketName;
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  getDefaultHTMLCode(): string {
    return `<div class="p-4">
<h2>HTML Node</h2>
<p class="mb-2 text-sky-500 dark:text-sky-400">Write your own HTML content here</p>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
  Click me!
</button>
</div>`;
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.IN,
        htmlInputSocketName,
        new HtmlType(),
        this.getDefaultHTMLCode(),
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        backgroundColor,
        false,
      ),
    ];
  }

  public getMinNodeHeight(): number {
    return 30;
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 150;
  }

  getWidgetContent(props: any): React.ReactElement {
    return <HtmlComponent {...props} />;
  }

  protected async onExecute(input: any, output: any): Promise<void> {
    const ReactUI: DeferredReactTypeInterface = {
      renderFunction: (props) => {
        return this.getDashboardWrapper({
          ...props,
        });
      },
      nodeId: this.id,
    };
    output[SOCKET_NAME_DASHBOARD_CONTENT] = ReactUI;

    await super.onExecute(input, output);
  }
}

const HtmlComponent = (props): React.ReactElement => {
  const node = props.node;
  const nodeComponentId = `${node.id}-${props.inDashboard ? 'dashboard' : 'canvas'}`;
  const idWithDiv = `${node.id}-div`;
  const isSmallScreen = useIsSmallScreen();
  const width = getDimension(
    'width',
    props.widthMode,
    props.width,
    isSmallScreen,
    true,
  );
  const height = getDimension(
    'height',
    props.heightMode,
    props.height,
    isSmallScreen,
    true,
  );
  const [htmlInput, setHtmlInput] = useState(props[htmlInputSocketName]);

  useEffect(() => {
    setHtmlInput(props[htmlInputSocketName]);
  }, [props[htmlInputSocketName]]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div
        id={nodeComponentId}
        style={{
          width: width,
          height: height,
          background: props[SOCKETNAME_BACKGROUNDCOLOR].toString(),
          pointerEvents: (props.inDashboard ? props.disabled : !node.hasFocus())
            ? 'none'
            : 'auto',
        }}
      >
        <div
          id={idWithDiv}
          style={{
            position: 'relative',
            width: width,
            height: height,
            overflow: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: htmlInput }}
        />
      </div>
    </ErrorBoundary>
  );
};
