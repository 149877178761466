import * as PIXI from 'pixi.js';
import PPGraph from './GraphClass';
import Button from './ButtonClass';
import PPNode from './NodeClass';
import InterfaceController from '../InterfaceController';
import {
  EDIT_ICON_TEXTURE,
  SELECTION_DOWNSTREAM_TEXTURE,
  SELECTION_UPSTREAM_TEXTURE,
  SELECTION_WHOLE_TEXTURE,
  ADD_TO_DASHBOARD_ICON_TEXTURE,
} from '../utils/constants';
import { VISIBILITY_ACTION } from '../utils/constants_shared';
import FlowLogic from './FlowLogic';

export default class NodeHeaderClass extends PIXI.Container {
  _selectDownstreamBranchButton: Button;
  _selectUpstreamBranchButton: Button;
  _selectWholeBranchButton: Button;
  _editNodeButton: Button;
  _addToDashboardButton: Button;

  constructor() {
    super();
  }

  async init(): Promise<void> {
    this.name = 'headerGraphics';
    this._selectUpstreamBranchButton = await Button.create(
      SELECTION_UPSTREAM_TEXTURE,
    );
    this._selectUpstreamBranchButton.addEventListener('pointerdown', (e) =>
      this.onPointerDown(e, true, false),
    );
    this._selectWholeBranchButton = await Button.create(
      SELECTION_WHOLE_TEXTURE,
    );
    this._selectWholeBranchButton.addEventListener('pointerdown', (e) =>
      this.onPointerDown(e, true, true),
    );
    this._selectDownstreamBranchButton = await Button.create(
      SELECTION_DOWNSTREAM_TEXTURE,
    );
    this._selectDownstreamBranchButton.addEventListener('pointerdown', (e) =>
      this.onPointerDown(e, false, true),
    );
    this._editNodeButton = await Button.create(EDIT_ICON_TEXTURE);
    this._editNodeButton.addEventListener(
      'pointerdown',
      this.editNodeMouseDown.bind(this),
    );

    this._addToDashboardButton = await Button.create(
      ADD_TO_DASHBOARD_ICON_TEXTURE,
    );
    this._addToDashboardButton.addEventListener(
      'pointerdown',
      this.addToDashboardMouseDown.bind(this),
    );

    // to avoid pointerup of GraphClass being triggered
    this._addToDashboardButton.addEventListener('pointerup', (e) => {
      e.stopPropagation();
    });

    this.addChild(this._selectUpstreamBranchButton);
    this.addChild(this._selectWholeBranchButton);
    this.addChild(this._selectDownstreamBranchButton);
    this.addChild(this._editNodeButton);
    this.addChild(this._addToDashboardButton);

    this._selectUpstreamBranchButton.x = 0;
    this._selectWholeBranchButton.x = 24;
    this._selectDownstreamBranchButton.x = 48;
    this._editNodeButton.x = 72;
    this._addToDashboardButton.x = 96;

    this.redrawAnythingChanging();
  }

  public redrawAnythingChanging(hoverNode = false): void {
    this.alpha = 0.01;
    if (hoverNode) {
      this.alpha = 1.0;
    }
    // Hide dashboard button if node is not layoutable
    const node = this.parent?.parent as PPNode;
    if (this._addToDashboardButton) {
      this._addToDashboardButton.visible = node?.isLayoutable();
    }
  }

  onPointerDown(
    event: PIXI.FederatedPointerEvent,
    up: boolean,
    down: boolean,
  ): void {
    event.stopPropagation();
    const altKey = event.altKey;
    const node = this.parent?.parent as PPNode;
    const graph = PPGraph.currentGraph;
    graph.selection.selectNodes(
      Object.values(FlowLogic.getAllUpDownstreamNodes(node, up, down, altKey)),
    );
  }

  editNodeMouseDown(): void {
    const node = this.parent?.parent as PPNode;

    if (node.selected) {
      InterfaceController.toggleRightSideDrawer(VISIBILITY_ACTION.TOGGLE);
    } else {
      PPGraph.currentGraph.selection.selectNodes([node], false);
      InterfaceController.toggleRightSideDrawer(VISIBILITY_ACTION.OPEN);
    }
  }

  addToDashboardMouseDown(e: PIXI.FederatedPointerEvent): void {
    e.stopPropagation();
    const node = this.parent?.parent as PPNode;
    if (node?.isLayoutable()) {
      InterfaceController.onAddToDashboard(node as any);
    }
  }

  public screenPointButtonCenter(buttonName: string): PIXI.Point {
    let button: Button;
    switch (buttonName) {
      case 'selectUpstream':
        button = this._selectUpstreamBranchButton;
        break;
      case 'selectWhole':
        button = this._selectWholeBranchButton;
        break;
      case 'selectDownstream':
        button = this._selectDownstreamBranchButton;
        break;
      case 'edit':
        button = this._editNodeButton;
        break;
      case 'addToDashboard':
        button = this._addToDashboardButton;
        break;
      default:
        return null;
    }

    if (!button) return null;

    const globalPos = button.getGlobalPosition();
    return new PIXI.Point(
      globalPos.x + button.width / 2,
      globalPos.y + button.height / 2,
    );
  }
}
