import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  WidgetHybridBase,
  WidgetPaper,
  labelName,
  outName,
  defaultOptions,
} from './abstract';
import Socket from '../../classes/SocketClass';
import { ArrayType } from '../datatypes/arrayType';
import { NumberType } from '../datatypes/numberType';
import { StringType } from '../datatypes/stringType';
import { BooleanType } from '../datatypes/booleanType';
import { BackPropagation } from '../../interfaces';
import { SOCKET_TYPE, customTheme } from '../../utils/constants';
import {
  ActionHandler,
  BakedAction,
  SerializableAction,
  SerializableActionHandler,
} from '../../classes/Action';

const optionsName = 'Options';
const selectedOptionIndex = 'Selected Index';

const radioDefaultLabel = 'Radio Group';
const rowLayoutName = 'Row Layout';

export class WidgetRadio extends WidgetHybridBase {
  public getName(): string {
    return 'Radio Button';
  }

  public getDescription(): string {
    return 'Adds a group of options allowing you to select one';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        optionsName,
        new ArrayType(),
        defaultOptions,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        selectedOptionIndex,
        new NumberType(true, 0, 10),
        0,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        rowLayoutName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        radioDefaultLabel,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new StringType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 240;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedOptionIndex),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);

    const options = inputObject[optionsName];
    const selectedIndex = Math.max(
      0,
      Math.min(options.length - 1, inputObject[selectedOptionIndex]),
    );

    const selectedValue = options[selectedIndex];

    this.setOutputData(outName, selectedValue);
  }

  handleOnChange = async (event) => {
    const value = event.target.value;
    const options = this.getInputData(optionsName);
    const selectedIndex = options.indexOf(value);

    if (selectedIndex === -1) return;

    const id = this.id;
    const prev = this.getInputData(selectedOptionIndex);

    const applyFunction = async (newIndex) => {
      const safeNode = SerializableActionHandler.getSafeNode(id);
      safeNode.setInputData(selectedOptionIndex, newIndex);
      const selectedOption = safeNode.getInputData(optionsName)[newIndex];
      safeNode.setOutputData(outName, selectedOption);
      await safeNode.executeOptimizedChain();
    };

    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          'Select Radio Option',
        ),
        selectedIndex,
        prev,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;
    const options = props[optionsName] || [];
    const selectedIndex = Math.max(
      0,
      Math.min(options.length - 1, props[selectedOptionIndex]),
    );
    const selectedValue = options[selectedIndex];

    const fontSize = props.inDashboard
      ? '16px'
      : `${Math.max(12, node.nodeHeight / 10)}px`;

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <FormControl
            component="fieldset"
            disabled={props.disabled}
            sx={{
              margin: 'auto',
              width: '100%',
              userSelect: 'none',
              padding: 1,
            }}
          >
            {props[labelName] && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '16px',
                  mb: 1,
                  textAlign: 'center',
                }}
              >
                {props[labelName]}
              </Typography>
            )}

            <RadioGroup
              value={selectedValue}
              onChange={node.handleOnChange}
              row={props[rowLayoutName]}
              sx={{
                pointerEvents: 'auto',
                flexWrap: props[rowLayoutName] ? 'wrap' : 'nowrap',
              }}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: fontSize,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: fontSize }}>
                      {option}
                    </Typography>
                  }
                  sx={{
                    mx: 0.5,
                    width: props[rowLayoutName] ? 'auto' : '100%',
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}

const checkboxDefaultLabel = 'Checkbox Group';
const selectedOptionsName = 'Selected Options';

export class WidgetCheckbox extends WidgetHybridBase {
  public getName(): string {
    return 'Checkbox';
  }

  public getDescription(): string {
    return 'Adds a group of checkboxes allowing multiple selections';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        optionsName,
        new ArrayType(),
        defaultOptions,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        selectedOptionsName,
        new ArrayType(),
        [],
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        rowLayoutName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        checkboxDefaultLabel,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new ArrayType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 240;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.forceRerender();
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedOptionsName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);

    const options = inputObject[optionsName];
    let selectedOptions = inputObject[selectedOptionsName] || [];

    // Filter selected options and keep them in the original options order
    selectedOptions = options.filter((option) =>
      selectedOptions.includes(option),
    );

    this.setOutputData(outName, selectedOptions);
  }

  handleCheckboxChange = async (event, checked) => {
    const value = event.target.value;
    const options = this.getInputData(optionsName);

    if (!options.includes(value)) return;

    const id = this.id;
    const prevSelected = this.getInputData(selectedOptionsName) || [];

    // Create new array based on checked state
    let newSelectedSet;
    if (checked) {
      // Add option if not already included
      newSelectedSet = new Set([...prevSelected, value]);
    } else {
      // Remove option if checked is false
      newSelectedSet = new Set(prevSelected.filter((item) => item !== value));
    }

    // Preserve original options order
    const newSelected = options.filter((option) => newSelectedSet.has(option));

    const applyFunction = async (selected) => {
      const safeNode = SerializableActionHandler.getSafeNode(id);
      safeNode.setInputData(selectedOptionsName, selected);
      safeNode.setOutputData(outName, selected);
      await safeNode.executeOptimizedChain();
    };

    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          'Update Checkbox Selection',
        ),
        newSelected,
        prevSelected,
      ),
    );
  };

  getWidgetContent(props: any): React.ReactElement {
    const node = props.node;
    const options = props[optionsName] || [];
    const selectedOptions = props[selectedOptionsName] || [];

    // Calculate dynamic font size based on node dimensions
    const fontSize = props.inDashboard
      ? '16px'
      : `${Math.max(12, node.nodeHeight / 10)}px`;

    return (
      <ThemeProvider theme={customTheme}>
        <WidgetPaper node={node} inDashboard={props.inDashboard}>
          <FormControl
            component="fieldset"
            disabled={props.disabled}
            sx={{
              margin: 'auto',
              width: '100%',
              userSelect: 'none',
              padding: 1,
            }}
          >
            {props[labelName] && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '16px',
                  mb: 1,
                  textAlign: 'center',
                }}
              >
                {props[labelName]}
              </Typography>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: props[rowLayoutName] ? 'row' : 'column',
                flexWrap: 'wrap',
                pointerEvents: 'auto',
              }}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={node.handleCheckboxChange}
                      value={option}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: fontSize,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: fontSize }}>
                      {option}
                    </Typography>
                  }
                  sx={{
                    mx: 0.5,
                    width: props[rowLayoutName] ? 'auto' : '100%',
                  }}
                />
              ))}
            </Box>
          </FormControl>
        </WidgetPaper>
      </ThemeProvider>
    );
  }
}
