import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CompanionBackend } from '../backend/CompanionBackend';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { FirebaseAppHandler } from '../firebase/FirebaseAppHandler';

const CompanionComponent: React.FC<{}> = () => {
  const [isRunning, setIsRunning] = useState<boolean>(
    CompanionBackend.getInstance().getHasConnection(),
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    FirebaseAppHandler.getInstance().getIsLoggedIn(),
  );

  useEffect(() => {
    const id = InterfaceController.addListener(
      ListenEvent.CompanionConnected,
      setIsRunning,
    );
    const id2 = InterfaceController.addListener(
      ListenEvent.UserIsLoggedIn,
      setIsLoggedIn,
    );
    return () => {
      InterfaceController.removeListener(id);
      InterfaceController.removeListener(id2);
    };
  }, []);

  const color = isLoggedIn
    ? 'info.main'
    : isRunning
      ? 'success.main'
      : 'error.main';

  const text = isLoggedIn
    ? 'Logged in'
    : isRunning
      ? 'Companion running'
      : 'Companion not running';

  return (
    <Tooltip title={text} placement="right">
      <Box
        data-cy="companionStatus"
        sx={{
          background: 'transparent',
          padding: 0.5,
          textAlign: 'center',
          width: 32,
          height: 32,
        }}
      >
        <FiberManualRecordIcon
          sx={{
            color,
            fontSize: 16,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default CompanionComponent;
