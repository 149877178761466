import React from 'react';
import { useNode, useEditor } from '@craftjs/core';
import {
  Box,
  Container as MUIContainer,
  Stack,
  ToggleButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Resizer } from './Resizer';
import { WidgetMode, TRgba } from '../../utils/interfaces';
import {
  AlignmentControl,
  ColorControl,
  DimensionInputs,
  SliderControl,
  PaddingControl,
  WidthHeightControl,
} from './SettingsControls';

export const containerName = 'Container';

type ContainerWidgetProps = {
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  color: Record<'r' | 'g' | 'b' | 'a', number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  height: string;
  padding: number[];
  minWidth: string;
  minHeight: string;
  widthMode: WidgetMode;
  heightMode: WidgetMode;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const defaultProps: ContainerWidgetProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: [0, 0, 0, 0],
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 51, g: 51, b: 51, a: 1 },
  width: '100%',
  height: 'auto',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'hug',
  maxWidth: false,
};

interface ContainerProps extends ContainerWidgetProps {
  children: React.ReactNode;
  gap: number;
}

export const borderStyledDashed = '1px dashed rgba(38, 128, 235, 0.3)';
export const borderStyledDashedActive = '1px dashed rgba(195, 171, 84, 0.3)';
export const borderStyle = '2px solid rgba(38, 128, 235, 1)';
export const borderStyleActive = '2px solid rgba(195, 171, 84, 1)';

export const getBorderStyles = (
  isEditMode: boolean,
  isRoot: boolean,
  flexDirection: string,
  isActive: boolean,
) => {
  if (!isEditMode || isRoot) return {};
  const myBorderStyle = isActive ? borderStyleActive : borderStyle;

  return flexDirection === 'column'
    ? {
        borderTop: myBorderStyle,
        borderBottom: myBorderStyle,
      }
    : {
        borderLeft: myBorderStyle,
        borderRight: myBorderStyle,
      };
};

export const Container = (props: Partial<ContainerProps>) => {
  const {
    actions: { setProp },
    id,
  } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }));
  const { isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));
  const isRoot = id === 'ROOT';
  const hasChildren = React.Children.count(props.children) > 0;
  const heightFixed = props.heightMode === 'fixed';
  const widthFixed = props.widthMode === 'fixed';
  const isFixed = props.flexDirection === 'row' ? widthFixed : heightFixed;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    flexDirection,
    alignItems,
    justifyContent,
    background,
    color,
    padding,
    gap = 0,
    children,
    maxWidth,
  } = props;
  const {
    connectors: { connect },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));
  const { isEditMode } = useEditor((state) => ({
    isEditMode: state.options.enabled,
  }));

  return (
    <MUIContainer maxWidth={maxWidth} disableGutters={true} sx={{ p: 0 }}>
      <Resizer
        propKey={{ width: 'width', height: 'height' }}
        style={{
          cursor: isEditMode ? 'move' : 'auto',
          display: 'flex',
          justifyContent,
          flexDirection: isMobile ? 'column' : flexDirection,
          alignItems,
          gap: `${gap}px`,
          ...getBorderStyles(isEditMode, isRoot, flexDirection, isActive),
          background: `rgba(${Object.values(background)})`,
          color: `rgba(${Object.values(color)})`,
          padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
          overflow: isRoot || isFixed ? 'auto' : 'unset',
          margin: isRoot ? '0 auto' : undefined,
        }}
      >
        {children}
        {isEditMode &&
          containerPlaceholder(flexDirection, hasChildren, isRoot, isActive)}
      </Resizer>
    </MUIContainer>
  );
};

const ContainerSettings = () => {
  const {
    actions: { setProp },
    props,
    id,
  } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }));
  const isRoot = id === 'ROOT';

  if (!props) {
    return null;
  }

  return isRoot ? (
    <RootContainerSettings
      setProp={setProp}
      width={props.width}
      height={props.height}
      gap={props.gap}
      background={props.background}
      maxWidth={props.maxWidth}
    />
  ) : (
    <ChildContainerSettings
      setProp={setProp}
      flexDirection={props.flexDirection}
      widthMode={props.widthMode}
      heightMode={props.heightMode}
      width={props.width}
      height={props.height}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      padding={props.padding}
      gap={props.gap}
      background={props.background}
    />
  );
};

interface RootContainerSettingsProps {
  setProp: (callback: (props: any) => void) => void;
  width: string;
  height: string;
  gap: number;
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const RootContainerSettings: React.FC<RootContainerSettingsProps> = ({
  setProp,
  ...props
}) => (
  <Stack spacing={0.5} sx={{ bgcolor: 'background.paper' }}>
    <AlignmentControl
      value={props.maxWidth}
      onChange={(value) => setProp((props: any) => (props.maxWidth = value))}
      label="Max Width"
      options={[
        <ToggleButton size="small" value="xs" key="xs">
          XS
        </ToggleButton>,
        <ToggleButton size="small" value="sm" key="sm">
          SM
        </ToggleButton>,
        <ToggleButton size="small" value="md" key="md">
          MD
        </ToggleButton>,
        <ToggleButton size="small" value="lg" key="lg">
          LG
        </ToggleButton>,
        <ToggleButton size="small" value="xl" key="xl">
          XL
        </ToggleButton>,
        <ToggleButton size="small" value={false} key="false">
          None
        </ToggleButton>,
      ]}
    />
    <DimensionInputs
      setProp={setProp}
      width={props.width}
      height={props.height}
    />
    <SliderControl
      value={props.gap}
      onChange={(value) => setProp((props: any) => (props.gap = value))}
      label="Gap"
    />
    <ColorControl
      setProp={setProp}
      color={props.background}
      controlBackground={true}
    />
  </Stack>
);

interface ChildContainerSettingsProps {
  setProp: (callback: (props: any) => void) => void;
  flexDirection: string;
  widthMode: WidgetMode;
  heightMode: WidgetMode;
  width: string;
  height: string;
  justifyContent: string;
  alignItems: string;
  padding: number[];
  gap: number;
  background: Record<'r' | 'g' | 'b' | 'a', number>;
}

const ChildContainerSettings: React.FC<ChildContainerSettingsProps> = ({
  setProp,
  ...props
}) => {
  // Helper function to get alignment options based on whether it's the primary axis
  const getAlignmentOptions = (isPrimaryAxis: boolean, isSpace: boolean) => {
    const basicOptions = [
      <ToggleButton size="small" value="flex-start" key="start">
        {isPrimaryAxis ? 'Left' : 'Top'}
      </ToggleButton>,
      <ToggleButton size="small" value="center" key="center">
        Center
      </ToggleButton>,
      <ToggleButton size="small" value="flex-end" key="end">
        {isPrimaryAxis ? 'Right' : 'Bottom'}
      </ToggleButton>,
    ];

    if (isSpace) {
      return [
        ...basicOptions,
        <ToggleButton size="small" value="space-between" key="between">
          Space
        </ToggleButton>,
      ];
    }

    return basicOptions;
  };

  return (
    <Stack spacing={0.5} sx={{ bgcolor: 'background.paper' }}>
      <AlignmentControl
        value={props.flexDirection}
        onChange={(value) =>
          setProp((props: any) => (props.flexDirection = value))
        }
        label="Direction"
        options={[
          <ToggleButton size="small" value="column" key="column">
            Vertical
          </ToggleButton>,
          <ToggleButton size="small" value="row" key="row">
            Horizontal
          </ToggleButton>,
        ]}
      />

      <WidthHeightControl
        setProp={setProp}
        widthMode={props.widthMode}
        width={props.width}
        heightMode={props.heightMode}
        height={props.height}
      />

      <AlignmentControl
        value={
          props.flexDirection === 'row'
            ? props.alignItems
            : props.justifyContent
        }
        onChange={(value) =>
          setProp((props: any) => {
            if (props.flexDirection === 'row') {
              props.alignItems = value;
            } else {
              props.justifyContent = value;
            }
          })
        }
        label="Vertical Alignment"
        options={getAlignmentOptions(false, props.flexDirection === 'column')}
      />

      <AlignmentControl
        value={
          props.flexDirection === 'row'
            ? props.justifyContent
            : props.alignItems
        }
        onChange={(value) =>
          setProp((props: any) => {
            if (props.flexDirection === 'row') {
              props.justifyContent = value;
            } else {
              props.alignItems = value;
            }
          })
        }
        label="Horizontal Alignment"
        options={getAlignmentOptions(true, props.flexDirection === 'row')}
      />

      <PaddingControl
        setProp={setProp}
        padding={props.padding}
        isVertical={true}
      />
      <PaddingControl
        setProp={setProp}
        padding={props.padding}
        isVertical={false}
      />
      <SliderControl
        value={props.gap}
        onChange={(value) => setProp((props: any) => (props.gap = value))}
        label="Gap"
      />
      <ColorControl
        setProp={setProp}
        color={props.background}
        controlBackground={true}
      />
    </Stack>
  );
};

Container.craft = {
  displayName: containerName,
  props: { ...defaultProps },
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
};

export function containerPlaceholder(
  flexDirection: string,
  hasChildren: boolean,
  isRoot: boolean,
  isActive: boolean,
) {
  return (
    <Stack
      direction={flexDirection === 'column' ? 'column' : 'row'}
      spacing={1}
      sx={{
        p: 0,
        width: flexDirection === 'row' && hasChildren ? '10%' : '100%',
        height:
          flexDirection === 'column' && hasChildren
            ? '16px'
            : isRoot
              ? '240px'
              : '104px',
        alignItems: 'stretch',
        justifyContent: 'stretch',
      }}
    >
      {!hasChildren && (
        <Box
          sx={{
            flex: 1,
            borderRadius: 0,
            border: isActive ? borderStyledDashedActive : borderStyledDashed,
            bgcolor: 'rgba(60, 84, 171, 0.1)',
          }}
        />
      )}
      <Box
        sx={{
          flex: 1,
          borderRadius: 0,
          border: isActive ? borderStyledDashedActive : borderStyledDashed,
          bgcolor: 'rgba(60, 84, 171, 0.1)',
        }}
      />
    </Stack>
  );
}
