// needs to live in a separate file due to webpack ReferenceError
import React from 'react';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Layoutable, WidgetMode } from '../utils/interfaces';
import { DeferredReactType } from '../nodes/datatypes/deferredHtmlType';
import { ensureVisible } from '../pixi/utils-pixi';
import PPGraph from '../classes/GraphClass';
import { StyledButton } from '../components/GraphOverlay';

export const SOCKET_NAME_DASHBOARD_CONTENT = 'ReactUI';
export const widthModeName = 'Width behaviour';
export const widthName = 'Width';
export const heightModeName = 'Height behaviour';
export const heightName = 'Height';
export const directionName = 'Direction';

// Add this to any layoutable node class to create the output socket
export function addDashboardContentOutput(node: any): void {
  // Check if the socket already exists
  if (!node.getOutputSocketByName(SOCKET_NAME_DASHBOARD_CONTENT)) {
    node.addOutput(SOCKET_NAME_DASHBOARD_CONTENT, new DeferredReactType());
  }
}

export const getDimension = (
  dimension: string,
  widgetMode: WidgetMode,
  nodeSize: string,
  isSmallScreen: boolean,
  isChild: boolean = false,
) => {
  // On small screens, the root container should always be 100% width
  if (isSmallScreen && dimension === 'width') return '100%';

  switch (widgetMode) {
    case 'hug':
      return 'auto';
    case 'fill':
      return '100%';
    case 'fixed':
      // If this is a child and parent uses percentage in fixed mode,
      // return 100% instead to prevent compounding percentages
      if (isChild && nodeSize.endsWith('%')) {
        return '100%';
      }
      return nodeSize;
  }
};

export function handleNodeSelection(
  layoutableElement: Layoutable,
  options: {
    ensureVisibility?: boolean;
    select?: boolean;
    stopPropagation?: boolean;
  } = {},
) {
  const {
    ensureVisibility = true,
    select = true,
    stopPropagation = true,
  } = options;

  return async (e?: React.SyntheticEvent) => {
    if (e && stopPropagation) e.stopPropagation();

    const nodeToJumpTo = layoutableElement.getRelatedNode();
    if (nodeToJumpTo) {
      if (ensureVisibility) {
        await ensureVisible([nodeToJumpTo], false);
      }

      PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);

      if (select) {
        PPGraph.currentGraph.selection.selectNodes([nodeToJumpTo], false);
      }
    }
  };
}

export function renderSelectNodeButton(
  handleSelectNode: (e: any) => Promise<void>,
): React.ReactNode {
  return (
    <StyledButton
      data-cy="select-node-button"
      onClick={handleSelectNode}
      sx={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        zIndex: 100,
        width: '18px',
        height: '18px',
        p: 0,
        borderRadius: 0,
      }}
    >
      <HighlightAltIcon sx={{ fontSize: '16px' }} />
    </StyledButton>
  );
}
