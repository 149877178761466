import React from 'react';
import {
  FormControl,
  FormLabel,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { WidgetMode, TRgba } from '../../utils/interfaces';
import { ColorPickerComponent } from '../../widgets';

export const FormWrapper = ({ children }: { children: React.ReactNode }) => (
  <FormControl
    sx={{
      width: '100%',
      bgcolor: 'background.default',
      p: 0.5,
    }}
    size="small"
  >
    {children}
  </FormControl>
);

// Create reusable component
export const StyledFormLabel = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <FormLabel component="legend" sx={{ fontSize: '0.8rem', paddingLeft: '2px' }}>
    {children}
  </FormLabel>
);

// Update ColorControl to use it
export const ColorControl = ({
  setProp,
  color,
  controlBackground,
}: {
  setProp: any;
  color: any;
  controlBackground: boolean;
}) => (
  <FormWrapper>
    <StyledFormLabel>
      {controlBackground ? 'Background' : 'Color'}
    </StyledFormLabel>
    <ColorPickerComponent
      defaultColor={TRgba.fromObject(color)}
      onChange={(color) =>
        setProp(
          (props: any) =>
            (props[controlBackground ? 'background' : 'color'] = color),
        )
      }
      showAlphaSlider={true}
    />
  </FormWrapper>
);

export const DimensionInputs = ({
  setProp,
  width,
  height,
}: {
  setProp: any;
  width: string;
  height: string;
}) => (
  <FormWrapper>
    <div style={{ display: 'flex', gap: '16px' }}>
      <TextField
        variant="filled"
        label="Width"
        size="small"
        data-cy="Container-width"
        value={width}
        onChange={(e) =>
          setProp((props: any) => (props.width = e.target.value))
        }
      />
      <TextField
        variant="filled"
        label="Height"
        size="small"
        data-cy="Container-height"
        value={height}
        onChange={(e) =>
          setProp((props: any) => (props.height = e.target.value))
        }
      />
    </div>
  </FormWrapper>
);

export const AlignmentControl = ({
  value,
  onChange,
  label,
  options,
  dimension,
  mode,
}: {
  value: string;
  onChange: (value: string) => void;
  label: string;
  options: React.ReactNode[];
  dimension?: 'width' | 'height';
  mode?: WidgetMode;
}) => (
  <FormWrapper>
    <StyledFormLabel>{label}</StyledFormLabel>
    <ToggleButtonGroup
      value={value}
      exclusive
      size="small"
      onChange={(_, value) => value !== null && onChange(value)}
      fullWidth
      sx={{
        mt: 1,
        '& .MuiToggleButtonGroup-grouped': {
          padding: '4px 8px',
          fontSize: '0.7rem',
          lineHeight: 1,
          height: '24px',
          '&:hover': {
            backgroundColor: 'secondary.dark',
          },
          '&.Mui-selected': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'secondary.light',
          },
        },
      }}
    >
      {options}
    </ToggleButtonGroup>
    {dimension === 'height' && mode === 'fill' && (
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          color: 'text.secondary',
          p: 0.5,
        }}
      >
        Parent needs to have a fixed height!
      </Typography>
    )}
  </FormWrapper>
);

export const PaddingControl = ({
  setProp,
  padding,
  isVertical,
}: {
  setProp: any;
  padding: number[];
  isVertical: boolean;
}) => (
  <FormWrapper>
    <StyledFormLabel>
      {isVertical ? 'Padding Vertical' : 'Padding Horizontal'}
    </StyledFormLabel>
    <Slider
      size="small"
      color="secondary"
      valueLabelDisplay="auto"
      sx={{ ml: 1, py: '15px', width: 'calc(100% - 16px)' }}
      min={0}
      max={100}
      value={padding?.[isVertical ? 0 : 1] || 0}
      onChange={(_, value) => {
        setProp((props: any) => {
          props.padding = props.padding || [0, 0, 0, 0];
          if (isVertical) {
            props.padding[0] = value; // top
            props.padding[2] = value; // bottom
          } else {
            props.padding[1] = value; // right
            props.padding[3] = value; // left
          }
        });
      }}
    />
  </FormWrapper>
);

type Dimension = 'width' | 'height';

interface DimensionControlProps {
  dimension: Dimension;
  mode: WidgetMode;
  value: string;
  setProp: any;
}

const DimensionControl = ({
  dimension,
  mode,
  value,
  setProp,
}: DimensionControlProps) => (
  <>
    <AlignmentControl
      value={mode}
      onChange={(value) =>
        setProp((props: any) => (props[`${dimension}Mode`] = value))
      }
      label={`${dimension.charAt(0).toUpperCase() + dimension.slice(1)} mode`}
      dimension={dimension}
      mode={mode}
      options={[
        <ToggleButton
          size="small"
          value="hug"
          key="hug"
          title="Uses as much space as it needs, not caring about others"
        >
          Hug
        </ToggleButton>,
        <ToggleButton
          size="small"
          value="fill"
          key="fill"
          title="Fills the available space"
        >
          Fill
        </ToggleButton>,
        <ToggleButton
          size="small"
          value="fixed"
          key="fixed"
          title="Uses a fixed size"
        >
          Fixed
        </ToggleButton>,
      ].filter(Boolean)}
    />

    {mode === 'fixed' && (
      <FormWrapper>
        <TextField
          variant="filled"
          label={dimension.charAt(0).toUpperCase() + dimension.slice(1)}
          size="small"
          value={value}
          onChange={(e) =>
            setProp((props: any) => (props[dimension] = e.target.value))
          }
        />
      </FormWrapper>
    )}
  </>
);

export const WidthHeightControl = ({
  setProp,
  widthMode,
  width,
  heightMode,
  height,
}: {
  setProp: any;
  widthMode: WidgetMode;
  width: string;
  heightMode: WidgetMode;
  height: string;
}) => (
  <>
    <DimensionControl
      dimension="width"
      mode={widthMode}
      value={width}
      setProp={setProp}
    />
    <DimensionControl
      dimension="height"
      mode={heightMode}
      value={height}
      setProp={setProp}
    />
  </>
);

interface SliderControlProps {
  value: number;
  onChange: (value: number) => void;
  label: string;
  min?: number;
  max?: number;
  step?: number;
}

export const SliderControl: React.FC<SliderControlProps> = ({
  value,
  onChange,
  label,
  min = 0,
  max = 100,
  step = 1,
}) => {
  return (
    <FormWrapper>
      <StyledFormLabel>{label}</StyledFormLabel>
      <Slider
        size="small"
        color="secondary"
        valueLabelDisplay="auto"
        sx={{
          ml: 1,
          py: '15px',
          width: 'calc(100% - 16px)',
        }}
        min={min}
        max={max}
        step={step}
        onChange={(e) => onChange(Number((e.target as HTMLInputElement).value))}
        value={value || 0}
      />
    </FormWrapper>
  );
};
