import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Paper, Typography } from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import EditIcon from '@mui/icons-material/Edit';
import { Frame, Element, useEditor } from '@craftjs/core';
import PPGraph from '../../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import { VISIBILITY_ACTION } from '../../utils/constants_shared';
import { IOverlay, Layoutable, TRgba } from '../../utils/interfaces';
import { getLayoutableElement } from '../../utils/utils';
import { Toolbox } from './Toolbox';
import { Container, containerName } from './Container';
import { DynamicWidget, DynamicWidgetName } from './DynamicWidget';
import { DashboardContainer } from './DashboardContainer';
import '../../utils/style.module.css';

const rootProps = {
  background: { r: 255, g: 255, b: 255, a: 0.0 },
  widthMode: 'fixed' as const,
  width: '80%',
  heightMode: 'fixed' as const,
  height: 'auto',
  minHeight: '240px',
  gap: 8,
};

export const emptyLayout = {
  ROOT: {
    type: { resolvedName: containerName },
    isCanvas: true,
    props: rootProps,
    custom: {
      displayName: 'Root',
    },
    hidden: false,
    nodes: [],
    linkedNodes: {},
  },
};

export const getWidgetPlacement = ({ query, nest }) => {
  const selected = query.getState().events.selected;
  let selectedNodeId = Array.from(selected)[0] as string;

  const ROOT_NODE = 'ROOT';
  // If no node is selected, default to ROOT
  if (!selectedNodeId) {
    selectedNodeId = ROOT_NODE;
  }

  // Place any widget when ROOT is selected as the last element in ROOT
  if (selectedNodeId === ROOT_NODE) {
    const rootChildren = query.node(selectedNodeId).childNodes();
    return {
      parentId: selectedNodeId,
      insertIndex: rootChildren.length,
    };
  }

  const selectedNode = query.node(selectedNodeId).get();
  const isContainer = (node) =>
    node.data.name === Container.craft.displayName ||
    node.data.name === DashboardContainer.craft.displayName;
  const selectedIsContainer = isContainer(selectedNode);

  // Place widgets with nest = true when a container is selected as the last element in the container
  if (nest && selectedIsContainer) {
    const containerChildren = query.node(selectedNodeId).childNodes();
    return {
      parentId: selectedNodeId,
      insertIndex: containerChildren.length,
    };
  } else {
    // Find the closest parent container and place it next to it
    let currentNode = selectedNode;
    let parentNode = query.node(currentNode.data.parent).get();

    while (
      parentNode &&
      !isContainer(parentNode) &&
      parentNode.id !== ROOT_NODE
    ) {
      currentNode = parentNode;
      parentNode = query.node(currentNode.data.parent).get();
    }

    const parentId = parentNode.id;
    const siblings = query.node(parentId).childNodes();
    const currentIndex = siblings.indexOf(selectedNodeId);

    return {
      parentId,
      insertIndex: currentIndex + 1,
    };
  }
};

export function addNodeWithPlacement(
  query,
  nest: boolean,
  actions,
  nodeToAdd,
  dashboardItemId: any,
) {
  const selectedNodeId = query.getEvent('selected').last();
  const { parentId, insertIndex } = getWidgetPlacement({
    query,
    nest,
  });
  InterfaceController.toggleDashboardInEditMode(VISIBILITY_ACTION.OPEN);
  // Add the new element
  actions.addNodeTree(nodeToAdd, parentId, insertIndex);
  // If a non-container element is selected and we're adding a container
  // move it inside the container
  if (selectedNodeId && !nest) {
    const selectedNode = query.node(selectedNodeId).get();
    const newNode = query.node(dashboardItemId).get();

    // Only try to nest if the new node is a container that can accept children
    if (newNode.data.isCanvas && selectedNode.data.type !== Container) {
      actions.move(selectedNodeId, dashboardItemId, 0);
    }
  }
  actions.selectNode(dashboardItemId);
}

interface DashboardEditorProps {
  isVisible: boolean;
  isEditMode: boolean;
  randomMainColor: string;
  overlayState: IOverlay;
  updateOverlayState: (newState: Partial<IOverlay>) => void;
}

export const DashboardEditor: React.FC<DashboardEditorProps> = ({
  isVisible,
  isEditMode,
  randomMainColor,
  overlayState,
  updateOverlayState,
}) => {
  const { actions, query, rootLength } = useEditor((state) => {
    const rootNode = state.nodes['ROOT'];
    return { rootLength: rootNode?.data.nodes.length };
  });

  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isDashboardLocked, setIsDashboardLocked] = useState(
    overlayState.dashboard.locked,
  );

  // load saved layout
  const loadSavedLayout = useCallback(() => {
    let loadEmpty = false;
    if (PPGraph.currentGraph.layouts?.default) {
      const savedLayout = PPGraph.currentGraph.layouts.default;
      try {
        const parsed = JSON.parse(savedLayout);
        if (!parsed.ROOT) {
          throw new Error('Invalid layout: missing ROOT node');
        }
        actions.history.ignore().deserialize(savedLayout);

        // After deserializing, get all nodes and notify for each one
        const nodes = query.getNodes();
        Object.entries(nodes).forEach(([dashboardItemId, node]) => {
          // Skip the ROOT node
          if (dashboardItemId === 'ROOT') return;

          // Only notify items that come from the canvas
          if (node.data.custom.isCanvasItem) {
            InterfaceController.notifyListeners(
              ListenEvent.DashboardItemAdded,
              {
                dashboardId: node.data.props.id,
              },
            );
          }
        });
      } catch (error) {
        console.error('Failed to load saved layout', error);
        loadEmpty = true;
      }
    } else {
      loadEmpty = true;
    }
    if (loadEmpty) {
      actions.history.ignore().deserialize(JSON.stringify(emptyLayout));
    }
  }, [query]);

  useEffect(() => {
    const listenId = InterfaceController.addListener(
      ListenEvent.GraphConfigured,
      loadSavedLayout,
    );

    return () => {
      InterfaceController.removeListener(listenId);
    };
  }, [loadSavedLayout]);

  // Initial load
  useEffect(() => {
    loadSavedLayout();
  }, [loadSavedLayout]);

  // Poll if dashboard items still exist
  const pollDashboardItems = useCallback(() => {
    let hasChanged = false;
    const nodes = query.getNodes();

    Object.keys(nodes).forEach((nodeId) => {
      if (nodeId === 'ROOT') return;
      if (
        nodes[nodeId].data.custom.isCanvasItem ||
        nodes[nodeId].data.name === DynamicWidgetName // compatiblity for legacy graphs
      ) {
        if (!getLayoutableElement(nodes[nodeId].data.props.id)) {
          hasChanged = true;
          InterfaceController.onRemoveFromDashboard(nodeId);
        }
      }
    });

    if (hasChanged) {
      InterfaceController.showSnackBar(
        'Related widgets have been removed from the dashboard',
      );
    }
  }, [query, actions]);

  useEffect(() => {
    const pollInterval = setInterval(pollDashboardItems, 1000);
    return () => clearInterval(pollInterval);
  }, [pollDashboardItems]);

  // Register add and remove functions
  useEffect(() => {
    InterfaceController.onAddToDashboard = addToDashboard;
    InterfaceController.onRemoveFromDashboard = removeFromDashboard;
  }, [PPGraph.currentGraph.layouts, PPGraph.currentGraph.allowSelfExecution]);

  const addToDashboard = useCallback(
    (itemToAdd: Layoutable) => {
      const itemId = itemToAdd.getDashboardId();
      const node = query.node(itemId).get();
      if (node) {
        console.error('Id already exists in the dashboard:', itemId);
        return;
      }

      const dashboardItemId = uuid();
      let nodeToAdd;
      if (itemToAdd.isContainer?.()) {
        nodeToAdd = query
          .parseReactElement(
            <Element
              canvas
              is={DashboardContainer}
              id={itemId}
              index={0}
              randomMainColor={randomMainColor}
              {...itemToAdd.getWidgetProps()}
            />,
          )
          .toNodeTree((node) => {
            node.id = dashboardItemId;
            node.data.custom.isCanvasItem = true;
          });
      } else {
        nodeToAdd = query
          .parseReactElement(
            <DynamicWidget
              id={itemId}
              index={0}
              randomMainColor={randomMainColor}
              background={TRgba.fromString(randomMainColor).darken(0.6)}
              {...itemToAdd.getWidgetProps()}
            />,
          )
          .toNodeTree((node) => {
            node.id = dashboardItemId;
            node.data.custom.isCanvasItem = true;
          });
      }
      try {
        addNodeWithPlacement(
          query,
          !itemToAdd.isContainer?.(),
          actions,
          nodeToAdd,
          dashboardItemId,
        );
      } catch (e) {
        console.error(e);
        InterfaceController.showSnackBar('Failed to add widget to dashboard');
        return;
      }
      InterfaceController.showSnackBar('Added dynamic widget to dashboard');
      InterfaceController.toggleShowDashboard(VISIBILITY_ACTION.OPEN);
      InterfaceController.notifyListeners(ListenEvent.DashboardItemAdded, {
        dashboardItemId,
      });
    },
    [randomMainColor, overlayState.dashboard, updateOverlayState],
  );

  const removeFromDashboard = useCallback((itemId: string) => {
    actions.delete(itemId);
  }, []);

  useEffect(() => {
    setIsEmpty(rootLength === 0);
  }, [rootLength]);

  useEffect(() => {
    const isLocked = overlayState.dashboard.locked;
    setIsDashboardLocked(isLocked);
    if (isLocked) {
      InterfaceController.toggleDashboardInEditMode(VISIBILITY_ACTION.CLOSE);
    }
  }, [overlayState.dashboard.locked]);

  return (
    <Box
      className="page-container"
      data-cy="dashboard"
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh',
        background: `${TRgba.fromString(randomMainColor).darken(0.85)}`,
      }}
    >
      {isVisible && isEditMode && (
        <Paper
          sx={{
            position: 'sticky',
            top: 0,
            ml: '48px',
            zIndex: 1000,
            borderRadius: 0,
          }}
        >
          <Toolbox
            randomMainColor={randomMainColor}
            addToDashboard={addToDashboard}
          />
        </Paper>
      )}
      <Box
        sx={{
          display: isVisible && (!isEmpty || isEditMode) ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mb: 12,
          mt: 6,
        }}
      >
        <Frame>
          <Element is={Container} canvas {...rootProps}></Element>
        </Frame>
      </Box>
      {isEmpty && !isEditMode && <EmptyState />}
    </Box>
  );
};

export const EmptyState: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      userSelect: 'none',
      pt: 4,
      lineHeight: 1.5,
    }}
  >
    <Typography variant="h5" gutterBottom>
      Start building your page
    </Typography>
    <Typography variant="body1" color="text.secondary">
      Add dynamic widgets by clicking the
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mx: 0.5,
          verticalAlign: 'middle',
        }}
      >
        <DashboardCustomizeIcon fontSize="small" />
      </Box>
      icon
      <br />
      Group and position them in the edit view
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mx: 0.5,
          verticalAlign: 'middle',
        }}
      >
        <EditIcon fontSize="small" />
      </Box>
      <br />
      <em>
        Tip: Add sockets by <CodeSpan>Shift+Clicking</CodeSpan> them
      </em>
    </Typography>
  </Box>
);

const CodeSpan = ({ children }) => (
  <span
    style={{
      fontFamily: 'monospace',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      padding: '2px 4px',
      borderRadius: '3px',
      fontSize: '0.9em',
    }}
  >
    {children}
  </span>
);
