import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  Suspense,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDropzone } from 'react-dropzone';
import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { Autocomplete, Box, Paper, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  NodeSearchInput,
  filterOptionsNode,
  getNodes,
  renderGroupItem,
  renderNodeItem,
} from './components/Search';
import GraphOverlay from './components/GraphOverlay';
import ErrorFallback from './components/ErrorFallback';
import PixiContainer from './containers/PixiContainer';
import { onDrop, onOpenFileBrowser } from './dragAndDrop';
import { Tooltip } from './components/Tooltip';

import {
  EditDialog,
  DeleteConfirmationDialog,
  ShareDialog,
} from './components/Dialogs';
import PPGraph from './classes/GraphClass';
import {
  CONTEXTMENU_GRAPH_HEIGHT,
  CONTEXTMENU_WIDTH,
  MAIN_COLOR,
} from './utils/constants';
import { IGraphSearch, INodeSearch } from './utils/interfaces';
import { controlOrMetaKey, isPhone } from './utils/utils';
import { createPixiApp, zoomToFitNodes } from './pixi/utils-pixi';
import { getAllNodeTypes } from './nodes/allNodes';
import PPSocket from './classes/SocketClass';
import PPNode from './classes/NodeClass';
import InterfaceController, { ListenEvent } from './InterfaceController';
import PPSelection from './classes/selection/SelectionClass';
import TestController from './TestController';

import { FirebaseAppHandler } from './firebase/FirebaseAppHandler';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

FirebaseAppHandler.getInstance();
//////////////////////// THIS FIXES THE RESIZEOBSERVER, FINALLY (?)
// Save a reference to the original ResizeObserver
const OriginalResizeObserver = window.ResizeObserver;
// Create a new ResizeObserver constructor
(window as any).ResizeObserver = function (callback) {
  const wrappedCallback = (entries, observer) => {
    window.requestAnimationFrame(() => {
      try {
        callback(entries, observer);
      } catch (error) {
        console.error('Error in ResizeObserver callback:', error);
      }
    });
  };
  return new OriginalResizeObserver(wrappedCallback);
};
// Copy over static methods, if any
for (let staticMethod in OriginalResizeObserver) {
  if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
    window.ResizeObserver[staticMethod] = OriginalResizeObserver[staticMethod];
  }
}

const GraphContextMenu = React.lazy(
  () => import('./components/contextmenus/GraphContextMenu'),
);
const NodeContextMenu = React.lazy(
  () => import('./components/contextmenus/NodeContextMenu'),
);
const SocketContextMenu = React.lazy(
  () => import('./components/contextmenus/SocketContextMenu'),
);
///////////////////////////////

fetch('/buildInfo')
  .then((response) => response.json())
  .then((data) => console.log(data))
  .catch((error) => console.error(error));

(window as any).testController = new TestController(); // this is for cypress tests to be able to access everything in here
const App = (): JSX.Element => {
  console.log('FULL APP REDRAW');
  document.title = 'Your Plug and Playground';

  const mousePosition = { x: 0, y: 0 };

  const theme = useTheme();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pixiApp = useRef<PIXI.Application | null>(null);
  const pixiContext = useRef<HTMLDivElement | null>(null);
  const viewport = useRef<Viewport | null>(null);
  const overlayCommentContainer = useRef<PIXI.Container | null>(null);
  const nodeSearchInput = useRef<HTMLInputElement | null>(null);
  const [isNodeSearchVisible, setIsNodeSearchVisible] = useState(false);
  const nodeSearchCountRef = useRef(0);
  const [isGraphContextMenuOpen, setIsGraphContextMenuOpen] = useState(false);
  const [isNodeContextMenuOpen, setIsNodeContextMenuOpen] = useState(false);
  const [isSocketContextMenuOpen, setIsSocketContextMenuOpen] = useState(false);
  const [selectedSocket, setSelectedSocket] = useState<PPSocket | null>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
  const [graphToBeModified, setGraphToBeModified] =
    useState<IGraphSearch>(null); // id and name of graph to edit/delete
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [nodeSearchActiveItem, setNodeSearchActiveItem] = useState<
    INodeSearch[]
  >([]);

  // dialogs
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteGraph, setShowDeleteGraph] = useState(false);
  const [showSharePlayground, setShowSharePlayground] = useState(false);
  let lastTimeTicked = 0;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...(isDragActive
        ? {
            opacity: 0.5,
          }
        : {}),
      ...(isDragAccept
        ? {
            backgroundColor: MAIN_COLOR,
            opacity: 0.5,
          }
        : {}),
      ...(isDragReject
        ? {
            backgroundColor: '#FF0000',
          }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  ) as any;

  useEffect(() => {
    console.log('isDragActive');
  }, [isDragActive]);

  // on mount
  useEffect(() => {
    console.time('main_app_mount');

    if (process.env.NODE_ENV !== 'development') {
      (async function () {
        const res = await fetch('/api/me', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { sessionExpired } = await res.json();
        if (!sessionExpired) {
          setIsLoggedIn(true);
        }
      })();
    }

    // create pixiApp
    lastTimeTicked = createPixiApp(
      pixiContext,
      pixiApp,
      viewport,
      overlayCommentContainer,
      mousePosition,
      lastTimeTicked,
    );

    const toggleInputValue = (open) => (prev) => open ?? !prev;

    InterfaceController.toggleShowEdit = (open) =>
      setShowEdit(toggleInputValue(open));
    InterfaceController.toggleShowDebugInfo = (open) =>
      setShowDebugInfo(toggleInputValue(open));

    InterfaceController.openNodeSearch = openNodeSearch;
    InterfaceController.setIsNodeSearchVisible = setIsNodeSearchVisible;
    InterfaceController.setIsGraphContextMenuOpen = setIsGraphContextMenuOpen;
    InterfaceController.setIsNodeContextMenuOpen = setIsNodeContextMenuOpen;
    InterfaceController.setIsSocketContextMenuOpen = setIsSocketContextMenuOpen;

    InterfaceController.setGraphToBeModified = setGraphToBeModified;
    InterfaceController.setShowGraphDelete = setShowDeleteGraph;
    InterfaceController.setShowGraphEdit = setShowEdit;
    InterfaceController.setShowSharePlayground = setShowSharePlayground;
    InterfaceController.setNodeSearchActiveItem = setNodeSearchActiveItem;
  }, []);

  InterfaceController.showSnackBar = enqueueSnackbar;
  InterfaceController.hideSnackBar = closeSnackbar;

  useEffect(() => {
    // data has id and name
    const ids = [];
    ids.push(
      InterfaceController.addListener(ListenEvent.GraphChanged, (data: any) => {
        setGraphToBeModified(data as IGraphSearch);
      }),
    );

    InterfaceController.onOpenFileBrowser = onOpenFileBrowser;

    InterfaceController.onRightClick = (
      event: PIXI.FederatedPointerEvent,
      target: PIXI.Container,
    ) => {
      setIsGraphContextMenuOpen(false);
      setIsNodeContextMenuOpen(false);
      setIsSocketContextMenuOpen(false);
      const contextMenuPosX = Math.min(
        window.innerWidth - (CONTEXTMENU_WIDTH + 8),
        event.global.x,
      );
      const contextMenuPosY = (offset: number) => {
        return Math.min(window.innerHeight - offset, event.global.y);
      };
      switch (true) {
        case target.parent instanceof PPSocket:
          console.log('app right click, socket');
          setContextMenuPosition([contextMenuPosX, contextMenuPosY(80)]);
          setSelectedSocket(target.parent as PPSocket);
          setIsSocketContextMenuOpen(true);
          break;
        case target instanceof PPNode:
          console.log('app right click, node');
          setContextMenuPosition([contextMenuPosX, contextMenuPosY(220)]);
          setIsNodeContextMenuOpen(true);
          break;
        case target instanceof Viewport:
          console.log('app right click, viewport');
          setContextMenuPosition([
            contextMenuPosX,
            contextMenuPosY(CONTEXTMENU_GRAPH_HEIGHT + 8),
          ]);
          setIsGraphContextMenuOpen(true);
          break;
        case target instanceof PPSelection:
          setContextMenuPosition([
            Math.min(
              window.innerWidth - (CONTEXTMENU_WIDTH + 8),
              event.global.x,
            ),
            Math.min(window.innerHeight - 432, event.global.y),
          ]);
          setIsNodeContextMenuOpen(true);
          break;
        default:
          console.log('app right click, something else');
          break;
      }
    };

    return () => {
      ids.forEach((id) => InterfaceController.removeListener(id));
    };
  });

  useEffect(() => {
    if (!nodeSearchInput?.current) {
      return;
    }
    console.log('add eventlistener to nodeSearchInput');
    nodeSearchInput.current.addEventListener('blur', nodeSearchInputBlurred);
    // }
  }, [nodeSearchInput?.current]);

  useEffect(() => {
    if (isNodeSearchVisible) {
      nodeSearchInput.current.focus();
      nodeSearchInput.current.select();
      // console.dir(nodeSearchInput.current);
    } else {
      // TODO remove timeout here
      // so handleNodeItemSelect has access
      setTimeout(() => {
        if (PPGraph.currentGraph) {
          PPGraph.currentGraph.stopConnecting();
        }
      }, 100);
    }
  }, [isNodeSearchVisible]);

  useEffect(() => {
    if (PPGraph.currentGraph) {
      PPGraph.currentGraph.showDebugInfo = showDebugInfo;
      overlayCommentContainer.current.visible = showDebugInfo;
    }
  }, [showDebugInfo]);

  function uploadGraph() {
    open();
  }

  const openNodeSearch = (pos?: PIXI.Point) => {
    // this is ugly and should be consolidated (the mouseposition in here that is used if no pos is coming in often gives incorrect result at upper left corner)
    if (pos == undefined) {
      pos = new PIXI.Point(mousePosition.x, mousePosition.y);
    }
    setContextMenuPosition([pos.x, pos.y]);
    setIsNodeSearchVisible(true);
  };

  const nodeSearchInputBlurred = () => {
    console.log('nodeSearchInputBlurred');
    setIsNodeSearchVisible(false);
    PPGraph.currentGraph.selectedSocket = undefined;
  };

  const ResultsWithHeader = useCallback(
    ({ children, ...other }) => {
      return (
        <Paper
          {...other}
          sx={{
            '.MuiAutocomplete-listbox': {
              padding: '0 0 8px',
            },
          }}
        >
          <Box
            sx={{
              px: 2,
              pt: 0.5,
              pb: 0.25,
              fontSize: '10px',
              opacity: '0.5',
            }}
          >
            {`${nodeSearchCountRef.current} of ${
              Object.keys(getAllNodeTypes()).length
            }`}
          </Box>
          {children}
        </Paper>
      );
    },
    [nodeSearchCountRef.current],
  );

  const toReturn = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div
        // close open context menu again on click
        onClick={() => {
          setIsGraphContextMenuOpen(false);
          setIsNodeContextMenuOpen(false);
          setIsSocketContextMenuOpen(false);
        }}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100vh',
        }}
      >
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {!isPhone() && (
            <Tooltip
              pixiApp={pixiApp.current}
              isContextMenuOpen={
                isGraphContextMenuOpen ||
                isNodeContextMenuOpen ||
                isSocketContextMenuOpen
              }
            />
          )}
          <ShareDialog
            showSharePlayground={showSharePlayground}
            setShowSharePlayground={setShowSharePlayground}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            graphName={graphToBeModified?.name}
          />
          <DeleteConfirmationDialog
            showDeleteGraph={showDeleteGraph}
            setShowDeleteGraph={setShowDeleteGraph}
            graphToBeModified={graphToBeModified}
          />
          <EditDialog
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            graphId={graphToBeModified?.id}
            graphName={graphToBeModified?.name}
          />
          <Suspense>
            {isGraphContextMenuOpen && (
              <GraphContextMenu
                controlOrMetaKey={controlOrMetaKey()}
                contextMenuPosition={contextMenuPosition}
                setShowEdit={setShowEdit}
                uploadGraph={uploadGraph}
                showDebugInfo={showDebugInfo}
                setShowDebugInfo={setShowDebugInfo}
                zoomToFitNodes={zoomToFitNodes}
                setShowSharePlayground={setShowSharePlayground}
                isLoggedIn={isLoggedIn}
              />
            )}
            {isNodeContextMenuOpen && (
              <NodeContextMenu
                controlOrMetaKey={controlOrMetaKey()}
                contextMenuPosition={contextMenuPosition}
                currentGraph={PPGraph.currentGraph}
                openNodeSearch={openNodeSearch}
                zoomToFitNodes={zoomToFitNodes}
              />
            )}
            {isSocketContextMenuOpen && (
              <SocketContextMenu
                controlOrMetaKey={controlOrMetaKey()}
                contextMenuPosition={contextMenuPosition}
                currentGraph={PPGraph.currentGraph}
                selectedSocket={selectedSocket}
              />
            )}
          </Suspense>
          <PixiContainer ref={pixiContext} />
          <GraphOverlay
            setContextMenuPosition={setContextMenuPosition}
            setIsGraphContextMenuOpen={setIsGraphContextMenuOpen}
            randomMainColor={MAIN_COLOR}
          />
          {PPGraph.currentGraph && (
            <div
              style={{
                visibility: isNodeSearchVisible ? undefined : 'hidden',
                position: 'relative',
                left: `${contextMenuPosition[0]}px`,
                top: `${contextMenuPosition[1]}px`,
              }}
            >
              <Autocomplete
                id="node-search"
                ListboxProps={{ style: { maxHeight: '50vh' } }}
                sx={{
                  maxWidth: '50vw',
                  width: '400px',
                  minWidth: '200px',
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: '90vw',
                    width: '90vw',
                  },
                }}
                freeSolo
                openOnFocus
                selectOnFocus
                autoHighlight
                clearOnBlur
                autoComplete
                // open
                disablePortal
                defaultValue={null}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                value={null}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                groupBy={(option) => option.group}
                options={getNodes(nodeSearchActiveItem)}
                onChange={PPGraph.currentGraph.addOrReplaceNode}
                filterOptions={(options, state) => {
                  const filteredOptions = filterOptionsNode(options, state);
                  nodeSearchCountRef.current = filteredOptions.length;
                  return filteredOptions;
                }}
                renderOption={renderNodeItem}
                renderInput={(props) => (
                  <NodeSearchInput
                    {...props}
                    inputRef={nodeSearchInput}
                    randomMainColor={MAIN_COLOR}
                  />
                )}
                renderGroup={renderGroupItem}
                PaperComponent={ResultsWithHeader}
              />
            </div>
          )}
        </div>
        <div
          id="portal"
          style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }}
        />
      </div>
    </ErrorBoundary>
  );
  return toReturn;
};

export default App;
